import {
    Button,
    Row,
    Upload,
    InboxOutlined,
    Modal,
    message,
    Card,
    FileExcelFilled,
    CheckCircleFilled,
    RightOutlined,
    InfoCircleFilled,
    Segmented,
    PlayCircleFilled,
} from 'gokwik-ui-kit'
const { Dragger } = Upload

import { useEffect, useState } from 'react'
import acodPincodeSample from '@public/assets/acod_pincode_sample.csv'
import dayjs from 'dayjs'

export function PincodesUploadModal({
    showModal,
    setShowModal,
    filesLists,
    uploadPincodesData,
    replacePincodesData,
    downloadPincodesCsv,
    onBoardingStates,
    GoToNextOnboardingStep,
    isKycCompleted,
    isNonPlusMerchant,
    fireDownloadSampleEvent
}) {
    const [uploadedFile, setUploadedFile] = useState('')
    const [showCodUpload, setShowCodUpload] = useState(true)
    const [showUpiUpload, setShowUpiUpload] = useState(true)
    const [codReplaceWarning, setCodReplaceWarning] = useState(false)
    const [upiReplaceWarning, setUpiReplaceWarning] = useState(false)
    const [currentSelectedCodFile, setCurrentSelectedCodFile] = useState<any>(null)
    const [currentSelectedUpiFile, setCurrentSelectedUpiFile] = useState<any>(null)
    const [playTutorial, setPlayTutorial] = useState(false)
    const [codOrUpiCard, setCodOrUpiCard] = useState({
        codCard: false,
        upiCard: false,
    })

    const isOnboardingStep = onBoardingStates?.addCodPincodes || onBoardingStates?.addPrepaidPincodes

    const showCodOrPrepaidCard = () => {
        if (!isOnboardingStep) {
            if (isKycCompleted && !isNonPlusMerchant) {
                setCodOrUpiCard({
                    codCard: true,
                    upiCard: true,
                })
            } else {
                setCodOrUpiCard({
                    codCard: true,
                    upiCard: false,
                })
            }
        } else {
            if (onBoardingStates?.addCodPincodes) {
                setCodOrUpiCard((prev) => ({ ...prev, codCard: true }))
            }
            if (onBoardingStates?.addPrepaidPincodes) {
                setCodOrUpiCard((prev) => ({ ...prev, upiCard: true }))
            }
        }
    }

    useEffect(() => {
        showCodOrPrepaidCard()
    }, [])

    const downloadSampleCsv = () => {
        var pom = document.createElement('a')
        pom.href = acodPincodeSample
        pom.setAttribute('download', 'sample.csv')
        pom.click()

        message.success('Sample downloaded successfully!')
        fireDownloadSampleEvent();
    }

    function uploadPincodeCsv(file, pincodeType) {
        let reader = new FileReader()
        reader.readAsText(file)
        reader.onload = (e) => {
            const content = e.target.result
            const rows = content
                //@ts-ignore
                .split('\n')
                .map((row) => row.trim()) // Trim whitespace from each row
                .filter((row) => row !== '') // Filter out empty rows

            if (rows.length < 2 || !rows.every((row) => row.split(',').length === 1)) {
                message.error('Wrong CSV file structure. Please look at the sample to know the right structure!')
                return
            }

            const header = rows[0].toLowerCase();
            if (header !== 'pincode' && header !== 'postal code') {
                message.error('CSV file must have a header of either "pincode" or "postal code".');
                return;
            }
            rows.shift()

            // Validate and process each row
            for (let i = 0; i < rows.length; i++) {
                const pincode = rows[i].trim()
                if (!/^\d+$/.test(pincode)) {
                    message.error(`Invalid pincode '${pincode}' found at row ${i + 1} in CSV file. Please correct and reupload.`)
                    return
                }
            }

            // Add header row
            const updatedContent = ['pincode', ...rows].join('\n');

            // Create a new Blob with the updated content
            const updatedFile = new Blob([updatedContent], { type: 'text/csv' });

            // Call to the function to handle the upload process
            uploadPincodesData(updatedFile, file.name, pincodeType)
            setUploadedFile(file.name)
        }
        reader.readAsText(file)
    }

    return (
        <Modal
            footer={
                isOnboardingStep ? (
                    <div className='mt-5'>
                        <Button variant='primary' onClick={GoToNextOnboardingStep} disabled={!uploadedFile}>
                            I Did It, What Next?
                        </Button>
                    </div>
                ) : (
                    <div className='mt-5'>
                        <Button variant='primary' onClick={() => setShowModal(false)}>
                            Done
                        </Button>
                    </div>
                )
            }
            okText={null}
            title={
                <div className='ml-2'>
                    <div className='flex items-center justify-between'>
                        <span>Postal Codes</span>
                        {isOnboardingStep && (
                            <Button onClick={() => setPlayTutorial((prev) => !prev)} type='link' variant='default'>
                                <PlayCircleFilled className='text-black' />
                                <span className='text-black'>{playTutorial ? 'Close' : 'Play'} Tutorial</span>
                            </Button>
                        )}
                    </div>
                    <span className='font-normal text-sm'>
                        Use this area to limit the postal codes that you service in India
                    </span>
                </div>
            }
            onCancel={() => {
                setShowModal(false)
            }}
            open={showModal}
            closable={isOnboardingStep ? false : true}
            maskClosable={false}
            width={'50vw'}
        >
            {playTutorial && (
                <iframe
                    width='100%'
                    height='350px'
                    src='https://www.youtube.com/embed/7q4fg5OqDW4?autoplay=1&mute=1'
                    allow='autoplay; encrypted-media'
                    className='rounded-xl'
                    allowFullScreen
                    title='video'
                ></iframe>
            )}

            {codOrUpiCard?.codCard && (
                <Card
                    className='mt-5'
                    title={<div className='font-semibold text-center'>COD FILE UPLOAD SECTION</div>}
                >
                    <div className='-mt-3'>
                        {/* <Checkbox checked>Limit where cash on delivery is available</Checkbox> */}
                    </div>

                    {filesLists?.codFilesList?.length > 1 && (
                        <UploadAndReplaceSegmentation
                            setShowUpload={setShowCodUpload}
                            setReplaceWarning={setCodReplaceWarning}
                        />
                    )}
                    {showCodUpload && (
                        <UploadPincodeSection
                            donwloadSampleCsv={downloadSampleCsv}
                            uploadPincodeCsv={(file) => uploadPincodeCsv(file, 'cod')}
                            downloadPincodeCSV={() => downloadPincodesCsv('cod')}
                            exportDisabled={filesLists?.codFilesList?.length === 0}
                            footer="Click here to upload COD Pincode CSV File"
                        />
                    )}
                    {filesLists?.codFilesList?.length > 1 && !codReplaceWarning && !showCodUpload && (
                        <ChooseFromExistingFiles
                            filesList={filesLists?.codFilesList}
                            currentSelectedFile={currentSelectedCodFile}
                            setCurrentSelectedFile={setCurrentSelectedCodFile}
                            setReplaceWarning={setCodReplaceWarning}
                        />
                    )}
                    {filesLists?.codFilesList?.length > 1 && currentSelectedCodFile && codReplaceWarning && (
                        <ReplaceWarningSection
                            currentPincode={filesLists?.codFilesList[0]}
                            currentSelectedFile={currentSelectedCodFile}
                            replacePincodesData={() => replacePincodesData(currentSelectedCodFile, 'cod')}
                            setReplaceWarning={setCodReplaceWarning}
                        />
                    )}
                </Card>
            )}

            {codOrUpiCard?.upiCard && (
                <Card
                    className='mt-5'
                    title={
                        <div className='font-semibold text-center'>
                            PREPAID FILE UPLOAD SECTION
                        </div>
                    }
                >
                    <div className='-mt-3'>
                        {/* <Checkbox checked>Limit where shipping on prepaid payment method is available</Checkbox> */}
                    </div>

                    {filesLists?.upiFilesList?.length > 1 && (
                        <UploadAndReplaceSegmentation
                            setShowUpload={setShowUpiUpload}
                            setReplaceWarning={setUpiReplaceWarning}
                        />
                    )}

                    {showUpiUpload && (
                        <UploadPincodeSection
                            donwloadSampleCsv={downloadSampleCsv}
                            uploadPincodeCsv={(file) => uploadPincodeCsv(file, 'upi')}
                            downloadPincodeCSV={() => downloadPincodesCsv('upi')}
                            exportDisabled={filesLists?.upiFilesList?.length === 0}
                            footer="Click here to upload Prepaid Pincode CSV File"
                        />
                    )}

                    {filesLists?.upiFilesList?.length > 1 && !upiReplaceWarning && !showUpiUpload && (
                        <ChooseFromExistingFiles
                            filesList={filesLists?.upiFilesList}
                            currentSelectedFile={currentSelectedUpiFile}
                            setCurrentSelectedFile={setCurrentSelectedUpiFile}
                            setReplaceWarning={setUpiReplaceWarning}
                        />
                    )}
                    {filesLists?.upiFilesList?.length > 1 && currentSelectedUpiFile && upiReplaceWarning && (
                        <ReplaceWarningSection
                            currentPincode={filesLists?.upiFilesList[0]}
                            currentSelectedFile={currentSelectedUpiFile}
                            replacePincodesData={() => replacePincodesData(currentSelectedUpiFile, 'upi')}
                            setReplaceWarning={setUpiReplaceWarning}
                        />
                    )}
                </Card>
            )}
        </Modal>
    )
}

const UploadAndReplaceSegmentation = ({ setShowUpload, setReplaceWarning }) => {
    return (
        <div className='bg-white p-2 flex items-center justify-center '>
            <Segmented
                label={null}
                defaultValue='Upload File'
                onChange={(e) => {
                    if (e == 'Upload File') {
                        setReplaceWarning(false)
                        setShowUpload(true)
                    } else if (e == 'Choose from pre-existing file') {
                        setReplaceWarning(false)
                        setShowUpload(false)
                    }
                }}
                options={['Upload File', 'Choose from pre-existing file']}
            />
        </div>
    )
}

const ChooseFromExistingFiles = ({ filesList, currentSelectedFile, setCurrentSelectedFile, setReplaceWarning }) => {
    return (
        <>
            <div>
                {filesList?.map((item, i) => (
                    <Row
                        key={i}
                        className='bg-white py-4 px-2 border-l-0 border-r-0 border-t-0 border-solid border-b-[0.5px] border-gray-200 cursor-pointer hover:cursor-pointer hover:bg-slate-200'
                        justify='space-between'
                        align='middle'
                        onClick={() => {
                            setCurrentSelectedFile(item)
                        }}
                    >
                        <p className='text-base '>
                            <FileExcelFilled className='text-green-500 text-[20px]' />
                            <span className='ml-2'>{item.file}</span>
                            {currentSelectedFile?.id === item.id && (
                                <CheckCircleFilled className='text-green-500 ml-2' />
                            )}
                        </p>
                        <Row className=''>
                            <p className='text-sm text-gray-400 gap-2 '>
                                <span className='ml-2'>{dayjs(item.updated_at).format("DD MMM'YY")}</span>
                                <RightOutlined className='ml-2' />
                            </p>
                        </Row>
                    </Row>
                ))}
            </div>
            <div className='mt-3 flex justify-end'>
                <Button disabled={!currentSelectedFile} variant='primary' onClick={() => setReplaceWarning(true)}>
                    Replace
                </Button>
            </div>
        </>
    )
}

const ReplaceWarningSection = ({ currentPincode, currentSelectedFile, replacePincodesData, setReplaceWarning }) => {
    return (
        <>
            <div className='flex flex-col items-center justify-center p-4'>
                <InfoCircleFilled className='text-5xl text-blue-800' />
                <p className='text-xl font-semibold my-2'>Are you sure you want to move ahead?</p>
                <p className='text-sm text-gray-400 text-center'>
                    Changing the pincode file will impact all of your shipping related settings. Are you sure you want
                    to move ahead?
                </p>
                <p className='my-2 mt-4'>The current pincode file ({currentPincode?.file}) will be replaced by</p>
                <Row className='gap-2 my-2'>
                    <p className='border-[0.7px] border-solid border-gray-300 rounded-md p-2 '>
                        <FileExcelFilled className='text-green-500 text-lg' />
                        {currentSelectedFile?.file}
                    </p>
                    <p className='border-[0.7px] border-solid border-gray-300 rounded-md p-2'>
                        {currentSelectedFile?.records_count || 0} Pincode
                    </p>
                </Row>
            </div>
            <Row justify='end'>
                <Button variant='default' onClick={() => setReplaceWarning(false)}>
                    No, go back
                </Button>
                <Button
                    variant='primary'
                    onClick={() => {
                        replacePincodesData()
                        setReplaceWarning(false)
                    }}
                    className='ml-2'
                >
                    Yes,replace
                </Button>
            </Row>
        </>
    )
}

const UploadPincodeSection = ({ donwloadSampleCsv, uploadPincodeCsv, downloadPincodeCSV, exportDisabled,footer }) => {
    return (
        <>
            <div className='bg-white mt-2 flex items-center justify-end'>
                <Button onClick={donwloadSampleCsv} type='link' variant='default'>
                    Download Sample
                </Button>
            </div>
            <div className='mt-2'>
                <Dragger
                    accept='.csv'
                    name='file'
                    maxCount={1}
                    multiple={true}
                    itemRender={(originNode, file) => {
                        return (
                            <div>
                                <span>{file.name}</span>
                            </div>
                        )
                    }}
                    onChange={(info) => {
                        const { status, originFileObj } = info.file
                        info.file.status = 'done'
                        uploadPincodeCsv(originFileObj)
                    }}
                >
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>{footer}</p>
                </Dragger>
            </div>
        </>
    )
}
