import { ReactNode, useMemo, useRef, useEffect, useState } from 'react'
import { Layout, Menu, Select, Tooltip } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import ShopifyAppPincode from '@pages/settings/shopify-app-pincode-settings'
import ShopifyAppShipping from '@pages/settings/shopify-app-shipping-settings'
import Discount from '@pages/settings/discount-settings'
import { CreateDiscountContainer } from './discount-settings/create-discount'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserConfig, getUserDetails } from '@store/user/selectors'
import { stepConfiguration, newStepConfiguration } from '@library/utilities/constants/constants'
import CodFeatures from './cod-features'
import UiCustomisation from './shopify-app-ui-customisations'
import DiscountSuggestions from './shopify-app-ui-customisations/discount-suggestions'
import CheckoutUiCustomisation from './shopify-app-ui-customisations/gokwik-checkout-ui'
import ReviewComponent from './cod-features/helpers/ReviewComponent'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchShopifyAppMerchantConfig } from '@store/shopify-app'
import { isShopifyAppCheckoutEnabled } from '@store/shopify-app/selectors'
import PrepaidDiscounts from './shopify-app-payment-configs/prepaid-discounts'
import PaymentFeatures from './shopify-app-payment-configs'
import PpcodConfig from './shopify-app-payment-configs/ppcod'
import ShippingAndPaymentCustomizations from './cod-features/shipping-and-payment'
import CodVerification from './cod-features/cod-verification'
import { FireEvents } from '@library/utilities/helpers/eventsHelper'
import { fireClickedEventOnSettingsPage } from '@library/utilities/helpers/helper'
import UpsellCheckout from './upsell/upsellCheckout'
import { makeAPICall, titleCase } from '@gokwik/utilities'
import CodPageUiCustomisation from './cod-ui-customisations'
import blueCheck from '../../library/images/completedCheck.svg'
import redirect from '../../library/images/redirectionLink.svg'
import incompleteStep from '../../library/images/incompleteStep.svg'
import sparkles from '../../library/images/sparkles.svg'
import openLock from '../../library/images/lock-open.svg'
import tooltipCancel from '../../library/images/cancel-tooltip.svg'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

const { Sider } = Layout
interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
    className?: string
}

function createMenuItem(
    label: ReactNode,
    key: string,
    className?: string,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        children,
        label,
        type,
        className,
    }
}
export default function () {
    const merchant_details = useSelector(getMerchantDetails)
    const config = useSelector(getUserConfig)
    const route = useLocation()
    const navigate = useNavigate()
    const [codShippingRate, setCodShippingRate] = useState(false)
    const [isCountryIndia, setIsCountryIndia] = useState(true)
    const [approvedChargesStatus, setApprovedChargesStatus] = useState('')
    const [currentOnboardingStatus, setCurrentOnboardingStatus] = useState('');
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await fetchBillingStatus();
            setCurrentOnboardingStatus(merchant_details?.onboarding_status?.kwik_checkout_app);
        };
        fetchData();
    }, [merchant_details?.onboarding_status?.kwik_checkout_app])

    const fetchBillingStatus = async () => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.fetchBillingStatus}?app=kwik_checkout`,
            })
            if (response?.data?.status_code === 200) {
                setApprovedChargesStatus(response?.data?.data?.status);
            }
        } catch (error) {
            console.log(error)
        }
    }
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })
    const user_details = useSelector(getUserDetails)
    const [userJourneySteps, setUserJourneySteps] = useState([
        { id: 'cod-activation', text: 'Activate GoKwik COD' },
        { id: 'enable-gk-checkout', text: 'Enable GoKwik Checkout' },
        { id: 'kyc-verification', text: 'Verify Your Business (KYC)' },
        { id: 'payments-checkout-config', text: 'Enable Payment Options' }
    ]);

    const checkoutPlatformKeys = useMemo(
        () =>
            Object.entries(config?.supported_checkout_configs || {}).reduce(
                (result: string[], item: [string, boolean]) =>
                    item[1] && item[0] !== 'custom' ? [...result, item[0]] : result,
                [],
            ),
        [config],
    )
    const [checkoutPlatform, setCheckoutPlatform] = useState('kwik_checkout')

    const eventsClass = new FireEvents(merchant_details, user_details).features
    const settingsEvents = eventsClass.settingsPageEvents

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (config?.enhancer_app) {
            dispatch(fetchShopifyAppMerchantConfig())
        }
        settingsEvents.fireClickedEvent()
    }, [config?.enhancer_app])

    useEffect(() => {
        if (merchant_details?.country && merchant_details?.country?.toLowerCase() !== 'india') {
            setIsCountryIndia(false)
            setUserJourneySteps([
                { id: 'cod-activation', text: 'Activate GoKwik COD' },
            ]);
        }
    }, [merchant_details.country]);

    const shopifyAppCheckoutEnabled = useSelector(isShopifyAppCheckoutEnabled)

    const settingsList = useMemo(
        () => [
            {
                title: 'Pincode Serviceability',
                path: '/pincode',
                component: () => <ShopifyAppPincode events={eventsClass.pincodeServiceabilityEvents} reviewFlowEvents={eventsClass.reviewFlowEvents} />,
                className: 'pincode-serviceability-item',
            },
            {
                title: 'Shipping Serviceability',
                path: '/shipping',
                component: () => <ShopifyAppShipping events={eventsClass.shippingServiceabilityEvents} />,
                className: 'shipping-serviceability-item',
            },
            {
                title: 'All Discounts',
                path: '/discount',
                component: () => <Discount events={eventsClass.customDiscountsEvents} />,
                className: 'all-discounts-item',
            },
            {
                title: 'All Discounts',
                path: '/create-discount',
                component: () => <CreateDiscountContainer events={eventsClass.customDiscountsEvents} />,
                hideInMenu: true,
            },
            {
                title: 'All Discounts',
                path: '/edit-discount/:id',
                component: () => <CreateDiscountContainer events={eventsClass.customDiscountsEvents} />,
                hideInMenu: true,
            },
            {
                title: 'COD Features',
                path: '/cod-features',
                component: () => <CodFeatures events={eventsClass} />,
                className: 'cod-features-item',
            },
            {
                title: 'Shipping and Payment Rules',
                path: '/cod-features/shipping-payment-rules',
                component: () => <ShippingAndPaymentCustomizations events={eventsClass} />,
                hideInMenu: true,
            },
            {
                title: 'COD Verification',
                path: '/cod-features/cod-verification',
                component: () => <CodVerification events={eventsClass} />,
                hideInMenu: true,
            },
            ...(config?.enhancer_app && shopifyAppCheckoutEnabled
                ? [
                      {
                          title: 'Payment Features',
                          path: '/payment-features',
                          component: () => <PaymentFeatures events={eventsClass.paymentFeaturesEvents} />,
                          className: 'payment-features',
                      },
                      {
                          title: 'PPCOD',
                          path: '/payment-features/ppcod',
                          component: () => <PpcodConfig events={eventsClass.ppcodEvents} />,
                          className: 'shopify-app-pppcod',
                          hideInMenu: true,
                      },
                      {
                          title: 'Prepaid Discounts',
                          path: '/payment-features/prepaid-discounts',
                          component: () => <PrepaidDiscounts events={eventsClass.prepaidDiscountsEvents} />,
                          className: 'prepaid-discounts',
                          hideInMenu: true,
                      },
                      {
                          title: 'UI Customisation',
                          path: '/ui-customisation',
                          component: () => <UiCustomisation events={eventsClass} />,
                          className: 'ui-customisation-item',
                      },
                      {
                          title: 'Discount Suggestions',
                          path: '/ui-customisation/discount-suggestions',
                          component: () => <DiscountSuggestions events={eventsClass.discountSuggestionsEvents} />,
                          className: 'discount-suggestions-item',
                          hideInMenu: true,
                      },
                      {
                          title: 'Gokwik Checkout UI',
                          path: '/ui-customisation/checkout-ui',
                          component: () => <CheckoutUiCustomisation events={eventsClass.loginUICustomisationsEvents} paymentUICustomisationEvents={eventsClass.paymentUICustomisationsEvents} />,
                          className: 'checkout-ui-item',
                          hideInMenu: true,
                      },
                  ]
                : [
                      {
                          title: 'UI Customisation',
                          path: '/ui-customisation/cod',
                          component: () => (
                              <CodPageUiCustomisation events={eventsClass.codPageUICustomisationsEvents} />
                          ),
                          className: 'cod-ui-customisation-item',
                      },
                  ]),
        ],
        [config, shopifyAppCheckoutEnabled],
    )
    const menuItems = () => {
        const items = settingsList.reduce(
            (result, item) => [
                ...result,
                ...(item.hideInMenu
                    ? []
                    : [createMenuItem(item.title, `/shopify-app/settings${item.path}`, item.className)]),
            ],
            [],
        )
        return items
    }

    const openItems = useMemo(() => {
        const menu = menuItems()

        const openedKey = menu.find((item) => {
            if (item.key === route.pathname) {
                return true
            }
            if (item.children) {
                return item.children.some((child) => child.key === route.pathname)
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
        } else if (previousOpenedItems.current.openedKey) {
            return {
                ...previousOpenedItems.current,
            }
        } else {
            let similarSelectedKey: MenuItem | undefined
            const similarKey = menu.find(
                (item) =>
                    !!item.children?.find((child) => {
                        if (route.pathname.includes(child.key)) {
                            similarSelectedKey = child
                            return true
                        } else return false
                    }),
            )

            previousOpenedItems.current = {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
            return {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
        }
    }, [route.pathname, settingsList])

    const eventMappings: Record<string, string> = {
        '/shopify-app/settings/pincode': 'pincode',
        '/shopify-app/settings/shipping': 'shipping',
        '/shopify-app/settings/discount': 'discounts',
        '/shopify-app/settings/cod-features': 'cod_features',
        '/shopify-app/settings/payment-features': 'payment_features',
        '/shopify-app/settings/ui-customisation': 'ui_customisation',
        '/shopify-app/settings/ui-customisation/cod': 'cod_ui_customisation',
    }

    const handleMenuSelection = ({ key }: any) => {
        const eventName = eventMappings[key]

        fireClickedEventOnSettingsPage(eventName, eventsClass)
        navigate(key)
    }

    useEffect(() => {
        if (route.pathname === '/shopify-app/settings') navigate('/shopify-app/settings' + settingsList[0].path)
    }, [route.pathname])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        setCodShippingRate(params?.get('cod-shipping-rate') === 'true' || false)

        const hasDismissed = localStorage.getItem("gkCheckoutTooltipDismissed");
        if (!hasDismissed) {
            setIsTooltipVisible(true);
        }
    }, [])

    const handleCloseTooltip = () => {
        setIsTooltipVisible(false);
        localStorage.setItem("gkCheckoutTooltipDismissed", "true");
    };

    const stepStatuses = useMemo(() => {
        const statuses: Record<string, string> = {};
        let currentStatus =  merchant_details?.onboarding_status?.kwik_checkout_app;

        userJourneySteps.forEach((step) => {
            if (step.id === 'cod-activation') {
                statuses[step.id] = 'Completed';
            } else if (step.id === 'enable-gk-checkout') {
                if (newStepConfiguration[currentStatus]?.id >=
                    newStepConfiguration['gk_checkout_buttons_not_working']?.id && approvedChargesStatus === 'APPROVED') {
                    statuses[step.id] = 'Completed';
                } else if (newStepConfiguration[currentStatus]?.id > newStepConfiguration['kyc_verification_failed']?.id) {
                    statuses[step.id] = 'Completed';
                } else {
                    statuses[step.id] = '30 Days free Trial';
                }
            } else if (step.id === 'kyc-verification') {
                if (newStepConfiguration[currentStatus]?.id >=
                    newStepConfiguration['kyc_completed']?.id) {
                    statuses[step.id] = 'Completed';
                } else if (currentStatus === 'kyc_verification_pending') {
                    statuses[step.id] = 'In-progress • ETA 2-3 Weeks';
                } else if (currentStatus === 'kyc_verification_failed') {
                    statuses[step.id] = 'KYC Verification Failed' 
                } else if (newStepConfiguration[currentStatus]?.id <
                    newStepConfiguration['gk_checkout_buttons_not_working']?.id) {
                    statuses[step.id] = 'Yet To Start';
                } else {
                    statuses[step.id] = 'Complete KYC to avoid extra charges';
                }
            } else if (step.id === 'payments-checkout-config') {
                if (newStepConfiguration[currentStatus]?.id >=
                    newStepConfiguration['gk_payments_app_installed']?.id) {
                    statuses[step.id] = 'Completed';
                } else if (newStepConfiguration[currentStatus]?.id <
                    newStepConfiguration['kyc_completed']?.id) {
                    statuses[step.id] = 'Yet To Start';
                } else {
                    statuses[step.id] = 'Install to avoid extra charges';
                }
            }
        });

        return statuses;
    }, [currentOnboardingStatus]);
    
    const handleRedirection = (id: string) => {
        if (id === 'enable-gk-checkout') {
            settingsEvents.fireProgressStepperEvent({ step: 'enable-gokwik-checkout' });
            navigate('/onboarding/checkout-setup')
        } else if(id === 'kyc-verification') {
            settingsEvents.fireProgressStepperEvent({ step: 'kyc-verification' });
            navigate('/onboarding/kyc')
        }  else if(id === 'payments-checkout-config') {
            settingsEvents.fireProgressStepperEvent({ step: 'payments-activation' });
            navigate('/onboarding/payment-setup')
        }
    }

    const getCardStyle = (status) => {
        if (status === 'KYC Verification Failed' || status === 'Install to avoid extra charges' || status === 'Complete KYC to avoid extra charges') {
            return {
                containerStyle: { border: '1px solid red', borderTop: '2px solid red',  background: '#ffe6e6',  fontWeight: '600' },
                textStyle: { color: 'red', fontSize: '12px', fontWeight: '400' },
            };
        } else if (status === 'Ongoing' || status === 'In-progress • ETA 2-3 Weeks' || status === '30 Days free Trial') {
            return {
                containerStyle: { border: '1px solid #1677FF', borderTop: '2px solid blue', background: '#eff6fe', fontWeight: '600', color: 'black' },
                textStyle: { color: 'rgba(0, 75, 141, 1)',  fontSize: '12px', fontWeight: '400' },
            };
        } else if (status === 'Completed') {
            return {
                containerStyle: { border: '0.2px solid rgba(0,0,0,0.3)',  background: '#fff', color: 'rgba(0, 0, 0, 0.70)', fontWeight: '400' },
                textStyle: { color: 'rgba(10, 91, 57, 1)',  fontSize: '12px', fontWeight: '400' },
            };
        } else if (status === 'Yet To Start') {
            return {
                containerStyle: { border: '0.2px solid rgba(0,0,0,0.3)', background: '#fff', color: 'rgba(0,0,0,0.7)' },
                textStyle: { color: 'rgba(0,0,0,0.7)', fontSize: '12px', fontWeight: '400' },
            };
        } else {
            return {
                containerStyle: { border: '0.2px solid rgba(0,0,0,0.3)', background: '#fff' },
                textStyle: { color: 'rgba(0,0,0,0.7)', fontSize: '12px', fontWeight: '400' },
            };
        }
    }

    const showToolTipConditions = (itemId: string) => {
        return itemId === 'enable-gk-checkout' && stepStatuses[itemId] !== 'Completed' && stepStatuses[itemId] !== 'Yet To Start' && newStepConfiguration[currentOnboardingStatus]?.id <= newStepConfiguration['kyc_verification_failed']?.id && newStepConfiguration[currentOnboardingStatus]?.id >= newStepConfiguration['kyc_viewed']?.id
    }

    return (
        <div className='flex flex-col w-full'>
            {(!codShippingRate && !['gk_additional_payment_methods_configured', 'gk_payments_app_installed', 'merchant_onboarded'].includes(currentOnboardingStatus) && isCountryIndia && merchant_details?.onboarding_status?.kwik_checkout_app) && (
                <div className='w-[full] bg-[#fff] mr-2 mb-2' style={{ padding: '0.6rem 1rem', borderRadius: '0.5rem' }}>
                    <div style={{ boxSizing: 'border-box' }} className='flex justify-between items-center flex-wrap text-[14px] w-[full] gap-2'>
                        {userJourneySteps?.map((item, index) => {
                            const { containerStyle, textStyle } = getCardStyle(stepStatuses[item.id])
                            const button = (
                                <button
                                    key={index}
                                    style={{
                                        ...containerStyle,
                                        flex: '1',
                                        boxSizing: 'border-box',
                                        padding: '12px',
                                        textAlign: 'left',
                                        borderRadius: '6px',
                                    }}
                                    className="flex items-center p-[10px] gap-1 text-[14px]"
                                    onClick={() => handleRedirection(item.id)}
                                    disabled={stepStatuses[item.id] === 'Completed' || stepStatuses[item.id] === 'Yet To Start'}
                                >
                                    {stepStatuses[item.id] === 'Completed' ? (
                                        <img src={blueCheck} className="w-[25px] h-[25px]" />
                                    ) : stepStatuses[item.id] === 'KYC Verification Failed' ? (
                                        <img src={incompleteStep} className="w-[25px] h-[25px]" />
                                    ) : stepStatuses[item.id] === 'Yet To Start' ? (
                                        <div className="w-[25px] h-[25px] flex items-center justify-center text-[rgba(0, 0, 0, 0.45)]" style={{ background: 'rgb(240, 240, 240)', borderRadius: '50%' }}>
                                            {index + 1}
                                        </div>
                                    ) : (
                                        <div className="w-[25px] h-[25px] flex items-center justify-center text-[#fff]" style={{ background: '#1677FF', borderRadius: '50%' }}>
                                            {index + 1}
                                        </div>
                                    )}
                                    <div className="flex flex-col gap-1 ml-[10px]">
                                        <div>{item.text}</div>
                                        {stepStatuses[item.id] === '30 Days free Trial' ? (
                                            <div className="inline-block">
                                                <img className="w-4 h-4 animate-pulse opacity-0 scale-90 transition-all duration-1000 ease-in-out" src={sparkles} />
                                                <span style={{ ...textStyle, background: "linear-gradient(90deg, rgba(222, 238, 255, 0) 0%, #B6DEFF 100%)", padding: '2px 4px' }}>
                                                    {stepStatuses[item.id]}
                                                </span>
                                            </div>
                                        ) : (
                                            <div style={textStyle}>{stepStatuses[item.id]}</div>
                                        )}
                                    </div>
                                    {stepStatuses[item.id] !== 'Completed' && stepStatuses[item.id] !== 'Yet To Start' && (
                                        <div className="redirect ml-[auto]">
                                            <img style={{ cursor: 'pointer' }} src={redirect} />
                                        </div>
                                    )}
                                </button>
                            );
                        
                            return showToolTipConditions(item.id) ? (
                                <Tooltip
                                    key={index}
                                    title={
                                        <div className="flex items-center text-[12px] text-bold">
                                            <img className="mr-2" src={openLock} /><span>Step unlocked, now you can enable the GoKwik Checkout</span>
                                            <div onClick={handleCloseTooltip} className="ml-2">
                                                <img src={tooltipCancel} />
                                            </div>
                                        </div>
                                    }
                                    open={isTooltipVisible}
                                    placement="bottom"
                                    overlayInnerStyle={{
                                        backgroundColor: "black",
                                        color: "white",
                                        width: 400,
                                        borderRadius: '0.5rem',
                                        padding: '0.5rem',
                                    }}
                                    overlayStyle={{
                                        zIndex: 1,
                                    }}
                                >
                                    {button}
                                </Tooltip>
                            ) : (
                                button
                            );
                        })}
                    </div>
                </div>
            )}
            <div className='w-full bg-white'>
                <Layout className='h-full'>
                    {(newStepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >=
                        newStepConfiguration['imp_checks_passed']?.id ||
                        !merchant_details?.onboarding_status?.kwik_checkout_app) && (
                            <Sider
                                className='bg-white h-full mr-4 border-0 border-gray-100 border-r-2 fixed overflow-hidden'
                                style={{ background: 'white' }}
                            >
                                {checkoutPlatformKeys.length > 1 && (
                                    <Select
                                        className='w-full mb-4'
                                        value={checkoutPlatform}
                                        onChange={(value) => {
                                            localStorage.setItem('checkoutPlatform', value)
                                            setCheckoutPlatform(value)
                                            if (value === 'checkout_1') navigate(`/checkout/settings`)
                                        }}
                                        options={checkoutPlatformKeys.map((item: string) => ({
                                            label: titleCase(item.split('_').join(' ')),
                                            value: item,
                                        }))}
                                    />
                                )}
                                <div className='setting-menu-custom-css flex flex-col h-[95vh]'>
                                    <Menu
                                        onClick={handleMenuSelection}
                                        defaultOpenKeys={[openItems.openedKey]}
                                        defaultSelectedKeys={[openItems.selectedKey]}
                                        key={`${route.pathname}-${openItems.openedKey}-${openItems.selectedKey}`}
                                        items={menuItems()}
                                        inlineCollapsed={true}
                                        mode='inline'
                                    />
                                    {/*Conditions to show UpsellCheckout:  
                                        (historical user AND 
                                            (is a shopify-app merchant AND
                                            merchant's onboarding step for Kwik Checkout App is not within the range of 'kyc_viewed' and 'kyc_completed' steps AND
                                            kyc details have not been submitted AND
                                            kyc has not been done manually ( merchant with 1.0 checkout and COD app))
                                        )   OR
                                        kwik_cod is chosen from onboarding (new user)
                                    */}
                                    {/* {((!merchant_details?.gokwik_products &&
                                        merchant_details?.onboarding_status?.kwik_checkout_app &&
                                        (newStepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id <
                                            newStepConfiguration['kyc_viewed']?.id ||
                                            newStepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >
                                                newStepConfiguration['kyc_completed']?.id) &&
                                        !merchant_details?.easebuzz_merchant_id &&
                                        !merchant_details?.kyc_completion_status) ||
                                        (merchant_details?.gokwik_products?.length === 1 &&
                                            merchant_details?.gokwik_products[0] === 'kwik_cod')) && (
                                        <UpsellCheckout settingsEvents={settingsEvents} />
                                    )} */}
                                </div>
                            </Sider>
                        )}
                    <Layout className='overflow-auto'>
                        <ReviewComponent events={eventsClass.reviewFlowEvents} />
                        <Routes>
                            {settingsList.map((item, index) => (
                                <Route key={index} path={item.path} element={<item.component />} />
                            ))}
                        </Routes>
                    </Layout>
                </Layout>
            </div>
        </div>
    )
}
