import { Col, Row, Form, Input, Button, message, Alert, InputNumber, Select, Radio, Space } from 'gokwik-ui-kit'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import sparkles from '@library/images/sparkles_lightBlue.svg'
import { useEffect, useState } from 'react'
import { IShippingServiceabilityEvents } from '@library/utilities/helpers/eventsHelper'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserConfig } from '@store/user/selectors'
import { useNavigate } from 'react-router-dom'

const BillingApproveSection = ({ billingSubscription }) => {
    const [showTooltip, setShowTooltip] = useState(false)
    return (
        <Col>
            <div
                style={{
                    position: 'relative',
                    marginTop: '1rem',
                    padding: '1rem',
                    border: '1px solid #91CAFF',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '-10px',
                        left: '25%',
                        transform: 'translateX(-50%)',
                        borderLeft: '10px solid transparent',
                        borderRight: '10px solid transparent',
                        borderBottom: '10px solid #91CAFF',
                        width: '0',
                        height: '0',
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '-8px',
                        left: '25%',
                        transform: 'translateX(-50%)',
                        borderLeft: '8px solid transparent',
                        borderRight: '8px solid transparent',
                        borderBottom: '8px solid white',
                        width: '0',
                        height: '0',
                    }}
                />

                <div className='flex items-center'>
                    <div className='flex gap-1 text-sm rounded-md px-2 py-1 bg-[linear-gradient(90deg,rgba(132,202,255,0.24)_0%,rgba(245,250,255,0.24)_100%)]'>
                        <img src={sparkles} alt='' height={16} width={16} />
                        <span className='text-gray-500 font-medium'>Add custom shipping method</span>
                    </div>
                    <span
                        className='text-blue-500 cursor-pointer underline'
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        + 3 more benefits
                    </span>
                    {showTooltip && (
                        <div
                            style={{
                                position: 'absolute',
                                backgroundColor: 'black',
                                borderRadius: '8px',
                                textAlign: 'left',
                                padding: '10px',
                                color: '#ffffff',
                                opacity: 1,
                                lineHeight: '21px',
                                zIndex: 50,
                                top: '22%',
                                left: '45%',
                                width: '242px',
                            }}
                        >
                            <div
                                style={{
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '100%',
                                    right: '45%',
                                    transform: 'translateX(-50%)',
                                    borderWidth: '8px',
                                    borderStyle: 'solid',
                                    borderColor: 'transparent transparent black transparent',
                                }}
                            />

                            <span>
                                Handle COD fees <br />
                                Add shipping and payment rules
                                <br />
                                Create custom discounts
                            </span>
                        </div>
                    )}
                </div>

                <h2 className='mt-4 text-lg font-semibold text-gray-900'>
                    Approve the application charges to activate this feature
                </h2>
                <p className='text-gray-600 py-2'>Tap 'Approve' to continue.</p>

                <Button
                    variant='primary'
                    className='w-full py-2 mt-2 rounded-md font-medium h-[40px]'
                    onClick={billingSubscription}
                >
                    Approve
                </Button>
            </div>
        </Col>
    )
}

const AddShippingRateForm = (props: {
    setShowModal
    configJSON
    action
    getShippingConfig
    events: IShippingServiceabilityEvents
    isOnBoardingFlow: boolean
    fetchBillingStatus, 
    billingStatus
}) => {
    const { setShowModal, configJSON, action, getShippingConfig, events, isOnBoardingFlow, fetchBillingStatus, billingStatus } = props
    const shippingServiceabilityEvents = events.configsUpsert
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const [addConditionalPricing, setAddConditionalPricing] = useState(false)
    const [selectedCondition, setSelectedCondition] = useState('order_price')
    const [getBillingStatus, setGetBillingStatus] = useState(false)
    const config = useSelector(getUserConfig)
    const merchantDetails = useSelector(getMerchantDetails)

    const initialValues = () => {
        const shippingConfig = configJSON?.shippingConfig?.find((item) => item.id === action.id)
        if (action.name === 'edit' || action.name === 'view') {
            return {
                shipping_name: shippingConfig?.shipping_name?.trim(),
                shipping_charge: Number(shippingConfig?.shipping_charge),
                paymentOption: shippingConfig?.paymentOption,
                minOrderPrice: shippingConfig?.minOrderPrice,
                maxOrderPrice: shippingConfig?.maxOrderPrice,
                minWeight: shippingConfig?.minWeight,
                maxWeight: shippingConfig?.maxWeight,
            }
        }
    }

    const updateOnBoardingStatus = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchant(merchantDetails?.m_id) + '?mode=live',
                payload: {
                    cod_lite_onboarding_status: 'setup_completed',
                },
            })

            if (response.data.status_code === 200) {
                navigate('/shopify-app/settings')
            } 
        } catch (error) {
            console.error('Error:', error)
            message.error('Failed to update the code lite app onboarding status')
        }
    }

    const ApprovedClicked = () => {
        fetchBillingStatus((status: string) => {
            if (status === 'APPROVED') {
                setGetBillingStatus(false)
                updateOnBoardingStatus()
                navigate('/shopify-app/settings')
            }
        })
    }

    useEffect(() => {
        let interval
        if (getBillingStatus) {
            interval = setInterval(() => {
                ApprovedClicked()
            }, 5000)
            return () => clearInterval(interval);
        }
    }, [getBillingStatus])

    const billingSubscription = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyBillingSubscription,
                payload: {
                    shopDomain: merchantDetails?.website,
                    app: 'cod_lite',
                },
            })
            if (response?.data?.status_code === 200) {
                window.open(response?.data?.data?.confirmationUrl, '_blank')
                setGetBillingStatus(true)
            }
        } catch (err) {
            message.error("Error occured while Approving Charges")
        }
    }

    const addShippingMethod = async (values) => {
        const tempShippingConfig = configJSON.shippingConfig
        const shippingConfig = {
            shipping_name: values.shipping_name?.trim(),
            shipping_charge: values.shipping_charge,
            paymentOption: values.paymentOption,
            minOrderPrice: values.minOrderPrice,
            maxOrderPrice: values.maxOrderPrice,
            minWeight: values.minWeight,
            maxWeight: values.maxWeight,
        }
        tempShippingConfig.push(shippingConfig);

        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
                payload: {
                    shippingConfig: tempShippingConfig,
                },
            })

            if (response.data.status_code === 200) {
                form.resetFields()
                message.success('Shipping rate added successfully')
                getShippingConfig()
                shippingServiceabilityEvents.add.fireSuccessEvent({
                    onboarding_flow: isOnBoardingFlow,
                    currentConfig: shippingConfig
                })
            } else {
                shippingServiceabilityEvents.add.fireFailureEvent({
                    onboarding_flow: isOnBoardingFlow,
                    currentConfig: shippingConfig,
                    apiStatus: response.data.status_code,
                    errorMessage: "Failed to Add Shipping Configs"
                })
            }
        } catch (error) {
            shippingServiceabilityEvents.add.fireFailureEvent({
                onboarding_flow: isOnBoardingFlow,
                currentConfig: shippingConfig,
                errorMessage: error?.message || "Failed to Add Shipping Configs"
            })
            console.error('Error:', error)
            message.error('Failed to add shipping rate')
        }
    }

    const editShippingMethod = async (values) => {
        const temp = { ...configJSON }
        const index = temp.shippingConfig.findIndex((item) => item.id === action.id)

        if (index !== -1) {
            temp.shippingConfig[index] = {
                ...temp.shippingConfig[index], // Keep existing properties
                ...values, // Update with new values
            }
        }

        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
                payload: temp,
            })

            if (response.data.status_code === 200) {
                form.resetFields()
                message.success('Shipping method edited successfully')
                getShippingConfig()
                shippingServiceabilityEvents.edit.fireSuccessEvent({
                    onboarding_flow: isOnBoardingFlow,
                    previousConfig: configJSON?.shippingConfig[index],
                    currentConfig: temp.shippingConfig[index]
                })
            } else {
                shippingServiceabilityEvents.edit.fireFailureEvent({
                    onboarding_flow: isOnBoardingFlow,
                    previousConfig: configJSON?.shippingConfig[index],
                    currentConfig: temp.shippingConfig[index],
                    errorMessage: "Failed to Edit Shipping Configs",
                    apiStatus: response.data.status_code
                })
            }
        } catch (error) {
            shippingServiceabilityEvents.edit.fireFailureEvent({
                onboarding_flow: isOnBoardingFlow,
                previousConfig: configJSON?.shippingConfig[index],
                currentConfig: temp.shippingConfig[index],
                errorMessage: error?.message || "Failed to Edit Shipping Configs"
            })
            console.error('Error:', error)
            message.error('Failed to edit shipping rate')
        }
    }

    const onFinish = async (values) => {

        for (const key in values) {
            if (typeof values[key] === 'string') {
                values[key] = values[key].trim()
            }
        }

        if (!addConditionalPricing) {
            values.minOrderPrice = undefined
            values.maxOrderPrice = undefined
            values.minWeight = undefined
            values.maxWeight = undefined
        } else {
            //for weight based shipping condition remove price based shipping condition values and vice versa
            if (selectedCondition === 'weight') {
                values.minWeight = values?.minWeight ?? 0
                values.maxWeight = values?.maxWeight ?? undefined
                values.minOrderPrice = undefined
                values.maxOrderPrice = undefined
            } else {
                values.minOrderPrice = values?.minOrderPrice ?? 0
                values.maxOrderPrice = values?.maxOrderPrice ?? undefined
                values.minWeight = undefined
                values.maxWeight = undefined
            }
        }

        if (action?.name === 'edit') {
            editShippingMethod(values)
        } else {
            addShippingMethod(values)
        }
        setShowModal(false)
    }

    const handleKeyDown = (e) => {
        if (e.key === '-' || e.key === '+' || e.key === 'e') {
            e.preventDefault()
        }
    }

    const handleRadioButtonChange = (e) => {
        if (e?.target?.value === 'weight') {
            setSelectedCondition('weight')
        } else {
            setSelectedCondition('order_price')
        }
    }

    useEffect(() => {
        if (action.name === 'edit' || action.name === 'view') {
            const shippingConfig = configJSON?.shippingConfig?.find((item) => item.id === action.id)
            setAddConditionalPricing(
                shippingConfig?.minOrderPrice ||
                    shippingConfig?.maxOrderPrice ||
                    shippingConfig?.minWeight ||
                    shippingConfig?.maxWeight,
            )
            if (shippingConfig?.minWeight || shippingConfig?.maxWeight) {
                setSelectedCondition('weight')
            } else {
                setSelectedCondition('order_price')
            }
        }
    }, [])

    return (
        <Form form={form} onFinish={onFinish} layout='vertical' initialValues={initialValues()}>
            <Row gutter={12} justify={'space-between'}>
                <Col span={24}>
                    <Form.Item
                        label={
                            <div className='flex flex-col gap-2 w-full'>
                                <span>Shipping Name</span>
                                <span className='flex w-full'>
                                    <Alert
                                        message={
                                            'Please ensure the shipping method is created on Shopify first and named exactly the same here'
                                        }
                                        showIcon
                                        type={'warning'}
                                        className='mb-2'
                                    />
                                </span>
                            </div>
                        }
                        name='shipping_name'
                        rules={[
                            {
                                validator(_, value) {
                                    if (value?.trim()?.length > 0) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('Shipping name is required and cannot be empty'))
                                },
                            },
                        ]}
                    >
                        <Input placeholder='Enter shipping name here' disabled={action?.name === 'view'} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label='Shipping Price'
                        name='shipping_charge'
                        rules={[{ required: true, message: 'Please enter the shipping price' }]}
                    >
                        <InputNumber
                            className='w-full'
                            type='number'
                            min={0}
                            placeholder='Enter price here'
                            disabled={action?.name === 'view'}
                            onKeyDown={handleKeyDown}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} className='p-0 -mt-2'>
                    {action?.name !== 'view' && (
                        <Button
                            type='link'
                            onClick={() => setAddConditionalPricing((prev) => !prev)}
                            className='p-0 -mt-4 mb-2 items-left border-none focus:outline-none'
                        >
                            <span className='hover:underline'>
                                {addConditionalPricing ? 'Remove' : 'Add'} conditional pricing
                            </span>
                        </Button>
                    )}
                </Col>

                {addConditionalPricing && (
                    <>
                        <Col span={24}>
                            <Radio.Group
                                className='mb-4'
                                onChange={handleRadioButtonChange}
                                defaultValue={'order_price'}
                                value={selectedCondition}
                                disabled={action?.name === 'view'}
                            >
                                <Space direction='vertical'>
                                    <Radio value={'weight'}>Based on item weight </Radio>
                                    <Radio value={'order_price'}>Based on order price </Radio>
                                </Space>
                            </Radio.Group>
                        </Col>
                        {selectedCondition === 'order_price' ? (
                            <>
                                <Col span={12}>
                                    <Form.Item label='Minimum Order Price' name='minOrderPrice'>
                                        <InputNumber
                                            className='w-full'
                                            type='number'
                                            prefix={'₹'}
                                            disabled={action?.name === 'view'}
                                            min={0}
                                            defaultValue={0}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label='Maximum Order Price'
                                        name='maxOrderPrice'
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, maxOrderPrice) {
                                                    const minOrderPrice = form.getFieldValue('minOrderPrice')
                                                    if (
                                                        !minOrderPrice ||
                                                        typeof maxOrderPrice !== 'number' ||
                                                        maxOrderPrice >= minOrderPrice
                                                    ) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Maximum order price cannot be less than minimum order price',
                                                        ),
                                                    )
                                                },
                                            }),
                                        ]}
                                    >
                                        <InputNumber
                                            className='w-full'
                                            type='number'
                                            prefix={'₹'}
                                            min={1}
                                            placeholder='No Limit'
                                            disabled={action?.name === 'view'}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col span={12}>
                                    <Form.Item label='Minimum Weight' name='minWeight'>
                                        <InputNumber
                                            className='w-full'
                                            type='number'
                                            suffix={'kg'}
                                            disabled={action?.name === 'view'}
                                            min={0}
                                            defaultValue={0}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label='Maximum Weight'
                                        name='maxWeight'
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, maxWeight) {
                                                    const minWeight = form.getFieldValue('minWeight')

                                                    if (
                                                        !minWeight ||
                                                        typeof maxWeight !== 'number' ||
                                                        maxWeight >= minWeight
                                                    ) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(
                                                        new Error('Maximum weight cannot be less than minimum weight'),
                                                    )
                                                },
                                            }),
                                        ]}
                                    >
                                        <InputNumber
                                            className='w-full'
                                            type='number'
                                            suffix={'kg'}
                                            min={0.001}
                                            placeholder='No Limit'
                                            disabled={action?.name === 'view'}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </>
                )}

                <Col span={24}>
                    <Form.Item
                        label='Choose payment method'
                        name='paymentOption'
                        rules={[{ required: true, message: 'Please choose a payment method' }]}
                    >
                        <Select disabled={action?.name === 'view'}>
                            <Select.Option value='all'>All</Select.Option>
                            <Select.Option value='cod'>COD</Select.Option>
                            <Select.Option value='prepaid'>Prepaid</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                {action?.name !== 'view' && (
                    <>
                        <Col span={12}>
                            <Form.Item className='mb-0'>
                                <Button
                                    className='w-full'
                                    variant='primary'
                                    htmlType='submit'
                                    disabled={config?.cod_lite && billingStatus !== 'APPROVED'}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className='mb-0'>
                                <Button className='w-full' variant='default' onClick={() => setShowModal(false)}>
                                    Cancel
                                </Button>
                            </Form.Item>
                        </Col>
                    </>
                )}
                {(billingStatus !== 'APPROVED' && billingStatus !== '') && (
                    <BillingApproveSection billingSubscription={billingSubscription} />
                )}
            </Row>
        </Form>
    )
}

export default AddShippingRateForm
