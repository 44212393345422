import { Button } from 'gokwik-ui-kit'
import isCod from '@library/images/isCod.mp4'
import codVideo from '@library/images/addressPrefill.gif'
import check from '@library/images/checkBlack.svg'
import sparkles from '@library/images/sparkles_lightBlue.svg'

const codLiteFeature = {
    title: 'Cash on Delivery Lite',
    features: [
        {
            text: 'Enable COD Orders',
            icon: check,
            isRightIcon: false,
        },
        {
            text: 'COD Interventions like COD OTP for Extra Security',
            icon: check,
            isRightIcon: false,
        },
        {
            text: 'Show/Hide Payment Based on Cart, Customers, Products, Location and More',
            icon: check,
            isRightIcon: false,
        },
    ],
}

const codFeature = {
    title: 'Cash on Delivery',
    features: [
        {
            text: 'Address Autofill for ~80%* of Users',
            icon: check,
            isRightIcon: true,
        },
        {
            text: 'Display and Apply Discount Coupons at Checkout',
            icon: check,
            isRightIcon: false,
        },
        {
            text: 'Enable COD Orders',
            icon: check,
            isRightIcon: false,
        },
        {
            text: 'COD Interventions like COD OTP for Extra Security',
            icon: check,
            isRightIcon: false,
        },
        {
            text: 'Show/Hide Payment Based on Cart, Customers, Products, Location and More',
            icon: check,
            isRightIcon: false,
        },
    ],
}

const Card = ({ title, features, isCodLite, isCodUser }) => {
    return (
        <div>
            <p className='text-gray-700 font-semibold text-sm'>GoKwik</p>
            <div className='flex gap-2 items-center whitespace-nowrap'>
                <h2 className='text-2xl font-bold text-gray-900'>{title}</h2>
                {!isCodLite && (
                    <p className='py-1 pr-1 h-[24px] text-[#006ED2] rounded-md bg-gradient-to-r from-[rgba(245,250,255,0.24)] to-[rgba(132,202,255,0.24)] 
                    font-bold text-[12px] leading-[17px] tracking-[0.002em]'>
                    {!isCodUser ? '30 Days free trial' : 'Your last configs are recovered'}
                 </p>
                )}
            </div>
            <div className='space-y-2 p-2 my-2 bg-[#FCFCFD] rounded-md'>
                {features?.map((feature, index) => (
                    <div
                        key={index}
                        className={`flex ${
                            feature.isRightIcon
                                ? 'w-[265px] bg-gradient-to-r from-[rgba(245,250,255,0.24)] to-[rgba(132,202,255,0.24)]'
                                : ''
                        } rounded-md`}
                    >
                        <img src={feature.icon} alt='check icon' className='w-4 h-4 mr-2 mt-1' />
                        <p className='font-inter font-normal text-[12px] leading-[20px]'>{feature.text}</p>
                        {feature.isRightIcon && <img src={sparkles} alt='right icon' className='w-4 h-4 ml-2' />}
                    </div>
                ))}
            </div>
        </div>
    )
}

const ChoosePlan = ({ selectCodLite, selectCod, isCodUser }) => {
    return (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
            <div className='flex flex-col rounded-lg shadow-lg w-[848px] bg-white p-5'>
                <div className='mb-4'>
                    <p className='font-inter font-bold text-[30px] leading-[38px] tracking-[0.016em]'>
                        Choose plan, <br />
                        as per you needs.
                    </p>
                </div>
                <div className='flex gap-3'>
                    <div className='flex flex-col'>
                        <div className='w-[320px]'>
                            <video
                                className='mb-4'
                                src={isCod}
                                autoPlay
                                loop
                                muted
                                style={{ width: '100%', borderRadius: '8px' }}
                            />
                        </div>
                        <Card title={codLiteFeature?.title} features={codLiteFeature?.features} isCodLite={true} isCodUser={isCodUser} />
                    </div>
                    <div className='flex flex-col'>
                        <div className='w-[320px]'>
                            <img src={codVideo} className='mb-4' style={{ height: 'auto', width: '100%' }} />
                        </div>
                        <Card title={codFeature?.title} features={codFeature?.features} isCodLite={false} isCodUser={isCodUser} />
                    </div>
                </div>
                <div className='flex gap-4 w-full'>
                    <Button variant='default' className='w-full' onClick={selectCodLite}>
                        Choose Lite Plan
                    </Button>
                    <Button variant='primary' className='w-full' onClick={selectCod}>
                        Choose Pro Plan (Recommended)
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ChoosePlan
