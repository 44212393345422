// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.plan-container {
  width: 200px;
}

.feature-container {
  overflow: hidden;
  transition: height 0.5s ease;
}

.about-plan {
  color: #fff;
  line-height: 1.2rem;
  transition: all 0.5s ease, opacity 0.5s ease;
  text-align: left;
 }

.upgrade-plan-btn {
  background: linear-gradient(to right, #91CAFF, #E6F4FF);
  border: none;
  outline: none;
  z-index: 9999999;
}

.spotlight-gradiant {
  background: linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF3D 24%);
}


.fade-in {
  transform: translateY(0);
  opacity: 1;
}

.fade-out {
  transform: translateY(20px);
  opacity: 0;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.upgrade-plan-expanded {
  opacity: 0;
  bottom: 20px;
  z-index: 99999;
  pointer-events: none;
  /* Prevent interactions when hidden */
  transition: opacity 0.5s ease-in-out;
}

.plan-description {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
/* .spotlight-gradiant {

} */

.upgrade-plan-expanded.open {
  opacity: 1;
  pointer-events: all;
  /* Enable interactions when visible */
}

.upgrade-plan-expanded.closed {
  opacity: 0;
  pointer-events: none;
  /* Disable interactions when not visible */
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settingsMenu.css"],"names":[],"mappings":";AACA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,4CAA4C;EAC5C,gBAAgB;CACjB;;AAED;EACE,uDAAuD;EACvD,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,+DAA+D;AACjE;;;AAGA;EACE,wBAAwB;EACxB,UAAU;AACZ;;AAEA;EACE,2BAA2B;EAC3B,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,cAAc;EACd,oBAAoB;EACpB,qCAAqC;EACrC,oCAAoC;AACtC;;AAEA;EACE,8BAA8B;EAC9B,+BAA+B;AACjC;AACA;;GAEG;;AAEH;EACE,UAAU;EACV,mBAAmB;EACnB,qCAAqC;AACvC;;AAEA;EACE,UAAU;EACV,oBAAoB;EACpB,0CAA0C;AAC5C","sourcesContent":["\n.plan-container {\n  width: 200px;\n}\n\n.feature-container {\n  overflow: hidden;\n  transition: height 0.5s ease;\n}\n\n.about-plan {\n  color: #fff;\n  line-height: 1.2rem;\n  transition: all 0.5s ease, opacity 0.5s ease;\n  text-align: left;\n }\n\n.upgrade-plan-btn {\n  background: linear-gradient(to right, #91CAFF, #E6F4FF);\n  border: none;\n  outline: none;\n  z-index: 9999999;\n}\n\n.spotlight-gradiant {\n  background: linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF3D 24%);\n}\n\n\n.fade-in {\n  transform: translateY(0);\n  opacity: 1;\n}\n\n.fade-out {\n  transform: translateY(20px);\n  opacity: 0;\n}\n\n.main-container {\n  display: flex;\n  flex-direction: column;\n  height: 90vh;\n}\n\n.upgrade-plan-expanded {\n  opacity: 0;\n  bottom: 20px;\n  z-index: 99999;\n  pointer-events: none;\n  /* Prevent interactions when hidden */\n  transition: opacity 0.5s ease-in-out;\n}\n\n.plan-description {\n  border-bottom-left-radius: 6px;\n  border-bottom-right-radius: 6px;\n}\n/* .spotlight-gradiant {\n\n} */\n\n.upgrade-plan-expanded.open {\n  opacity: 1;\n  pointer-events: all;\n  /* Enable interactions when visible */\n}\n\n.upgrade-plan-expanded.closed {\n  opacity: 0;\n  pointer-events: none;\n  /* Disable interactions when not visible */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
