import { formatAmount } from '@library/utilities/helpers/helper'
import { ColumnProps, InfoCircleOutlined, Tooltip } from 'gokwik-ui-kit'

export const checkoutFunnelFilters = (trend: string, dateDiff: number) => [
    {
        key: 'trend',
        value: 'funnel',
        className: 'w-36',
        options: [
            { label: 'Funnel', value: 'funnel' },
            { label: 'Hourly Metric', value: 'funnelTrendMetrics', disabled: dateDiff > 3 },
        ],
    },
    ...(trend === 'funnelTrendMetrics'
        ? [
              {
                  key: 'metrics',
                  value: 'sessions_converted',
                  className: 'w-60',
                  options: [
                      {
                          label: 'Sessions Converted %',
                          value: 'SuccessfulOrders',
                      },
                      {
                          label: 'Payment Method Selected %',
                          value: 'PaymentMethodSelected',
                      },
                      {
                          label: 'Payment Step Reached %',
                          value: 'PaymentStepReached',
                      },
                      {
                          label: 'Address Step Reached %',
                          value: 'AddressLanded',
                      },
                  ],
              },
          ]
        : []),
]

const paymentMethodFilter = {
    key: 'payment_method_filter',
    value: 'all',
    options: [
        { label: 'All', value: 'all' },
        { label: 'COD', value: 'cod' },
        { label: 'Prepaid', value: 'prepaid' },
    ],
}

const salesOrCountFilter = {
    key: 'sales_by',
    value: 'count',
    className: 'w-22',
    options: [
        { label: 'Sales', value: 'sum' },
        { label: 'Count', value: 'count' },
    ],
}
const splitByFilter = {
    key: 'split_by',
    value: 'mkt_source',
    options: [
        { label: 'UTM Source', value: 'mkt_source' },
        { label: 'UTM Medium', value: 'mkt_medium' },
        { label: 'UTM Campaign', value: 'mkt_campaign' },
        { label: 'Landing Page', value: 'landing_page' },
        { label: 'Origin Referrer', value: 'orig_referrer' },
    ],
}

export const SummaryBlocksData: {
    heading: string
    tooltipText: string
    dataKey: string
    nestedKey?: string | string[]
    heroValueTransform?: 'percentage' | 'number' | 'amount'
    kwikCodLock?: boolean
}[] = [
    {
        heading: 'Total Sales',
        tooltipText: 'Sum of sales during the period',
        dataKey: 'realtimeStats',
        nestedKey: ['data', 'total_payment'],
        heroValueTransform: 'amount',
    },
    {
        heading: 'Total Orders',
        tooltipText: 'Sum of orders during the period',
        dataKey: 'realtimeStats',
        nestedKey: ['data', 'total_order_count'],
        heroValueTransform: 'number',
    },
    {
        heading: 'Average Order Value',
        tooltipText: 'Average order value during the period',
        dataKey: 'realtimeStats',
        nestedKey: ['data', 'aov'],
        heroValueTransform: 'amount',
    },
    {
        heading: 'Checkout Conversion',
        tooltipText: 'Number of orders placed / Total users reaching checkout',
        heroValueTransform: 'percentage',
        dataKey: 'funnel',
        nestedKey: ['data', 'conversion'],
        kwikCodLock: true,
    },
    // {
    //     heading: 'Gokwik SSO',
    //     tooltipText:
    //         "Number of users for which no login was required as these users were identified using GoKwik's network.",
    //     heroValueTransform: 'percentage',
    //     dataKey: 'vdFunnelMetrics',
    //     nestedKey: 'sso_percentage',
    // },
    {
        heading: 'Address Prefilled',
        tooltipText: "Number of users for which the address was prefilled by GoKwik using Gokwik network's data.",
        heroValueTransform: 'percentage',

        dataKey: 'addressPrefillTrendMetrics',
        nestedKey: ['data', 'conversion'],
        kwikCodLock: true,
    },
    {
        heading: 'Prepaid %',
        tooltipText: 'Total orders for which a prepaid payment was used / Total orders placed.',
        heroValueTransform: 'percentage',
        dataKey: 'realtimeStats',
        nestedKey: ['data', 'prepaid_percent'],
        kwikCodLock: true,
    },
    // {
    //     heading: 'COD Share',
    //     tooltipText: 'Percentage of COD orders from total orders placed',
    //     dataKey: 'vdCodShare',
    //     nestedKey: 'cod_share',
    //     heroValueTransform: 'percentage',
    // },
    // {
    //     heading: 'COD RTO Rate',
    //     tooltipText: 'Percentage of COD RTO orders from total COD orders dispatched',
    //     dataKey: 'vdCodRTORate',
    //     nestedKey: 'cod_rto_rate',
    //     heroValueTransform: 'percentage',
    // },
]

export const SummaryBlocksDataSources: {
    heading: string
    tooltipText: string
    dataKey: string
    nestedKey?: string | string[]
    heroValueTransform?: 'percentage' | 'number' | 'amount'
}[] = [
    {
        heading: 'Total Sales (Non GoKwik ABC orders)',
        tooltipText: `Total sales from Orders where Checkout was initiated on GoKwik and abandoned but recovery was done from some other source`,
        dataKey: 'realtimeStats',
        nestedKey: ['data', 'totalSales'],
        heroValueTransform: 'amount',
    },
    {
        heading: ' Total Orders (Non GoKwik ABC orders)',
        tooltipText:
            'Total orders where Checkout was initiated on GoKwik and abandoned but recovery was done from some other source',
        dataKey: 'realtimeStats',
        nestedKey: ['data', 'totalOrders'],
        heroValueTransform: 'number',
    },
    {
        heading: 'Average Order Value (Non GoKwik ABC orders)',
        tooltipText:
            'Total average order value of orders where Checkout was initiated on GoKwik and abandoned but recovery was done from some other source',
        dataKey: 'realtimeStats',
        nestedKey: ['data', 'avgOrderValue'],
        heroValueTransform: 'amount',
    },
    {
        heading: 'Estd. Checkout Conversion (Non GoKwik ABC orders)',
        tooltipText:
            'Estimated Checkout Conversion Rate for the period considering orders where Checkout was initiated on GoKwik and abandoned but recovery was done from some other source',
        heroValueTransform: 'percentage',
        dataKey: 'realtimeStats',
        nestedKey: ['data', 'conversion'],
    },
]
const tableColumns = [
    {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        align: 'left',
    },
    {
        title: 'Total Sales',
        dataIndex: 'total_sales',
        key: 'total_sales',
        render: (value) => formatAmount(value),
        align: 'center',
    },
    {
        title: 'Total Orders',
        dataIndex: 'total_orders',
        key: 'total_orders',
        align: 'center',
        render: (value) => Intl.NumberFormat('en-In').format(value),
    },
    {
        title: 'Avg. Order Value',
        dataIndex: 'average_order_value',
        key: 'average_order_value',
        render: (value) => formatAmount(value),
        align: 'right',
    },
]

const codMethods = {
    direct_cod: 'Direct COD',
    cod_otp: 'COD OTP',
    cod_captcha: 'COD Captcha',
    cod_digits: 'COD Digits',
    cod_otp_new_user: 'COD OTP ( New Users)',
    cod_prompt: 'COD Prompt',
}

const codMethodTableColumns: ColumnProps<any>[] = [
    {
        title: 'Method',
        dataIndex: 'method',
        key: 'cod_method',
        render: (val) => codMethods[val?.replace(/"/g, '')],
        width: 100,
    },
    {
        title: 'Initiated Orders',
        dataIndex: 'initiated',
        key: 'initiated_orders',
        render: (val) => val,
        align: 'right',
        width: 100,
    },
    {
        title: 'Successful Orders',
        dataIndex: 'completed',
        key: 'successful_orders',
        render: (val) => val,
        align: 'right',
        width: 100,
    },
    {
        title: 'Drop offs',
        dataIndex: 'drop_offs',
        key: 'drop_offs',
        render: (val) => val,
        align: 'right',
        width: 100,
    },
    {
        title: 'Success %',
        dataIndex: 'success_percentage',
        key: 'success_percentage',
        render: (val) => val,
        align: 'right',
        width: 100,
    },
]


const abUTMParamsFilter = {
    key: 'utm_param_filter',
    value: 'utm_source',
    options: [
        { label: 'UTM Source', value: 'utm_source' },
        { label: 'UTM Medium', value: 'utm_medium' },
        { label: 'UTM Campaign', value: 'utm_campaign' },
    ],
}

const abUTMTableColumns = [
    {
        title: 'UTM Parameter',
        dataIndex: 'utm_parameter',
        key: 'utm_parameter',
        ellipsis: true,
        align: 'left',
        width: 120,
    },
    {
        title: 'Shopify Native Initiation %',
        dataIndex: 'shopify_native_initiation_percentage',
        key: 'shopify_native_initiation_percentage',
        render: (value) => value,
        align: 'right',
        width: 150,
    },
    {
        title: 'Gokwik Checkout Initiation %',
        dataIndex: 'kwik_checkout_initiation_percentage',
        key: 'kwik_checkout_initiation_percentage',
        render: (value) => value,
        align: 'right',
        width: 180,
    },
]

export const abAnalyticsTableFieldsMap = {
    checkout_initiated: 'Checkout Initiated',
    total_orders: 'Total Orders',
    conversion_rate: (
        <p>
            Conversion Rate{' '}
            <Tooltip title='Conversion Rate = 100 * (Total orders / Checkout initiated)'>
                <InfoCircleOutlined />
            </Tooltip>
        </p>
    ),
    prepaid_percentage: 'Prepaid %',
    cod_percentage: 'COD %',
}

const abAnalyticsTableColumns = [
    {
        title: '',
        dataIndex: 'field',
        key: 'field',
        render: (value) => abAnalyticsTableFieldsMap[value],
        ellipsis: true,
        align: 'left',
        width: 100,
    },
    {
        title: 'Shopify Native',
        dataIndex: 'shopify_native',
        key: 'shopify_native',
        render: (value) => value,
        align: 'right',
        width: 80,
    },
    {
        title: 'Gokwik Checkout',
        dataIndex: 'kwik_checkout',
        key: 'kwik_checkout',
        render: (value) => value,
        align: 'right',
        width: 100,
    },
    {
        title: (
            <p>
                Diff{' '}
                <Tooltip title='The difference is calculated relative to GoKwik Checkout. A negative value (-X%) means GoKwik Checkout conversion is lower compared to Shopify Checkout. A positive value (+X%) indicates GoKwik Checkout conversion is higher.'>
                    <InfoCircleOutlined />
                </Tooltip>
            </p>
        ),
        dataIndex: 'diff',
        key: 'diff',
        render: (value) => `${value}%`,
        align: 'right',
        width: 80,
    },
]

export const ChartRows = [
    {
        title: 'Business Metrics',
        charts: [
            {
                heading: 'Total Orders',
                chartProps: {
                    chartType: 'line',
                },
                tooltipText:
                    'Count of all confirmed orders placed via GoKwik (including Cancelled and refunded orders)',
                dataKey: 'orderMetrics',
                nestedKey: 'orderStats',
                subHeading: 'ORDERS OVER TIME',
                subHeadingTooltip: 'Trend of total orders over time',
                heroValueTransform: 'number',
                filters: [paymentMethodFilter],
                permissionKey: 'totalOrders',
            },
            {
                heading: 'Average Order Value',
                chartProps: {
                    chartType: 'line',
                    labelType: 'amount',
                },
                tooltipText: `Average Order Value of all orders placed via GoKwik (including Cancelled and refunded orders)`,
                dataKey: 'orderMetrics',
                nestedKey: 'averageOrderStats',
                subHeading: 'AVERAGE ORDER VALUE OVER TIME',
                subHeadingTooltip: 'Trend of average order value over time',
                heroValueTransform: 'amount',
                filters: [paymentMethodFilter],
                permissionKey: 'averageOrderValue',
            },
            {
                heading: 'Total Sales',
                chartProps: {
                    chartType: 'line',
                    labelType: 'amount',
                },
                tooltipText: `Net Sales processed via GoKwik (including Cancelled and refunded orders) GMV = Subtotal - Discounts + Shipping/COD charges`,
                dataKey: 'orderMetrics',
                nestedKey: 'saleStats',
                subHeading: 'SALES OVER TIME',
                subHeadingTooltip: 'Trend of total sales over time',
                heroValueTransform: 'amount',
                filters: [paymentMethodFilter],
                permissionKey: 'totalSales',
            },
        ],
    },
    {
        title: 'Checkout Metrics',
        charts: [
            {
                heading: 'Discount Usage Trend',
                chartProps: {
                    chartType: 'line',
                },
                span: 16,
                tooltipText: 'Summary of discounts usage on an order level',
                dataKey: 'discountUsageTrendMetrics',
                subHeading: 'Discount usage Over Time',
                subHeadingTooltip: 'Trend of discount usage rate over time',
                heroValueTransform: 'percentage',
                showDownload: true,
                permissionKey: 'discountUsageTrend',
            },
            {
                heading: 'Top Discounts Used',
                tooltipText: 'Summary of discounts usage on an order level',
                dataKey: 'discountUsedMetrics',
                isChart: false,
                span: 8,
                showDownload: true,
                permissionKey: 'topDiscountsUsed',
            },
            {
                heading: 'Payment Method Split',
                tooltipText: 'Order count and Sales split by Payment method used on GoKwik checkout',
                dataKey: 'paymentMetrics',
                isChart: false,
                span: 8,
                showDownload: true,
                filters: [salesOrCountFilter],
                permissionKey: 'paymentMethodSplit',
            },
            {
                heading: 'Product Wise Split',
                tooltipText: 'Order count and Sales split by top products',
                dataKey: 'topProducts',
                filters: [salesOrCountFilter],
                isChart: false,
                span: 8,
                showDownload: true,
                permissionKey: 'productWiseSplit',
            },
            {
                heading: 'Top Discounts Failed',
                tooltipText:
                    'Discount codes entered by customers on checkout which were invalid (data split on sales channel not available)',
                dataKey: 'discountFailedMetrics',
                isChart: false,
                span: 8,
                showDownload: true,
                permissionKey: 'topDiscountsFailed',
            },
        ],
    },
    {
        title: 'COD Metrics',
        charts: [
            {
                heading: 'COD Method Distribution Summary',
                tooltipText:
                    'An overview of the COD order flow, showing initiated orders, successful orders, drop-offs, and the success rate (Success %), providing insight into user behavior from order initiation to order completion.',
                dataKey: 'codMethodMetrics',
                span: 16,
                permissionKey: 'saCodAnalytics',
                showDownload: true,
                isChart: false,
                isTable: true,
                tableData: codMethodTableColumns,
            },
        ],
    },
    {
        title: 'A/B Metrics',
        charts: [
            {
                heading: 'UTM Analysis',
                tooltipText: 'Compare Shopify native checkout and GoKwik checkout UTMs.',
                dataKey: 'abUTMAnalyticsMetrics',
                filters: [abUTMParamsFilter],
                permissionKey: 'saAbAnalytics',
                span: 12,
                isChart: false,
                isTable: true,
                showDownload: true,
                tableData: abUTMTableColumns,
            },
            {
                heading: 'A/B Analysis',
                tooltipText: 'Compare Shopify native checkout and GoKwik checkout.',
                dataKey: 'abAnalyticsMetrics',
                permissionKey: 'saAbAnalytics',
                span: 12,
                isChart: false,
                isTable: true,
                showDownload: true,
                tableData: abAnalyticsTableColumns,
            },
        ],
    },
]
