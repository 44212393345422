import { newStepConfiguration } from '../constants/constants'
import { SearchFilters, FilterCreatorModel } from '../interface'
import { IFeaturesEvents } from './eventsHelper'

const getDynamicSearchFilters = (searchFilters: SearchFilters[]): FilterCreatorModel => {
    const filterCreatorState: FilterCreatorModel = {
        title: 'Add More Filters +',
        options: searchFilters
            ?.filter((item) => item?.is_dynamic)
            ?.map((filter) => {
                return {
                    id: filter?.id,
                    text: filter?.label,
                }
            }),
    }
    return filterCreatorState
}

const formatAmount = (
    val: number,
    minFractionDigits: number = 0,
    maxFractionDigits: number = 2,
    notation: 'standard' | 'scientific' | 'engineering' | 'compact' = 'standard',
) =>
    val === undefined || typeof val === 'string' || val === null
        ? ''
        : Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              notation,
              minimumFractionDigits: minFractionDigits,
              maximumFractionDigits: maxFractionDigits,
          })
              .format(val)
              .replace('T', 'K')

const shopifyAppCheckoutEnabledStatus = (lastLoginDate: Date): boolean => {
    const parsedLastLoginDate = new Date(lastLoginDate)
    if (isNaN(parsedLastLoginDate.getTime())) {
        return false
    }

    const twoDaysInMs = 1000 * 60 * 60 * 24 * 2
    return Date.now() - parsedLastLoginDate.getTime() <= twoDaysInMs
}

const kycAndPaymentStatus = (merchantDetails: any, key: string): boolean => {
    const onboardingStatus = merchantDetails?.onboarding_status
    const kwikCheckoutOnboardingStatus = onboardingStatus?.kwik_checkout_app
    
    switch (key) {
        case 'isKycCompleted': {
            return (
                merchantDetails?.kyc_completion_status === 'Approved' ||
                (merchantDetails?.easebuzz_merchant_id &&
                    onboardingStatus && kwikCheckoutOnboardingStatus &&
                    newStepConfiguration[kwikCheckoutOnboardingStatus]?.id >= 
                        newStepConfiguration['kyc_completed']?.id)
            )
        }
        case 'isPaymentSetupComplete': {
            return (
                !onboardingStatus ||
                (onboardingStatus && kwikCheckoutOnboardingStatus &&
                    newStepConfiguration[kwikCheckoutOnboardingStatus]?.id >= 
                        newStepConfiguration['gk_payments_app_installed']?.id)
            )
        }
        default: {
            return false
        }
    }
}

function fireClickedEventOnSettingsPage(eventName: string, eventsClass: IFeaturesEvents): void {
    switch(eventName) {
        case 'pincode': {
            eventsClass.pincodeServiceabilityEvents.fireClickedEvent();
            break;
        }
        case 'shipping': {
            eventsClass.shippingServiceabilityEvents.fireClickedEvent();
            break;
        }
        case 'discounts': {
            eventsClass.customDiscountsEvents.fireClickedEvent();
            break;
        }
        case 'cod_features': {
            eventsClass.codFeaturesEvents.fireClickedEvent();
            break;
        }
        case 'payment_features': {
            eventsClass.paymentFeaturesEvents.fireClickedEvent();
            break;
        }
        case 'ui_customisation': {
            eventsClass.uiCustomisationsEvents.fireClickedEvent();
            break;
        }
        case 'cod_ui_customisation': {
            eventsClass.codPageUICustomisationsEvents.fireClickedEvent();
            break;
        }
        default: {
            break;
        }
    }
}

function fireClickedEventOnCheckoutAdminPage(eventName: string, eventsClass: IFeaturesEvents) {
    switch (eventName) {
        case 'manual-cod': {
            eventsClass.manualCodEvents.fireClickedEvent()
            break
        }
        default: {
            break
        }
    }
}

export {
    getDynamicSearchFilters,
    formatAmount,
    shopifyAppCheckoutEnabledStatus,
    fireClickedEventOnSettingsPage,
    fireClickedEventOnCheckoutAdminPage,
    kycAndPaymentStatus,
}
