import { useEffect, useState } from 'react'
import { Input, SearchOutlined, Table, ColumnProps, Switch, Tooltip, InfoCircleOutlined, Button } from 'gokwik-ui-kit'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { IManualCodEvents } from '@library/utilities/helpers/eventsHelper'
import SaveBar from '@library/components/save-bar'

interface ManualCodEntry {
    merchantId: string
    manualCOD: boolean
}

export default function index(props: { events: IManualCodEvents }) {
    const [searchMerchantName, setSearchMerchantName] = useState('')
    const [searchMerchantId, setSearchMerchantId] = useState('')
    const [fullList, setFullList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [total, setTotalMerchant] = useState(0)
    const [manualCodToggleList, setManualCodToggleList] = useState<ManualCodEntry[]>([])
    const [parameters, setParameters] = useState({
        merchant: null,
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
    })

    const handleToggle = async (merchantId: string, checked: boolean) => {
        try {
            setMerchantList((prevList) =>
                prevList.map((merchant) =>
                    merchant.merchantId === merchantId ? { ...merchant, manualCOD: checked } : merchant,
                ),
            )

            setManualCodToggleList((prevState) => {
                const existingEntryIndex = prevState.findIndex((entry) => entry.merchantId === merchantId)
                if (existingEntryIndex !== -1) {
                    const updatedArray = [...prevState]
                    updatedArray[existingEntryIndex].manualCOD = checked
                    return updatedArray
                } else {
                    return [...prevState, { merchantId, manualCOD: checked }]
                }
            })
            setUnsavedChanges(true)
            toggleManualCod.fireClickedEvent({
                merchantId,
                manualCod: checked,
            })
        } catch (error) {
            console.error('Error toggling manual COD status:', error)
        }
    }

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }

    const { toggleManualCod } = props.events

    const columns: ColumnProps<any>[] = [
        {
            title: <span>MID</span>,
            dataIndex: 'merchantId',
            width: 150,
            ellipsis: true,
        },
        {
            title: <span>Merchant Name</span>,
            dataIndex: 'merchantName',
            width: 150,
            ellipsis: true,
        },
        {
            title: <span>Status</span>,
            dataIndex: 'manualCOD',
            width: 150,
            render: (_, record) => (
                <Switch
                    checked={record?.manualCOD || false}
                    onChange={(checked) => handleToggle(record?.merchantId, checked)}
                />
            ),
        },
    ]

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/shopify-app/admin',
                text: 'Checkout Admin',
            },
            {
                key: 'pincode',
                href: '/shopify-app/admin/manual-cod',
                text: 'Manual COD',
            },
        ])
    }, [])

    useEffect(() => {
        if (!searchMerchantName.length && !searchMerchantId.length && !merchantList.length) {
            if (parameters.page === 1) {
                getMerchantList()
            } else setParameters((prev) => ({ ...prev, page: 1 }))
        } else {
            const updatedList = fullList?.filter(
                (merchant) =>
                    merchant?.merchantName?.toLowerCase().includes(searchMerchantName?.toLowerCase()) ||
                    merchant?.merchantId?.toLowerCase().includes(searchMerchantId?.toLowerCase()),
            )
            setTotalMerchant(updatedList?.length)
            setMerchantList(updatedList)
        }
    }, [searchMerchantName, searchMerchantId])

    const getMerchantList = async () => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getAllMerchantList,
            })
            if (response?.data?.status_code == 200) {
                setMerchantList(
                    response?.data?.data?.filter(
                        (merchant) =>
                            merchant?.merchantName?.toLowerCase().includes(searchMerchantName?.toLowerCase()) ||
                            merchant?.merchantId?.toLowerCase().includes(searchMerchantId?.toLowerCase()),
                    ),
                )
                setFullList(response?.data?.data)
            }
        } catch (err) {
            setMerchantList([])
            console.error('Error fetching merchant list:', err);
        }
    }

    const toggleManualCodFn = async (manualCodToggleList: any) => {
        try {
            const res = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.toggleMerchantManualCod,
                payload: { data: manualCodToggleList },
            })
            if (res?.data?.statusCode == 200) {
                toggleManualCod.fireSuccessEvent({
                    data: manualCodToggleList,
                })
                setUnsavedChanges(false)
            } else {
                toggleManualCod.fireFailureEvent({
                    apiStatusCode: res?.data?.statusCode,
                    errorMessage: `Failed to Update Manual Cod Config for ${manualCodToggleList.merchantId}`,
                })
            }
        } catch (err) {
            console.error('Error toggling manual COD status:', err)
        }
    }

    const handleSave = async () => {
        await toggleManualCodFn(manualCodToggleList)
        await getMerchantList()
        setManualCodToggleList([])
        setUnsavedChanges(false)
    }

    const handleDiscard = () => {
        setUnsavedChanges(false)
        getMerchantList()
    }
    return (
        <div className='mt-1 bg-white rounded-md pt-1  overflow-auto'>
            {unsavedChanges && <SaveBar handleDiscard={handleDiscard} handleSave={handleSave} />}
            <div className='w-3/12 mt-2 px-2 '>
                <Input
                    onChange={(e) => {
                        setSearchMerchantName(e.target.value.trim())
                        setSearchMerchantId(e.target.value.trim())
                    }}
                    type='text'
                    placeholder='Search Merchant'
                    maxLength={50}
                    suffix={<SearchOutlined />}
                />
            </div>
            <div>
                <Table
                    columns={columns}
                    dataSource={merchantList || []}
                    pagination={{
                        current: parameters.page, // Current page number
                        pageSize: parameters.pageSize, // Number of items to display per page
                        total: total, // Total number of items in the data array
                        showSizeChanger: false,
                        onChange: handlePaginationChange,

                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                    }}
                    scroll={{ x: 300 }}
                />
            </div>
        </div>
    )
}
