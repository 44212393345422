import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { abAnalyticsTableFieldsMap } from '@pages/analytics/chartsData'
import { getMerchantDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'

export const analyticsAPIs: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        defaultParams?: { [key: string]: any }
        formatData?: (data: any) => any
        isVd?: boolean
        realtime?: boolean
    }
} = {
    realtimeStats: {
        url: APIEndPoints.checkoutAnalytics.realtimeStats,
        errMsg: 'Unable to fetch stats',
        defaultParams: {
            sales_channel: 'all',
        },
        realtime: true,
        formatData: (data) => {
            const abcMetricSourceMap = {
                external: 'Shopify ABC Link',
                kc: 'KwikChat Headless',
            }
            const {
                abc_orders_data: { metrics, conversion },
                ...restData
            } = data || {}

            let result = {
                ...(restData || {}),
            }

            if (metrics && Array.isArray(metrics)) {
                const { totalOrders, totalSales } = metrics.reduce(
                    (acc, metric) => ({
                        totalOrders: acc.totalOrders + metric.total_orders,
                        totalSales: acc.totalSales + metric.total_sales,
                    }),
                    { totalOrders: 0, totalSales: 0 },
                )
                const avgOrderValue = totalSales / totalOrders

                if (totalOrders)
                    result = {
                        ...result,
                        metrics: metrics.map((metric: any) => ({
                            ...metric,
                            source: metric.source
                                ? abcMetricSourceMap[metric.source?.toLowerCase()] || metric.source
                                : '-',
                        })),
                        totalOrders,
                        totalSales,
                        avgOrderValue,
                        conversion,
                    }
                else if (result.conversion) {
                    delete result.conversion
                }
            }

            return result
        },
    },
    funnel: {
        url: APIEndPoints.checkoutAnalytics.checkoutFunnel,
        errMsg: 'Unable to fetch Funnel Data',
        defaultParams: {
            payment_method_filter: 'all',
            sales_channel: 'all',
        },
    },
    funnelTrendMetrics: {
        url: APIEndPoints.checkoutAnalytics.checkoutFunnelTrendMetrics,
        errMsg: 'Unable to fetch Funnel Trend Data',
        defaultParams: {
            payment_method_filter: 'all',
            sales_channel: 'all',
        },
        formatData: (data: any) => {
            if (!data?.checkoutFunnelTrend) return data
            return data.checkoutFunnelTrend
        },
    },
    // vdOrderMetrics: {
    //     url: APIEndPoints.checkoutAnalytics.vdOrderMetrics,
    //     isVd: true,
    //     errMsg: 'Unable to fetch Order Metrics',
    //     nestedKeys: ['total_orders', 'total_sales', 'prepaid_percentage', 'aov'],
    // },
    // vdFunnelMetrics: {
    //     url: APIEndPoints.checkoutAnalytics.vdFunnelMetrics,
    //     isVd: true,
    //     errMsg: 'Unable to fetch Funnel Data',
    //     nestedKeys: ['address_prefilled_percentage', 'checkout_conversion_percentage', 'sso_percentage'],
    // },
    // vdCodShare: {
    //     url: APIEndPoints.checkoutAnalytics.vdCodShare,
    //     isVd: true,
    //     errMsg: 'Unable to fetch COD Share Data',
    //     nestedKeys: ['cod_share'],
    // },
    // vdCodRTORate: {
    //     url: APIEndPoints.checkoutAnalytics.vdCodRTORate,
    //     isVd: true,
    //     errMsg: 'Unable to fetch COD RTO Rate Data',
    //     nestedKeys: ['cod_rto_rate'],
    // },
    orderMetrics: {
        url: APIEndPoints.checkoutAnalytics.orderMetrics,
        errMsg: 'Unable to fetch Order Metrics',
        nestedKeys: ['averageOrderStats', 'orderStats', 'saleStats'],
        defaultParams: {
            payment_method_filter: 'all',
            sales_channel: 'all',
        },
    },
    marketingMetrics: {
        url: APIEndPoints.checkoutAnalytics.marketingMetrics,
        errMsg: 'Unable to fetch marketing Data',
        defaultParams: {
            payment_method_filter: 'all',
            sales_channel: 'all',
        },
        formatData: (data: any) => {
            const { lastUpdatedAt, stats } = data
            return { lastUpdatedAt, ...stats }
        },
    },
    paymentMetrics: {
        url: APIEndPoints.checkoutAnalytics.paymentMetrics,
        errMsg: 'Unable to fetch Payment Metrics',
        defaultParams: {
            payment_method_filter: 'all',
            sales_by: 'count',
            sales_channel: 'all',
        },
        formatData: (data: any) => {
            const { lastUpdatedAt, stats } = data
            return {
                lastUpdatedAt,
                prepaidPercentage: stats.reduce(
                    (acc: number, stat: any) =>
                        !stat.key.toLowerCase().includes('cod') ? +(acc + stat.contribution).toFixed(2) : acc,
                    0,
                ),
                stats: stats.map((stat: any) => ({
                    ...stat,
                    title: stat.key || '-',
                    primaryValue: +stat.value,
                    percentageChange: +stat.change,
                    secondaryValue: +stat.contribution,
                })),
            }
        },
    },
    topProducts: {
        url: APIEndPoints.checkoutAnalytics.topProducts,
        errMsg: 'Unable to fetch Top Products Metrics',
        defaultParams: {
            payment_method_filter: 'all',
            sales_by: 'count',
            sales_channel: 'all',
        },
        formatData: (data: any) => {
            const { lastUpdatedAt, stats } = data
            return {
                lastUpdatedAt,
                stats: stats.map((stat: any) => ({
                    ...stat,
                    title: stat.description || '-',
                    primaryValue: +stat.totalSales,
                })),
            }
        },
    },
    discountUsedMetrics: {
        url: APIEndPoints.checkoutAnalytics.topDiscountUsageMetrics,
        errMsg: 'Unable to fetch discount Usage Metrics',
        defaultParams: {
            payment_method_filter: 'all',
            sales_by: 'count',
            sales_channel: 'all',
        },
        formatData: (data: any) => {
            const { lastUpdatedAt, stats } = data
            return {
                lastUpdatedAt,
                stats: stats.map((stat: any) => ({
                    ...stat,
                    title: stat.coupon_code || '-',
                    secondaryValue: +stat.contribution,
                })),
            }
        },
    },
    discountFailedMetrics: {
        url: APIEndPoints.checkoutAnalytics.topDiscountFailureMetrics,
        errMsg: 'Unable to Fetch Failed Discount Metrics',
        defaultParams: {
            payment_method_filter: 'all',
            sales_by: 'count',
            sales_channel: 'all',
        },
        formatData: (data: any) => {
            const { lastUpdatedAt, stats } = data
            return {
                lastUpdatedAt,
                stats: stats.map((stat: any) => ({
                    ...stat,
                    title: stat.coupon_code || '-',
                    secondaryValue: +stat.contribution,
                })),
            }
        },
    },
    gmvSplitMetrics: {
        url: APIEndPoints.checkoutAnalytics.gmvSalesSplitMetrics,
        errMsg: 'Unable to Fetch Gmv Split Metrics',
        defaultParams: {
            split_by: 'mkt_source',
            sales_by: 'count',
            sales_channel: 'all',
        },
        formatData: (data: any) => {
            const { lastUpdatedAt, stats } = data
            return {
                lastUpdatedAt,
                stats: stats.map((stat: any) => ({
                    ...stat,
                    title: stat.source || '-',
                    secondaryValue: +stat.contribution,
                })),
            }
        },
    },
    discountUsageTrendMetrics: {
        url: APIEndPoints.checkoutAnalytics.discountUsageOverTimeMetrics,
        errMsg: 'Unable to Fetch discount usage trend Metrics',
        defaultParams: {
            payment_method_filter: 'all',
            sales_channel: 'all',
        },
        formatData: (data: any) => {
            const { lastUpdatedAt, stats } = data
            return { lastUpdatedAt, ...stats }
        },
    },
    addressPrefillTrendMetrics: {
        url: APIEndPoints.checkoutAnalytics.addressPrefillMetrics,
        errMsg: 'Unable to Fetch address prefill Metrics',
        defaultParams: {
            payment_method_filter: 'all',
            sales_channel: 'all',
        },
    },
    codMethodMetrics: {
        url: APIEndPoints.checkoutAnalytics.codMethodMetrics,
        errMsg:'Unable to Fetch cod method Metrics',
        formatData: (data: any) => {
            const { lastUpdatedAt, primaryBreakdown } = data
            return { lastUpdatedAt, stats: primaryBreakdown }
        },
    },
    abUTMAnalyticsMetrics: {
        url: APIEndPoints.checkoutAnalytics.abUTMAnalyticsMetrics,
        errMsg: 'Unable to Fetch AB UTM Analytics',
        defaultParams: {
            utm_param_filter: 'utm_source',
        },
    },
    abAnalyticsMetrics: {
        url: APIEndPoints.checkoutAnalytics.abAnalyticsMetrics,
        errMsg: 'Unable to Fetch AB Analytics Metrics',

        formatData: (data: any) => {
            const { lastUpdatedAt, stats } = data

            const tableData = stats

            const platforms = [
                ...new Set([
                    ...(tableData?.map((item) => item?.checkout_platform) ?? []),
                    'kwik_checkout',
                    'shopify_native',
                ]),
            ]

            const fields = Object.keys(tableData[0])?.filter((key) =>
                Object.keys(abAnalyticsTableFieldsMap)?.includes(key),
            )

            const result = fields.map((field) => {
                const row = { field }
                platforms.forEach((platform: string) => {
                    row[platform] = tableData?.find((item) => item.checkout_platform === platform)?.[field] ?? 0
                })

                row['diff'] =
                    field.toLowerCase().includes('rate') || field.toLowerCase().includes('percentage')
                        ? row['kwik_checkout'] - row['shopify_native']
                        : row['kwik_checkout'] === 0
                        ? row['shopify_native'] > 0
                            ? -100
                            : 0
                        : Number(
                              (((row['kwik_checkout'] - row['shopify_native']) / row['kwik_checkout']) * 100).toFixed(
                                  2,
                              ),
                          )

                return row
            })

            return { lastUpdatedAt, stats: result }
        },
    },
}

export const fetchAnalyticsData = async ({
    key,
    params,
    nestedKey,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
}) => {
    const { url, nestedKeys, defaultParams, formatData, isVd, realtime } = analyticsAPIs[key]
    let param = {
        ...(defaultParams || {}),
        ...(realtime
            ? {
                  startDate: params?.current_datetime_range?.split(',')[0],
                  endDate: params?.current_datetime_range?.split(',')[1],
                  ...params,
              }
            : isVd
            ? {
                  start_date: params?.current_datetime_range?.split(',')[0],
                  end_date: params?.current_datetime_range?.split(',')[1],
              }
            : params || {}),
    }
    let m_id = params.m_id
    delete param.m_id
    const response = await makeAPICall({
        method: 'get',
        url: process.env.REACT_APP_BASE_URL + url + `${realtime ? '/' + m_id : ''}`,
        params: param,
        skipLoader: true,
        skipMode: true,
    })
    if (response.success) {
        response.data.data = nestedKeys
            ? nestedKeys.reduce((result, curKey) => ({ ...result, [curKey]: response.data?.data?.[curKey] }), {})
            : response.data.data
        return {
            success: true,
            data:
                formatData && response.data.data && typeof response.data.data !== 'string'
                    ? formatData(response.data.data)
                    : response.data.data,
            nestedKeys,
        }
    }
    return { error: true, nestedKeys }
}
