import { Button, Modal } from "gokwik-ui-kit";
import installKwikCheckoutPreview from '@library/images/installKwikCheckoutPreview.png'
import { logEvent } from "@library/utilities/userLogEvents/userLogEvents";
import { useSelector } from 'react-redux';
import { getMerchantDetails, getUserConfig, getUserDetails } from '@store/user/selectors';
import { useState } from "react";
import { makeAPICall } from "@gokwik/utilities";
import APIEndPoints from "@library/utilities/constants/apiEndpoints";
import { useNavigate } from "react-router-dom";

const KwikCheckoutPrompt = ({ isInstallModalVisible, setIsInstallModalVisible }) => {
    const navigate = useNavigate()
    const config = useSelector(getUserConfig)
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const [isCheckingStatus, setIsCheckingStatus] = useState(false);

    const checkInstallationStatus = async (intervalId: NodeJS.Timeout) => {
        await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            method: 'get',
            skipLoader: true,
        }).then((result) => {
            if (result?.data?.data?.merchant_details?.onboarding_status?.kwik_checkout_app !== 'kwik_checkout_app_uninstalled') {
                clearInterval(intervalId);
                setIsCheckingStatus(false);
                setIsInstallModalVisible(false);
                navigate('/onboarding/cod-setup');
            }
        }).catch((err) => {
            console.warn(err)
        })
    };

    const installKwikCheckoutApp = () => {
        logEvent(
            "install_kwikCheckout_app_clicked",
            'click',
            'shopify-settings',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
        )
        window.open('https://apps.shopify.com/kwikcheckout', '_blank');
        
        setIsCheckingStatus(true);
        const intervalId = setInterval(() => {
            checkInstallationStatus(intervalId);
        }, 2000);
    }

    const shallCloseModal = () => {
        if (config?.supported_checkout_configs?.checkout_1 ||
            config?.supported_checkout_configs?.custom) {
            return true;
        } else {
            return false;
        }
    }
    
    return (
        <Modal
            open={isInstallModalVisible}
            footer={null}
            centered
            width={700}
            closable={shallCloseModal()}
            maskClosable={shallCloseModal()}
            onCancel={() => {
                setIsInstallModalVisible(false);
                navigate('/checkout/settings'); 
            }}
        >
            <div style={{ display: 'flex', flexDirection:'row', gap: '2rem', padding: '1rem' }}>
                <div style={{ borderRadius: 6 }} >
                    <img src={installKwikCheckoutPreview} alt="App Logo" style={{ borderRadius: 6, height: '15rem', width: '16rem' }} />
                </div>

                <div>
                    <span style={{ color: 'rgba(130, 0, 20, 1)', border: '1px solid rgba(255, 163, 158, 1)', background: 'rgba(255, 241, 240, 1)', padding: '0.25rem 0.5rem', fontSize: '12px', borderRadius: '0.25rem' }}>
                        Action Required
                    </span>
                    <div style={{ marginTop: '2rem', marginBottom: 8, fontSize: '24px', fontWeight: 600, lineHeight: '2rem' }}>
                        Install Kwik COD & Checkout to Access Your Dashboard
                    </div>
                    <div style={{ color: 'rgba(102, 112, 133, 1)',  fontWeight: '400', fontSize: '1rem', lineHeight: '20px'}}>
                        The app is missing from your dashboard. Install it now to continue.
                    </div>

                    <div style={{ marginTop: 16 }}>
                        <Button
                            onClick={installKwikCheckoutApp}
                            variant='primary'
                            className="flex items-center justify-center w-full p-6"
                            size="large"
                            disabled={isCheckingStatus}
                        >{isCheckingStatus ? "Checking Installation..." : "Install App"}</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default KwikCheckoutPrompt;
