import { filterStore } from '@gokwik/utilities';
import { AnyAction, Dispatch, createSlice } from '@reduxjs/toolkit';

type State = {
  discount: any;
};

const initialState: State = {
  discount: {},
};

const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {
    setFilters: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { setFilters } = filtersSlice.actions;
export const initialiseFilters = (data) => {
  setFilters(data);
};
export const saveFiltersData =
  (page: string, data: { [key: string]: any }) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setFilters({ [page]: data }));
    filterStore.getState().setFilters({ key: 'checkout_platform', data: { [page]: data } });
  };

export default filtersSlice.reducer;
