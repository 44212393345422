const APIEndPoints = {
    getOrderById: (id) => `/api/dashboard/order/${id}`,
    getOrders: '/api/dashboard/orders/all',
    getAbandonedCarts: '/api/dashboard/orders/Abandoned-cart',
    getAbcCount: '/api/dashboard/orders/Abandoned-cart/list/count',
    addCommentAbandonedCart: (id) => `/api/dashboard/orders/abandoned-cart/${id}/add-comment`,
    getFilters: '/api/dashboard/dashboard_filters',
    getMerchantList: '/api/dashboard/merchant/merchant-list',
    getAllMerchantList: '/api/dashboard/shopify-app/get-all-merchants',
    toggleMerchantManualCod: '/api/dashboard/shopify-app/manual-cod/update',
    getPincodeList: '/api/dashboard/pincode/files',
    getPin: '/api/dashboard/pincode/get-pin',
    upload: '/api/dashboard/pincode/upload/file',
    enablePin: '/api/dashboard/pincode/enable-pin',
    download: '/api/dashboard/pincode/download',
    presignedURL: '/api/dashboard/pincode/presigned-url',
    refundPresignedUrl: '/api/dashboard/refund/presigned-url',
    initiateRefund: '/api/dashboard/refund/initiate',
    fileS3PreSignedUrl: '/api/dashboard/pincode/presigned-url',
    fileS3UploadNotifyUrl: '/api/dashboard/pincode/upload/file',
    merchantPincodeFiles: '/api/dashboard/pincode/merchant-pincode-files',
    pincodeList: '/api/dashboard/pincode/merchant-pincode',
    productSearch: '/api/dashboard/product/search',
    getEditOrder: '/api/dashboard/order/get-edit-order',
    saveEditOrder: '/api/dashboard/order/save-order',
    getUtmList: '/api/dashboard/orders/utm-list',
    checkoutAnalytics: {
        checkoutFunnel: '/api/dashboard/merchantStats/checkout-funnel-metrics',
        checkoutFunnelTrendMetrics: '/api/dashboard/merchantStats/checkout-funnel-trend-metrics',
        orderMetrics: '/api/dashboard/merchantStats/order-metrics',
        socialMetrics: '/api/dashboard/merchantStats/social-sales-metrics',
        marketingMetrics: '/api/dashboard/merchantStats/marketing-sales-metrics',
        paymentMetrics: '/api/dashboard/merchantStats/payment-metrics',
        topProducts: '/api/dashboard/merchantStats/top-product-metrics',
        returningCustomers: '/api/dashboard/merchantStats/returning-customer-metrics',
        addressPrefillMetrics: '/api/dashboard/merchantStats/address-prefill-metrics',
        discountUsageOverTimeMetrics: '/api/dashboard/merchantStats/discount-usage-data-metrics',
        topDiscountUsageMetrics: '/api/dashboard/merchantStats/discount-usage-trend-metrics',
        topDiscountFailureMetrics: '/api/dashboard/merchantStats/discount-usage-failure-metrics',
        gmvSalesSplitMetrics: '/api/dashboard/merchantStats/gmv-sales-split-metrics',
        vdOrderMetrics: '/api/dashboard/vd/order-metrics',
        vdFunnelMetrics: '/api/dashboard/vd/funnel-metrics',
        vdCodRTORate: '/api/dashboard/vd/cod-rto-rate',
        vdCodShare: '/api/dashboard/vd/cod-share',
        realtimeStats: '/api/dashboard/stats',
    },
    downloadCohortCSV: '/api/dashboard/cohort-csv/download',
    getCohortPresignedUrl: '/api/dashboard/cohort-csv/presigned-url',
    marketingAnalytics: '/api/dashboard/merchantStats/campaign-data',
    distinctCampiagnData: '/api/dashboard/merchantStats/distinct-campaign-data',
    merchantConfigs: '/api/dashboard/merchant-config', //get, post
    fetchInternalConfig: '/api/dashboard/internal-config',
    updateDiscountConfig: '/api/dashboard/discount-config-update',
    updateInternalConfig: '/api/dashboard/internal-config-update',
    offersAndDiscounts: '/api/dashboard/merchant-discount',
    offersAndDiscountsAll: '/api/dashboard/merchant-discount/all',
    discountConfig: {
        createDiscount: '/discount',
        generateDiscountCode: '/discount/generate-codes',
        getDiscountsList: '/discount/list-discounts',
        getDiscount: (discount_id) => `/discount/${discount_id}`,
        deleteDiscount: (discount_id) => `/discount/${discount_id}`,
        getDiscountPresignedURL: '/api/dashboard/discount/presigned-url',
        getFileUploadPath: '/api/dashboard/discount/upload/file',
        getProductsList: '/discount/items/search-products',
        getCustomersList: (m_id, text) => `/api/dashboard/shopify/fetch-customers/${m_id}?customQuery=${text}`,
        getCustomerSegments: (m_id, text) =>
            `/api/dashboard/shopify/fetch-customer-segments/${m_id}?customQuery=${text}`,
        getCollectionsList: (m_id, text) =>
            `/api/dashboard/shopify/fetch-product-collections/${m_id}?customQuery=${text}`,
        getBulkCodesCSV: '/discount/download-file',
        CSVValidityCheck: '/discount/check-discount-file',
    },
    shopifyMerchantConfigs: '/api/dashboard/shopify-app/merchant-config',
    shopifyShippingAndPaymentRule: '/api/dashboard/shopify-app/payment-shipping-customization',
    shopifyAppPrepaidDiscountSaveConfig: '/api/dashboard/shopify-app/prepaid-discounts',
    shopifyReviewAndRating: '/api/dashboard/shopify-app/send-email',
    updateMerchant: (merchant_id: string) => `/api/dashboard/merchant/update/${merchant_id}`,
    userEvent: '/api/dashboard/event',
    getBulkMerchantHealth: '/api/dashboard/shopify/bulk-merchant-health',
    updateMerchantHealth: '/api/dashboard/shopify/merchant-health',
    getGlobalStatus: '/api/dashboard/shopify/global-status',
    updateGlobalStatus: '/api/dashboard/shopify/global-status',
    getMerchantHealth: '/api/dashboard/shopify/merchant-health',
    bulkDiscounts: {
        validateDiscountSet: '/api/validate-price-rule',
        getDiscountsList: '/api/list-campaigns',
        createDiscount: '/api/campaign',
        presignedUrl: '/api/presigned-url',
    },
    getCustomerPrepaidHistory: `/v1/shopify/is-prepaid-customer`,
    getFailSafeState : `/api/dashboard/shopify-app/failsafe`,
    updateFailSafe : `/api/dashboard/shopify-app/failsafe`,
    fetchBillingStatus: '/api/dashboard/shopify-app/billing-subscription/status',
    getColumnPreferences: '/api/dashboard/column_filters',
    updateColumnPreferences: '/api/dashboard/update_column_filters',
    orderAnalytics: '/api/dashboard/order/analytics',
}
export default APIEndPoints
