import { useState, useEffect } from 'react'
import { Col, Input, Row, Switch, Button, Tooltip, QuestionCircleOutlined, message } from 'gokwik-ui-kit'
import { IShopifyNewCheckoutUIConfig } from '@library/utilities/interface'
import LoginPagePreview from './loginPagePreview'
import { defaultShopifyNewCheckoutConfig, loginPageTooltips } from './constants'
import BannerFunction from './banner'
import SaveBar from '@library/components/save-bar'

const LoginPageCustomisation = ({
    updatedCheckoutUIConfig,
    setUpdatedCheckoutUIConfig,
    setShowCopyConfirm,
    config,
    saveConfig,
    fireDiscardSaveEvent,
}) => {
    const [isUpdated, setIsUpdated] = useState<boolean>(false)
    const [isBannerUpdated, setIsBannerUpdated] = useState(false)
    const paymentUIBanners = config?.newPaymentsUIConfig?.textConfig?.bannerText?.filter((banner) => banner?.length)

    useEffect(() => {
        if (config?.newCheckoutUIConfig) {
            const banners = config.newCheckoutUIConfig?.textConfig?.otherSections?.brandBanner?.map((banner) => {
                return banner.includes('|') ? banner.split('|')[1] : banner
            })
            const updatedConfig = {
                ...config.newCheckoutUIConfig,
                textConfig: {
                    ...config.newCheckoutUIConfig.textConfig,
                    otherSections: {
                        ...config.newCheckoutUIConfig.textConfig.otherSections,
                        brandBanner: banners,
                    },
                },
            }
            setUpdatedCheckoutUIConfig(updatedConfig)
        }
    }, [config?.newCheckoutUIConfig])

    const validateLoginPageConfig = () => {
        if (
            updatedCheckoutUIConfig?.uiConfig?.showBrandBanner &&
            updatedCheckoutUIConfig?.textConfig?.otherSections?.brandBanner?.length < 1
        ) {
            return {
                msg: 'Please add banner text',
                status: false,
            }
        }
        if (updatedCheckoutUIConfig?.textConfig?.login?.cta?.trim()?.length < 1) {
            return {
                msg: 'Please add CTA button text',
                status: false,
            }
        }
        if (updatedCheckoutUIConfig?.textConfig?.login?.checkboxText?.trim()?.length < 1) {
            return {
                msg: 'Please add consent box text',
                status: false,
            }
        }
        return {
            msg: 'newUiLoginPageConfig validation success',
            status: true,
        }
    }

    const onDiscard = () => {
        //reset
        setUpdatedCheckoutUIConfig(config?.newCheckoutUIConfig ?? defaultShopifyNewCheckoutConfig)
        setIsUpdated(false)
        fireDiscardSaveEvent()
    }

    const onSave = () => {
        const validation = validateLoginPageConfig()
        if (validation.status) {
            // Trim values of updated config
            if (isBannerUpdated && config?.newPaymentsUIEnabled && paymentUIBanners.length < 3) {
                setShowCopyConfirm(true)
                setIsBannerUpdated(false)
                setIsUpdated(false)
            } else {
                setShowCopyConfirm(false)
                const trimmedConfig = { ...updatedCheckoutUIConfig }
                if (trimmedConfig.textConfig?.login?.cta) {
                    trimmedConfig.textConfig.login.cta = trimmedConfig.textConfig.login.cta.trim()
                }
                if (trimmedConfig.textConfig?.login?.checkboxText) {
                    trimmedConfig.textConfig.login.checkboxText = trimmedConfig.textConfig.login.checkboxText.trim()
                }

                //update config
                saveConfig(
                    {
                        newCheckoutUIConfig: trimmedConfig,
                    },
                    () => setIsUpdated(false),
                )
            }
        } else {
            message.error(validation.msg)
        }
    }

    return (
        <div className='w-full'>
            <Row gutter={16}>
                <Col span={24}>
                    {' '}
                    {/* Set span to 24 to occupy the full width */}
                    <div className='bg-gray-100 m-4 p-4 bg-white border-none rounded-lg ml-2 mb-2'>
                        {/* Banner Section */}
                        <BannerFunction
                            updatedConfig={updatedCheckoutUIConfig}
                            setUpdatedConfig={setUpdatedCheckoutUIConfig}
                            setIsUpdated={setIsUpdated}
                            setIsBannerUpdated={setIsBannerUpdated}
                            type='checkoutUI'
                        />

                        {/* CTA Button Section */}
                        <div className='mb-6 p-4 max-w-4xl mx-auto'>
                            <p className='mb-2 text-base font-semibold'>
                                CTA Text{' '}
                                <Tooltip title={<>{loginPageTooltips.CtaButton}</>}>
                                    <QuestionCircleOutlined className='opacity-40' />
                                </Tooltip>
                            </p>
                            <div>
                                <label className='block mb-1 text-sm font-medium text-gray-500'>
                                Use this field to change the name of the primary button on gokwik checkout
                                </label>
                                <Input
                                    value={updatedCheckoutUIConfig?.textConfig?.login?.cta}
                                    placeholder='Eg: Proceed'
                                    maxLength={18}
                                    onChange={(e) => {
                                        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
                                        setUpdatedCheckoutUIConfig((prev) => ({
                                            ...prev,
                                            textConfig: {
                                                ...prev.textConfig,
                                                login: {
                                                    ...prev.textConfig.login,
                                                    cta: sanitizedValue,
                                                },
                                            },
                                        }))
                                        setIsUpdated(true)
                                    }}
                                    showCount
                                />
                            </div>
                        </div>

                        {/* Guest Checkout Section */}
                        <div className='mb-6 p-4 max-w-4xl mx-auto'>
                            <div className='flex items-center justify-between'>
                                <div className='flex flex-col space-y-1'>
                                    <p className='text-base font-semibold'>
                                        Guest Checkout{' '}
                                        <Tooltip title={<>{loginPageTooltips.GuestCheckout}</>}>
                                            <QuestionCircleOutlined className='opacity-40' />
                                        </Tooltip>
                                    </p>
                                    <p className='mb-1 text-sm font-medium text-gray-500'>
                                        Users doesn't need to confirm before the payment
                                    </p>
                                </div>

                                <Switch
                                    checked={updatedCheckoutUIConfig?.uiConfig?.showGuestCheckoutOption}
                                    onChange={(checked) => {
                                        setUpdatedCheckoutUIConfig((prev) => ({
                                            ...prev,
                                            uiConfig: {
                                                ...prev.uiConfig,
                                                showGuestCheckoutOption: checked,
                                            },
                                        }))
                                        setIsUpdated(true)
                                    }}
                                />
                            </div>
                        </div>

                        {/* Consent Box Text Section */}
                        <div className='p-4 max-w-4xl mx-auto'>
                            <p className='mb-2 text-base font-semibold'>
                                Consent Box Text{' '}
                                <Tooltip title={<>{loginPageTooltips.ConsentBox}</>}>
                                    <QuestionCircleOutlined className='opacity-40' />
                                </Tooltip>
                            </p>
                            <p className='mb-2 text-gray-500'>Let's users pre-pay for their COD orders(s)</p>

                            <div>
                                <Input
                                    placeholder='Eg: Keep me posted about new deals'
                                    maxLength={40}
                                    value={updatedCheckoutUIConfig?.textConfig?.login?.checkboxText}
                                    onChange={(e) => {
                                        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
                                        setUpdatedCheckoutUIConfig((prev) => ({
                                            ...prev,
                                            textConfig: {
                                                ...prev.textConfig,
                                                login: {
                                                    ...prev.textConfig.login,
                                                    checkboxText: sanitizedValue,
                                                },
                                            },
                                        }))
                                        setIsUpdated(true)
                                    }}
                                    showCount
                                />
                            </div>
                        </div>
                        {isUpdated && <SaveBar handleSave={onSave} handleDiscard={onDiscard} />}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default LoginPageCustomisation
