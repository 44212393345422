import { useEffect, useRef, useState } from 'react'

import '../settingsMenu.css'
import Sparkels from '@library/images/sparkles.svg'
import expand from '@library/images/expand.svg'
import check from '@library/images/check.svg'
import minus from '@library/images/MinusOutlined.svg'
import codVideo from '@library/images/addressPrefill.gif'
import sparklesLightBlue from '@library/images/sparkles_lightBlue.svg'
import { Button } from 'gokwik-ui-kit'
import { useNavigate } from 'react-router-dom'

const messages = [
    {
        text: 'Address Autofill for ~80%* of Users',
        bgGradiant: 'spotlight-gradiant',
    },
    {
        text: 'Prepaid Payments via UPI, Net-Banking, Wallets, Cards, BNPL and More',
    },
    {
        text: 'Partial Payment on COD to Collect Upfront Amount',
    },
    {
        text: 'Discounts on Prepaid Orders',
    },
    {
        text: 'Display and Apply Discount Coupons during Checkout',
    },
]
const UpsellCheckout = ({ settingsEvents }) => {
    const [upgradePlanClickCount, setUpgradePlanClickCount] = useState(0)
    const [hideWidget, setHideWidget] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [isFading, setIsFading] = useState(false)
    const [containerHeight, setContainerHeight] = useState(0)
    const messageRef = useRef(null)
    const [isExpandWidget, setIsExpandWidget] = useState(true)
    const navigate = useNavigate()
    const closePopup = () => {
        settingsEvents.fireUpgradeWidgetCollapsed()
        const newClickCount = upgradePlanClickCount + 1
        setUpgradePlanClickCount(newClickCount)
        sessionStorage.setItem('upgradePlanClickCount', newClickCount.toString())
        if (newClickCount >= 3) {
            setHideWidget(true)
        }
        setIsExpandWidget(false)
    }

    const getStartedWithCheckout = () => {
        navigate('/onboarding/upgrade-plan', {
            state: {
                merchantType: 'kwik_cod',
            },
        })
        settingsEvents.fireUpgradePlanEvent()
    }

    const widgetClicked = () => {
        setIsExpandWidget(true)
        settingsEvents.fireWidgetClicked()
    }

    useEffect(() => {
        if (messageRef.current) {
            setContainerHeight(messageRef.current.offsetHeight)
        }
        const interval = setInterval(() => {
            setIsFading(true)
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length)
                setIsFading(false)
            }, 250)
        }, 2500)

        if (isExpandWidget) {
            settingsEvents.fireUpgradeWidgetExpanded()
        }
        return () => clearInterval(interval)
    }, [isExpandWidget])

    useEffect(() => {
        // Update the container height when the message changes
        if (messageRef.current) {
            setContainerHeight(messageRef.current.offsetHeight)
        }
    }, [currentIndex])

    // Initialize click count from sessionStorage when component mounts
    useEffect(() => {
        const storedClickCount = parseInt(sessionStorage.getItem('upgradePlanClickCount')) || 0
        setUpgradePlanClickCount(storedClickCount)
        if (storedClickCount >= 3) {
            setHideWidget(true) // Disable button if click count is 3 or more
        }
    }, [])

    if (hideWidget) {
        return null
    }
    return (
        <div className='mt-[auto]'>
            {!isExpandWidget && (
                <div className='w-[400px] mt-[auto] mb-[70px]'>
                    <div className='w-[200px]'>
                        <div
                            className='feature-container bg-[#004B8D] w-full flex justify-center items-center pt-[15px] pb-[35px] mb-[-20px] rounded-lg mt-[auto]'
                            style={{ height: containerHeight }}
                        >
                            <div
                                className={`about-plan pt-[15px] gap-[8px] text-[14px] align-center flex items-start space-between ${
                                    isFading ? 'fade-out' : 'fade-in'
                                }`}
                                ref={messageRef}
                            >
                                <div
                                    className={`w-[90%] m-[auto] mb-[15px] rounded-lg flex items-start gap-[8px] text-[12px] p-2 ${
                                        messages[currentIndex].bgGradiant && messages[currentIndex].bgGradiant
                                    } `}
                                >
                                    <img src={check} /> {messages[currentIndex].text}
                                    {messages[currentIndex].bgGradiant && (
                                        <img src={sparklesLightBlue} className='m-[auto]' />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        style={{ border: 'none', outline: 'none' }}
                        className='upgrade-plan-btn flex items-center p-[6px] gap-[10px] w-[200px]  rounded-lg'
                        onClick={widgetClicked}
                    >
                        <img src={Sparkels} alt='sparkels' />
                        <div className='text-[#004B8D] font-bold'>Upgrade Plan</div>
                        <img className='ml-[auto] bg-[#c2d8f1] p-[6px] rounded-lg w-[30px]' src={expand} />
                    </button>
                </div>
            )}
            <div className={`upgrade-plan-expanded fixed w-[380px] ${isExpandWidget ? 'open' : 'closed'}`}>
                {isExpandWidget && (
                    <div>
                        <div
                            className='tab-heading flex items-center text-[#004B8D] p-2 gap-2 rounded'
                            style={{ background: 'linear-gradient(to right, #91CAFF, #E6F4FF' }}
                        >
                            <div>
                                <img src={Sparkels} />
                            </div>
                            <div>Get Checkout</div>
                            <div className='ml-[auto] bg-[#c8def1] rounded'>
                                <img src={minus} onClick={closePopup} className='p-[4px]' />
                            </div>
                        </div>
                        <div className='plan-video'>
                            <img src={codVideo} style={{ height: 'auto', width: '100%', background: '#004B8D' }} />
                        </div>
                        <div className='plan-description bg-[#004B8D] text-[#fff] p-4'>
                            <div className='heading text-[20px] font-bold mb-5'>Add Checkout Features</div>
                            <div className='plan-features font-medium font-medium text-[14px] flex flex-col gap-2'>
                                {messages.map((message, index) => (
                                    <div
                                        key={index}
                                        style={{ lineHeight: '1.4rem' }}
                                        className={`flex items-start gap-2 p-1 rounded-lg ${
                                            message.bgGradiant && message.bgGradiant
                                        }`}
                                    >
                                        <img src={check} />
                                        <p>{message.text}</p>
                                        {message.bgGradiant && <img src={sparklesLightBlue} className='m-[auto]' />}
                                    </div>
                                ))}
                            </div>
                            <Button
                                onClick={getStartedWithCheckout}
                                style={{ border: 'none', outline: 'none' }}
                                className='mt-[20px] flex items-center justify-center text-[16px] p-[20px] w-[100%]'
                            >
                                Upgrade Plan
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UpsellCheckout
