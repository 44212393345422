import { ReactNode, useMemo, useRef, useEffect, useState, Fragment } from 'react'
import React from 'react'

import { Layout, Menu, Select, Tooltip, UpOutlined } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import ShopifyAppPincode from '@pages/settings/shopify-app-pincode-settings'
import ShopifyAppShipping from '@pages/settings/shopify-app-shipping-settings'
import Discount from '@pages/settings/discount-settings'
import { CreateDiscountContainer } from './discount-settings/create-discount'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserConfig, getUserDetails } from '@store/user/selectors'
import { stepConfiguration, newStepConfiguration } from '@library/utilities/constants/constants'
import CodFeatures from './cod-features'
import UiCustomisation from './shopify-app-ui-customisations'
import DiscountSuggestions from './shopify-app-ui-customisations/discount-suggestions'
import CheckoutUiCustomisation from './shopify-app-ui-customisations/gokwik-checkout-ui'
import ReviewComponent from './cod-features/helpers/ReviewComponent'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchShopifyAppMerchantConfig } from '@store/shopify-app'
import { isShopifyAppCheckoutEnabled, shopifyAppMerchantConfig } from '@store/shopify-app/selectors'
import PrepaidDiscounts from './shopify-app-payment-configs/prepaid-discounts'
import PaymentFeatures from './shopify-app-payment-configs'
import PpcodConfig from './shopify-app-payment-configs/ppcod'
import ShippingAndPaymentCustomizations from './cod-features/shipping-and-payment'
import CodVerification from './cod-features/cod-verification'
import { FireEvents } from '@library/utilities/helpers/eventsHelper'
import { fireClickedEventOnSettingsPage, kycAndPaymentStatus } from '@library/utilities/helpers/helper'
import UpsellCheckout from './upsell/upsellCheckout'
import { makeAPICall, titleCase } from '@gokwik/utilities'
import CodPageUiCustomisation from './cod-ui-customisations'
import blueCheck from '../../library/images/completedCheck.svg'
import redirect from '../../library/images/redirectionLink.svg'
import incompleteStep from '../../library/images/incompleteStep.svg'
import sparkles from '../../library/images/sparkles.svg'
import openLock from '../../library/images/lock-open.svg'
import tooltipCancel from '../../library/images/cancel-tooltip.svg'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import './settingsMenu.css'
import KwikCheckoutPrompt from './onboardingStep/kwikCheckoutPrompt'
import NudgeCard from './trialPeriodNudge'
import CodPopUp from './codLite-popup'
import ChoosePlan from './choosePlan'
import PrepaidDiscountsWithstoreCredits from './shopify-app-payment-configs/prepaid-discount-with-store-credits'

const { Sider } = Layout
interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
    className?: string
    style?: React.CSSProperties
}

function createMenuItem(
    label: ReactNode,
    key: string,
    className?: string,
    children?: MenuItem[],
    type?: 'group',
    style?: React.CSSProperties,
): MenuItem {
    return {
        key,
        children,
        label,
        type,
        className,
        style,
    }
}

export default function () {
    const merchant_details = useSelector(getMerchantDetails)
    const config = useSelector(getUserConfig)
    const route = useLocation()
    const [showCodPlanPopUp, setShowCodPlanPopUp] = useState(
        JSON.parse(sessionStorage.getItem(`isChoosePlan_${merchant_details?.m_id}`) || 'false'),
    )
    const [showCodPop, setShowCodPop] = useState(
        JSON.parse(sessionStorage.getItem(`isCodVisible_${merchant_details?.m_id}`) || 'true'),
    )
    const navigate = useNavigate()
    const [isInstallModalVisible, setIsInstallModalVisible] = useState(false);
    const [codShippingRate, setCodShippingRate] = useState(false)
    const [isCountryIndia, setIsCountryIndia] = useState(true)
    const [approvedChargesStatus, setApprovedChargesStatus] = useState('')
    const [currentOnboardingStatus, setCurrentOnboardingStatus] = useState('')
    const [codLitebillingStatus, setCodLitebillingStatus] = useState('')
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [showNudge, setShowNudge] = useState(false)
    const [isKyc, setIsKyc] = useState(true)
    const [remainingTrialDays, setRemainingTrialDays] = useState(0)
    const [isTrialExpired, setIsTrialExpired] = useState(false)
    const showKycNudgeStatusList = [
        'kyc_viewed',
        'kyc_basic_info_added',
        'kyc_address_details',
        'kyc_bank_details',
        'kyc_business_details',
        'kyc_document_upload',
        'kyc_vpc',
        'kyc_verification_pending',
        'kyc_verification_failed',
    ]

    const showPaymentNudgeStatusList = [
        'setup_completed',
        'kyc_completed',
        'gk_payments_agreement_signed',
        'kwik_checkout_button_activated',
    ]
    const slideInterval = 2000

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slideMessages.length)
        }, slideInterval)

        return () => clearInterval(interval)
    }, [])

    const slideMessages = useMemo(() => {
        const trialMessage = isTrialExpired
            ? `Your trial period is expired`
            : remainingTrialDays === 0
            ? 'Your trial period will expire today'
            : `${remainingTrialDays} days left in your trial period`

        return [
            isKyc && merchant_details?.kyc_completion_status?.toLowerCase() !== 'approved'
                ? 'Complete KYC to avoid extra charges'
                : 'Install to avoid extra charges',
            trialMessage,
        ]
    }, [remainingTrialDays, isKyc, merchant_details, isTrialExpired])

    const showTourPopup = useMemo(() => {
        return !(
            (showCodPop &&
                codLitebillingStatus === 'PENDING' &&
                (merchant_details?.onboarding_status?.cod_lite_app === 'new_merchant_app_installed' ||
                    merchant_details?.onboarding_status?.cod_lite_app === 'existing_merchant_app_installed')) ||
            (showCodPlanPopUp &&
                merchant_details?.onboarding_status?.cod_lite_app === 'existing_merchant_app_installed')
        )
    }, [showCodPop, showCodPlanPopUp, codLitebillingStatus, merchant_details])

    useEffect(() => {
        const fetchData = async () => {
            await fetchBillingStatus('kwik_checkout')
            setCurrentOnboardingStatus(merchant_details?.onboarding_status?.kwik_checkout_app)
            if (merchant_details?.onboarding_status?.kwik_checkout_app === 'kwik_checkout_app_uninstalled') {
                setIsInstallModalVisible(true);
            } else if (isInstallModalVisible) {
                setIsInstallModalVisible(false)
            }
        }
        fetchData()
    }, [merchant_details?.onboarding_status?.kwik_checkout_app])
    useEffect(() => {
        const fetchData = async () => {
            await fetchBillingStatus('cod_lite')
        }
        fetchData()
    }, [merchant_details?.onboarding_status?.cod_lite_app])

    const fetchFreeTrialDays = async () => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getRemainingTrialDays}?app=kwik_checkout`,
            })
            if (response?.data?.status_code === 200) {
                const today = new Date().getTime()
                const trialEnd = new Date(response?.data?.data?.trialEndDate).getTime()
                setIsTrialExpired(response?.data?.data?.trialDays === 0 && today > trialEnd)
                setRemainingTrialDays(response?.data?.data?.trialDays)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchBillingStatus = async (appName: string) => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.fetchBillingStatus}?app=${appName}`,
            })
            if (response?.data?.status_code === 200) {
                if (appName === 'kwik_checkout') setApprovedChargesStatus(response?.data?.data?.status)
                else {
                    if (response?.data?.data?.status === 'APPROVED')
                        setCodLitebillingStatus(response?.data?.data?.status)
                    else setCodLitebillingStatus('PENDING')
                }
            } else {
                if (appName === 'cod_lite') setCodLitebillingStatus('PENDING')
            }
        } catch (error) {
            console.log(error)
        }
    }
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })
    const user_details = useSelector(getUserDetails)

    const isKycCompleted = () => {
        return merchant_details?.kyc_completion_status?.toLowerCase() === 'approved'
    }

    const [userJourneySteps, setUserJourneySteps] = useState(() => {
        const steps = [
            { id: 'cod-activation', text: 'Activate GoKwik COD' },
            { id: 'enable-gk-checkout', text: 'Enable GoKwik Checkout' },
            { id: 'payments-checkout-config', text: 'Enable Payment Options' },
        ]
        if (!isKycCompleted()) {
            steps.splice(2, 0, { id: 'kyc-verification', text: 'Verify Your Business (KYC)' })
        }
        return steps
    })

    const checkoutPlatformKeys = useMemo(
        () =>
            Object.entries(config?.supported_checkout_configs || {}).reduce(
                (result: string[], item: [string, boolean]) =>
                    item[1] && item[0] !== 'custom' ? [...result, item[0]] : result,
                [],
            ),
        [config],
    )
    const [checkoutPlatform, setCheckoutPlatform] = useState(
        localStorage.getItem('checkoutPlatform') ? localStorage.getItem('checkoutPlatform') : 'kwik_checkout',
    )

    const eventsClass = new FireEvents(merchant_details, user_details).features
    const settingsEvents = eventsClass.settingsPageEvents

    const isPaymentSetupComplete = kycAndPaymentStatus(merchant_details, 'isPaymentSetupComplete')

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchShopifyAppMerchantConfig())
        fetchFreeTrialDays()
        settingsEvents.fireClickedEvent()
    }, [])

    useEffect(() => {
        if (currentOnboardingStatus && !showKycNudgeStatusList?.includes(currentOnboardingStatus)) setIsKyc(false)
    }, [currentOnboardingStatus])

    useEffect(() => {
        if (config?.supported_checkout_configs?.cod_lite && !config?.supported_checkout_configs?.kwik_checkout) {
            setCheckoutPlatform('cod_lite')
        }
    }, [config?.supported_checkout_configs])

    useEffect(() => {
        if (merchant_details?.country && merchant_details?.country?.toLowerCase() !== 'india') {
            setIsCountryIndia(false)
            setUserJourneySteps([{ id: 'cod-activation', text: 'Activate GoKwik COD' }])
        }
    }, [merchant_details.country])

    const shopifyAppCheckoutEnabled = useSelector(isShopifyAppCheckoutEnabled)
    const shopifyMerchantConfig = useSelector(shopifyAppMerchantConfig)
    const isNonPlusMerchant = shopifyMerchantConfig?.isNonPlusMerchant
    const storeCreditDiscountEnabled = shopifyMerchantConfig?.storeCreditDiscountEnabled
    const subItemsStyle = {
        marginLeft: '-3px',
        width: 'auto',
        display: 'block',
        textIndent: '-39px',
        lineHeight: '2',
    }

    const settingsList = useMemo(
        () => [
            {
                title: 'Pincode Serviceability',
                path: '/pincode',
                component: () => (
                    <ShopifyAppPincode
                        events={eventsClass.pincodeServiceabilityEvents}
                        reviewFlowEvents={eventsClass.reviewFlowEvents}
                        showTourPop={showTourPopup}
                    />
                ),
                className: 'pincode-serviceability-item',
                hideInMenu: checkoutPlatform === 'cod_lite' || false,
            },
            {
                title: 'Shipping Serviceability',
                path: '/shipping',
                component: () => (
                    <ShopifyAppShipping events={eventsClass.shippingServiceabilityEvents} showTourPop={showTourPopup} />
                ),
                className: 'shipping-serviceability-item',
            },
            {
                title: 'All Discounts',
                path: '/discount',
                component: () => <Discount events={eventsClass.customDiscountsEvents} showTourPop={showTourPopup} />,
                className: 'all-discounts-item',
            },
            {
                title: 'All Discounts',
                path: '/create-discount',
                component: () => <CreateDiscountContainer events={eventsClass.customDiscountsEvents} />,
                hideInMenu: true,
            },
            {
                title: 'All Discounts',
                path: '/edit-discount/:id',
                component: () => <CreateDiscountContainer events={eventsClass.customDiscountsEvents} />,
                hideInMenu: true,
            },
            {
                title: 'COD Verification',
                path: '/cod-features/cod-verification',
                component: () => <CodVerification events={eventsClass} />,
                hideInMenu: true,
            },
            {
                title: 'Shipping and Payment Rules',
                path: '/cod-features/shipping-payment-rules',
                component: () => <ShippingAndPaymentCustomizations events={eventsClass} />,
                hideInMenu: true,
            },
            {
                title: 'COD Features',
                path: '/cod-features',
                component: () => <CodFeatures events={eventsClass} showTourPop={showTourPopup} />,
                className: 'cod-features-item',
                subItems: [
                    {
                        label: 'Shipping and Payment Rules',
                        key: '/shopify-app/settings/cod-features/shipping-payment-rules',
                        style: subItemsStyle,
                    },
                    checkoutPlatform !== 'cod_lite' && {
                        label: 'COD Verification',
                        key: '/shopify-app/settings/cod-features/cod-verification',
                        style: subItemsStyle,
                    },
                ],
            },
            ...(isPaymentSetupComplete
                ? [
                      {
                          title: 'Payment Features',
                          path: '/payment-features',
                          component: () => (
                              <PaymentFeatures events={eventsClass.paymentFeaturesEvents} showTourPop={showTourPopup} />
                          ),
                          className: 'payment-features',
                          subItems: [
                              {
                                  label: 'Partial Payment COD',
                                  key: '/shopify-app/settings/payment-features/ppcod',
                                  style: subItemsStyle,
                              },
                              {
                                  label: 'Prepaid Discount Rule',
                                  key: '/shopify-app/settings/payment-features/prepaid-discounts',
                                  style: subItemsStyle,
                              },

                              {
                                  label: 'Prepaid Store Credits',
                                  key: '/shopify-app/settings/payment-features/prepaid-discounts-with-store-credits',
                                  style: subItemsStyle,
                              },
                          ],
                      },
                      {
                          title: 'PPCOD',
                          path: '/payment-features/ppcod',
                          component: () => <PpcodConfig events={eventsClass.ppcodEvents} showTourPop={showTourPopup} />,
                          className: 'shopify-app-pppcod',
                          hideInMenu: true,
                      },
                      {
                          title: 'Prepaid Discounts',
                          path: '/payment-features/prepaid-discounts',
                          component: () => (
                              <PrepaidDiscounts
                                  events={eventsClass.prepaidDiscountsEvents}
                                  showTourPop={showTourPopup}
                              />
                          ),
                          className: 'prepaid-discounts',
                          hideInMenu: true,
                      },
                      {
                          title: 'Prepaid Discounts with Store Credit',
                          path: '/payment-features/prepaid-discounts-with-store-credits',
                          component: () => (
                              <PrepaidDiscountsWithstoreCredits events={eventsClass.prepaidDiscountsEvents} />
                          ),
                          className: 'prepaid-discounts-with-store-credits',
                          hideInMenu: true,
                      },
                  ]
                : []),
            ...(shopifyAppCheckoutEnabled || isPaymentSetupComplete
                ? [
                      {
                          title: 'UI Customisation',
                          path: '/ui-customisation',
                          component: () => <UiCustomisation events={eventsClass} />,
                          className: 'ui-customisation-item',
                          hideInMenu: checkoutPlatform === 'cod_lite' || false,
                          subItems: [
                              {
                                  label: 'Theme',
                                  key: '/shopify-app/settings/ui-customisation',
                                  style: subItemsStyle,
                              },
                              {
                                  label: 'Pages',
                                  key: '/shopify-app/settings/ui-customisation/checkout-ui',
                                  style: subItemsStyle,
                              },
                              ...(shopifyAppCheckoutEnabled || !isNonPlusMerchant
                                  ? [
                                        {
                                            label: 'Discount Suggestions',
                                            key: '/shopify-app/settings/ui-customisation/discount-suggestions',
                                            style: subItemsStyle,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                      {
                          title: 'Pages',
                          path: '/ui-customisation/checkout-ui',
                          component: () => (
                              <CheckoutUiCustomisation
                                  events={eventsClass.loginUICustomisationsEvents}
                                  paymentUICustomisationEvents={eventsClass.paymentUICustomisationsEvents}
                              />
                          ),
                          className: 'checkout-ui-item',
                          hideInMenu: true,
                      },
                      {
                          title: 'Discount Suggestions',
                          path: '/ui-customisation/discount-suggestions',
                          component: () => <DiscountSuggestions events={eventsClass.discountSuggestionsEvents} />,
                          className: 'discount-suggestions-item',
                          hideInMenu: true,
                      },
                  ]
                : [
                      {
                          title: 'UI Customisation',
                          path: '/ui-customisation/cod',
                          component: () => (
                              <CodPageUiCustomisation events={eventsClass.codPageUICustomisationsEvents} />
                          ),
                          hideInMenu: checkoutPlatform === 'cod_lite' || false,
                          className: 'cod-ui-customisation-item',
                      },
                  ]),
        ],
        [config, shopifyAppCheckoutEnabled, checkoutPlatform, showTourPopup],
    )

    const menuItems = () => {
        return settingsList.reduce((result, item) => {
            if (item.hideInMenu) return result

            return [
                ...result,
                createMenuItem(item.title, `/shopify-app/settings${item.path}`, item.className, item.subItems),
            ]
        }, [])
    }

    const openItems = useMemo(() => {
        const menu = menuItems()

        const openedKey = menu.find((item) => {
            if (item.key === route.pathname) {
                return true
            }
            if (item.children) {
                return item.children.some((child) => child.key === route.pathname)
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
        } else if (previousOpenedItems.current.openedKey) {
            return {
                ...previousOpenedItems.current,
            }
        } else {
            let similarSelectedKey: MenuItem | undefined
            const similarKey = menu.find(
                (item) =>
                    !!item.children?.find((child) => {
                        if (route.pathname.includes(child.key)) {
                            similarSelectedKey = child
                            return true
                        } else return false
                    }),
            )

            previousOpenedItems.current = {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
            return {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
        }
    }, [route.pathname, settingsList])

    const eventMappings: Record<string, string> = {
        '/shopify-app/settings/pincode': 'pincode',
        '/shopify-app/settings/shipping': 'shipping',
        '/shopify-app/settings/discount': 'discounts',
        '/shopify-app/settings/cod-features': 'cod_features',
        '/shopify-app/settings/payment-features': 'payment_features',
        '/shopify-app/settings/ui-customisation': 'ui_customisation',
        '/shopify-app/settings/ui-customisation/cod': 'cod_ui_customisation',
    }

    const handleMenuSelection = ({ key }: any) => {
        const eventName = eventMappings[key]

        fireClickedEventOnSettingsPage(eventName, eventsClass)
        navigate(key)
    }

    const handleCodPlanPopUp = () => {
        setShowCodPlanPopUp((prev) => {
            const newValue = !prev
            sessionStorage.setItem(`isChoosePlan_${merchant_details?.m_id}`, JSON.stringify(newValue))
            return newValue
        })
        setCheckoutPlatform('cod_lite')
        setShowCodPop((prev) => {
            const newValue = !prev
            sessionStorage.setItem(`isCodVisible_${merchant_details?.m_id}`, JSON.stringify(newValue))
            return newValue
        })
    }

    const redirectForCodLiteActivation = (marchantDetails) => {
        const activationUrl = marchantDetails?.redirect_urls?.cod_lite_app_activation_url
        if (activationUrl) {
            window.open(activationUrl, '_blank')
        }
        setShowCodPop((prev) => {
            const newValue = !prev
            sessionStorage.setItem(`isCodVisible_${merchant_details?.m_id}`, JSON.stringify(newValue))
            return newValue
        })
    }

    const handleChooseCodLite = () => {
        setShowCodPlanPopUp((prev) => {
            const newValue = !prev
            sessionStorage.setItem(`isChoosePlan_${merchant_details?.m_id}`, JSON.stringify(newValue))
            return newValue
        })
        const defaultSelect = settingsList?.find((setting) => !setting.hideInMenu)
        if (defaultSelect) navigate('/shopify-app/settings' + defaultSelect?.path)
    }

    const handleProPlan = (merchantDetails) => {
        if (!config?.cod_app) {
            setShowCodPlanPopUp((prev) => {
                const newValue = !prev
                sessionStorage.setItem(`isChoosePlan_${merchantDetails?.m_id}`, JSON.stringify(newValue))
                return newValue
            })
            setShowCodPop(false)
            const defaultSelect = settingsList?.find((setting) => !setting.hideInMenu)
            if (defaultSelect) navigate('/shopify-app/settings' + defaultSelect?.path)
        } else {
            const activationUrl = merchantDetails?.redirect_urls?.cod_app_installation_url
            if (activationUrl) {
                window.open(activationUrl, '_blank')
            }
            setShowCodPlanPopUp((prev) => {
                const newValue = !prev
                sessionStorage.setItem(`isChoosePlan_${merchantDetails?.m_id}`, JSON.stringify(newValue))
                return newValue
            })
            setShowCodPop(false)
        }
    }

    useEffect(() => {
        if (route.pathname === '/shopify-app/settings') {
            const defaultSelect = settingsList.find((setting) => !setting.hideInMenu)
            navigate(
                config?.supported_checkout_configs?.cod_lite && !config?.supported_checkout_configs?.kwik_checkout
                    ? '/shopify-app/settings/shipping'
                    : '/shopify-app/settings' + defaultSelect?.path
            )
        }
    }, [route.pathname, config?.supported_checkout_configs, settingsList])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        setCodShippingRate(params?.get('cod-shipping-rate') === 'true' || false)

        const hasDismissed = localStorage.getItem('gkCheckoutTooltipDismissed')
        if (!hasDismissed) {
            setIsTooltipVisible(true)
        }
    }, [])

    const handleCloseTooltip = () => {
        setIsTooltipVisible(false)
        localStorage.setItem('gkCheckoutTooltipDismissed', 'true')
    }

    const stepStatuses = useMemo(() => {
        const statuses: Record<string, string> = {}
        let currentStatus = merchant_details?.onboarding_status?.kwik_checkout_app
        const codModuleStatus = merchant_details?.onboarding_status?.cod_module_status;
        const gkCheckoutStatus = merchant_details?.onboarding_status?.gk_checkout_status;
        const kycStatus = merchant_details?.onboarding_status?.kyc_status
        const gkPaymentsStatus = merchant_details?.onboarding_status?.gk_payments_status

        userJourneySteps.forEach((step) => {
            if (step.id === 'cod-activation') {
                if (newStepConfiguration[currentStatus]?.id >= newStepConfiguration['imp_checks_passed']?.id || codModuleStatus === 'imp_checks_passed') {
                    statuses[step.id] = 'Completed';
                } else {
                    statuses[step.id] = 'Ongoing';
                }
            } else if (step.id === 'enable-gk-checkout') {
                if (
                    newStepConfiguration[currentStatus]?.id >=
                    newStepConfiguration['gk_checkout_buttons_not_working']?.id &&
                    approvedChargesStatus === 'APPROVED' || ['gk_checkout_buttons_not_working', 'gk_checkout_buttons_working'].includes(gkCheckoutStatus)
                ) {
                    statuses[step.id] = 'Completed'
                } else if (
                    newStepConfiguration[currentStatus]?.id > newStepConfiguration['kyc_verification_failed']?.id
                ) {
                    statuses[step.id] = 'Completed'
                } else if (gkCheckoutStatus === '' && currentStatus !== 'imp_checks_passed') {
                    statuses[step.id] = 'Yet To Start'
                } else {
                    statuses[step.id] = '30 Days free Trial'
                }
            } else if (step.id === 'kyc-verification') {
                if (newStepConfiguration[currentStatus]?.id >= newStepConfiguration['kyc_completed']?.id || ['setup_completed', 'kyc_completed'].includes(kycStatus)) {
                    statuses[step.id] = 'Completed'
                } else if (currentStatus === 'kyc_verification_pending') {
                    statuses[step.id] = 'In-progress • ETA 2-3 Weeks'
                } else if (currentStatus === 'kyc_verification_failed') {
                    statuses[step.id] = 'KYC Verification Failed'
                } else if (
                    newStepConfiguration[currentStatus]?.id <
                    newStepConfiguration['gk_checkout_buttons_not_working']?.id
                ) {
                    statuses[step.id] = 'Yet To Start'
                } else {
                    statuses[step.id] = 'slider'
                }
            } else if (step.id === 'payments-checkout-config') {
                if (newStepConfiguration[currentStatus]?.id >=
                    newStepConfiguration['gk_payments_app_installed']?.id || gkPaymentsStatus === 'gk_payments_app_installed') {
                    statuses[step.id] = 'Completed';
                } else if (newStepConfiguration[currentStatus]?.id <
                    newStepConfiguration['kyc_completed']?.id && !(isKycCompleted() && newStepConfiguration[currentStatus]?.id >=
                    newStepConfiguration['gk_checkout_buttons_not_working']?.id)) {
                    statuses[step.id] = 'Yet To Start';
                } else {
                    statuses[step.id] = 'slider'
                }
            }
        })

        return statuses
    }, [currentOnboardingStatus])

    const handleRedirection = (id: string) => {
        if (id === 'cod-activation') {
            settingsEvents.fireProgressStepperEvent({ step: 'cod-activation' })
            navigate('/onboarding/cod-setup')
        } else if (id === 'enable-gk-checkout') {
            settingsEvents.fireProgressStepperEvent({ step: 'enable-gokwik-checkout' })
            navigate('/onboarding/checkout-setup')
        } else if (id === 'kyc-verification') {
            settingsEvents.fireProgressStepperEvent({ step: 'kyc-verification' })
            navigate('/onboarding/kyc')
        } else if (id === 'payments-checkout-config') {
            settingsEvents.fireProgressStepperEvent({ step: 'payments-activation' })
            navigate('/onboarding/payment-setup')
        }
    }

    const getCardStyle = (status) => {
        if (
            status === 'KYC Verification Failed' ||
            status === 'Install to avoid extra charges' ||
            status === 'slider'
        ) {
            return {
                containerStyle: {
                    border: '1px solid red',
                    borderTop: '2px solid red',
                    background: '#ffe6e6',
                    fontWeight: '600',
                },
                textStyle: { color: 'red', fontSize: '12px', fontWeight: '400' },
            }
        } else if (
            status === 'Ongoing' ||
            status === 'In-progress • ETA 2-3 Weeks' ||
            status === '30 Days free Trial'
        ) {
            return {
                containerStyle: {
                    border: '1px solid #1677FF',
                    borderTop: '2px solid blue',
                    background: '#eff6fe',
                    fontWeight: '600',
                    color: 'black',
                },
                textStyle: { color: 'rgba(0, 75, 141, 1)', fontSize: '12px', fontWeight: '400' },
            }
        } else if (status === 'Completed') {
            return {
                containerStyle: {
                    border: '0.2px solid rgba(0,0,0,0.3)',
                    background: '#fff',
                    color: 'rgba(0, 0, 0, 0.70)',
                    fontWeight: '400',
                },
                textStyle: { color: 'rgba(10, 91, 57, 1)', fontSize: '12px', fontWeight: '400' },
            }
        } else if (status === 'Yet To Start') {
            return {
                containerStyle: { border: '0.2px solid rgba(0,0,0,0.3)', background: '#fff', color: 'rgba(0,0,0,0.7)' },
                textStyle: { color: 'rgba(0,0,0,0.7)', fontSize: '12px', fontWeight: '400' },
            }
        } else {
            return {
                containerStyle: { border: '0.2px solid rgba(0,0,0,0.3)', background: '#fff' },
                textStyle: { color: 'rgba(0,0,0,0.7)', fontSize: '12px', fontWeight: '400' },
            }
        }
    }

    const showToolTipConditions = (itemId: string) => {
        return (
            itemId === 'enable-gk-checkout' &&
            stepStatuses[itemId] !== 'Completed' &&
            stepStatuses[itemId] !== 'Yet To Start' &&
            newStepConfiguration[currentOnboardingStatus]?.id <= newStepConfiguration['kyc_verification_failed']?.id &&
            newStepConfiguration[currentOnboardingStatus]?.id >= newStepConfiguration['kyc_viewed']?.id
        )
    }

    const handleNudgeButtonClick = () => {
        if (isKyc) {
            settingsEvents.fireProgressStepperEvent({ step: 'kyc-verification' })
            navigate('/onboarding/kyc')
        } else {
            navigate('/onboarding/payment-setup')
        }
    }

    return (
        <div className='flex flex-col w-full'>
            {!codShippingRate &&
                checkoutPlatform !== 'cod_lite' &&
                ![
                    'gk_additional_payment_methods_configured',
                    'gk_payments_app_installed',
                    'merchant_onboarded',
                ].includes(currentOnboardingStatus) &&
                isCountryIndia &&
                merchant_details?.onboarding_status?.kwik_checkout_app && (
                    <div
                        className='w-[full] bg-[#fff] mr-2 mb-2'
                        style={{ padding: '0.6rem 1rem', borderRadius: '0.5rem' }}
                    >
                        <div
                            style={{ boxSizing: 'border-box' }}
                            className='flex justify-between items-center flex-wrap text-[14px] w-[full] gap-2'
                        >
                            {userJourneySteps?.map((item, index) => {
                                const { containerStyle, textStyle } = getCardStyle(stepStatuses[item.id])
                                const button = (
                                    <button
                                        key={index}
                                        style={{
                                            ...containerStyle,
                                            flex: '1',
                                            boxSizing: 'border-box',
                                            padding: '12px',
                                            textAlign: 'left',
                                            borderRadius: '6px',
                                        }}
                                        className='flex items-center p-[10px] gap-1 text-[14px]'
                                        onClick={() => handleRedirection(item.id)}
                                        disabled={
                                            stepStatuses[item.id] === 'Completed' ||
                                            stepStatuses[item.id] === 'Yet To Start'
                                        }
                                    >
                                        {stepStatuses[item.id] === 'Completed' ? (
                                            <img src={blueCheck} className='w-[25px] h-[25px]' />
                                        ) : stepStatuses[item.id] === 'KYC Verification Failed' ? (
                                            <img src={incompleteStep} className='w-[25px] h-[25px]' />
                                        ) : stepStatuses[item.id] === 'Yet To Start' ? (
                                            <div
                                                className='w-[25px] h-[25px] flex items-center justify-center text-[rgba(0, 0, 0, 0.45)]'
                                                style={{ background: 'rgb(240, 240, 240)', borderRadius: '50%' }}
                                            >
                                                {index + 1}
                                            </div>
                                        ) : (
                                            <div
                                                className='w-[33px] h-[25px] flex items-center justify-center text-[#fff]'
                                                style={{ background: '#1677FF', borderRadius: '50%' }}
                                            >
                                                {index + 1}
                                            </div>
                                        )}
                                        <div className='flex flex-col gap-1 ml-[10px] w-full'>
                                            <div>{item.text}</div>
                                            {stepStatuses[item.id] === '30 Days free Trial' ? (
                                                <div className='inline-block'>
                                                    <img
                                                        className='w-4 h-4 animate-pulse opacity-0 scale-90 transition-all duration-1000 ease-in-out'
                                                        src={sparkles}
                                                    />
                                                    <span
                                                        style={{
                                                            ...textStyle,
                                                            background:
                                                                'linear-gradient(90deg, rgba(222, 238, 255, 0) 0%, #B6DEFF 100%)',
                                                            padding: '2px 4px',
                                                        }}
                                                    >
                                                        {stepStatuses[item.id]}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div style={textStyle}>
                                                    {stepStatuses[item.id] === 'slider' ? (
                                                        <>
                                                            <div className='flex justify-center gap-1'>
                                                                {slideMessages.map((_, index) => (
                                                                    <div
                                                                        key={index}
                                                                        style={{
                                                                            backgroundColor: `${
                                                                                currentSlide === index ? 'red' : ''
                                                                            }`,
                                                                            border: '0.5px solid red',
                                                                        }}
                                                                        className='h-1 w-1 rounded-full transition-all duration-300'
                                                                    />
                                                                ))}
                                                            </div>

                                                            {slideMessages[currentSlide]}
                                                        </>
                                                    ) : (
                                                        stepStatuses[item.id]
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {stepStatuses[item.id] !== 'Completed' &&
                                            stepStatuses[item.id] !== 'Yet To Start' && (
                                                <div className='redirect ml-[auto]'>
                                                    <img style={{ cursor: 'pointer' }} src={redirect} />
                                                </div>
                                            )}
                                    </button>
                                )

                                return showToolTipConditions(item.id) ? (
                                    <Tooltip
                                        key={index}
                                        title={
                                            <div className='flex items-center text-[12px] text-bold'>
                                                <img className='mr-2' src={openLock} />
                                                <span>Step unlocked, now you can enable the GoKwik Checkout</span>
                                                <div onClick={handleCloseTooltip} className='ml-2'>
                                                    <img src={tooltipCancel} />
                                                </div>
                                            </div>
                                        }
                                        open={isTooltipVisible}
                                        placement='bottom'
                                        overlayInnerStyle={{
                                            backgroundColor: 'black',
                                            color: 'white',
                                            width: 400,
                                            borderRadius: '0.5rem',
                                            padding: '0.5rem',
                                        }}
                                        overlayStyle={{
                                            zIndex: 1,
                                        }}
                                    >
                                        {button}
                                    </Tooltip>
                                ) : (
                                    button
                                )
                            })}
                        </div>
                        <KwikCheckoutPrompt isInstallModalVisible={isInstallModalVisible} setIsInstallModalVisible={setIsInstallModalVisible}/>
                    </div>
                )}
            <div className='w-full bg-white'>
                <Layout className='h-full'>
                    {(newStepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >=
                        newStepConfiguration['imp_checks_passed']?.id ||
                        !merchant_details?.onboarding_status?.kwik_checkout_app ||
                        checkoutPlatform === 'cod_lite') && (
                        <Sider
                            className='bg-white h-full mr-4 border-0 border-gray-100 border-r-2 flex flex-col'
                            style={{ background: 'white' }}
                        >
                            {checkoutPlatformKeys.length > 1 && (
                                <Select
                                    className='w-full mb-4'
                                    value={checkoutPlatform}
                                    onChange={(value) => {
                                        localStorage.setItem('checkoutPlatform', value)
                                        setCheckoutPlatform(value)
                                        if (value === 'checkout_1') navigate(`/checkout/settings`)
                                        if (value === 'cod_lite') navigate(`/shopify-app/settings/shipping`)
                                    }}
                                    options={checkoutPlatformKeys.map((item: string) => ({
                                        label: titleCase(item.split('_').join(' ')),
                                        value: item,
                                    }))}
                                />
                            )}
                            <div
                                className={`setting-menu-custom-css flex flex-col h-[95vh] ${
                                    checkoutPlatformKeys.length === 1 && checkoutPlatformKeys.includes('cod_lite')
                                        ? 'mt-2'
                                        : ''
                                }`}
                            >
                                <div
                                    className='flex-grow overflow-auto'
                                    style={{
                                        height: '400px',
                                        overflowY: 'auto',
                                        maxHeight: '500px',
                                    }}
                                >
                                    <Menu
                                        onClick={handleMenuSelection}
                                        defaultOpenKeys={[openItems.openedKey]}
                                        defaultSelectedKeys={[openItems.selectedKey]}
                                        key={`${route.pathname}-${openItems.openedKey}-${openItems.selectedKey}`}
                                        items={menuItems()}
                                        inlineCollapsed={true}
                                        mode='inline'
                                    />
                                </div>

                                <div style={{ position: 'fixed', bottom: 0, background: 'white', paddingTop: '10px' }}>
                                    <ReviewComponent events={eventsClass.reviewFlowEvents} />
                                </div>
                            </div>
                        </Sider>
                    )}
                    <Layout className='overflow-auto'>
                        <Routes>
                            {settingsList.map((item, index) => (
                                <Route key={index} path={item.path} element={<item.component />} />
                            ))}
                        </Routes>
                    </Layout>
                </Layout>
                {(merchant_details?.onboarding_status?.cod_lite_app === 'new_merchant_app_installed' ||
                    merchant_details?.onboarding_status?.cod_lite_app === 'existing_merchant_app_installed') &&
                    showCodPop &&
                    codLitebillingStatus === 'PENDING' && (
                        <CodPopUp
                            onYesClick={handleCodPlanPopUp}
                            onNoClick={() => redirectForCodLiteActivation(merchant_details)}
                        />
                    )}
                {showCodPlanPopUp &&
                    merchant_details?.onboarding_status?.cod_lite_app === 'existing_merchant_app_installed' && (
                        <ChoosePlan
                            selectCodLite={() => handleChooseCodLite()}
                            selectCod={() => handleProPlan(merchant_details)}
                            isCodUser={config?.cod_app}
                        />
                    )}
            </div>
            {!showNudge &&
                (showKycNudgeStatusList.includes(currentOnboardingStatus) ||
                    showPaymentNudgeStatusList.includes(currentOnboardingStatus)) && (
                    <div className='kyc-paymentapp-nudge fixed bg-[#004B8D] text-[#fff] w-[200px] text-[12px] flex items-center justify-between p-2 rounded-md shadow-lg'>
                        <p className='text-[12px] leading-[20px] font-inter font-semibold'>
                            {remainingTrialDays === 0
                                ? isTrialExpired
                                    ? 'Your trial period is expired'
                                    : `Free trial will expire today`
                                : `${remainingTrialDays} days left in your trial`}
                            <br />
                            {showKycNudgeStatusList.includes(currentOnboardingStatus)
                                ? '(Complete Your KYC)'
                                : '(Install Payments app)'}
                        </p>
                        <button
                            className='z-50 w-[30px] h-[30px] bg-[#3B6BA5] flex items-center justify-center rounded-md 
               border-none outline-none focus:outline-none focus:border-none focus:ring-0 
               active:outline-none active:ring-0 cursor-pointer pointer-events-auto'
                            onClick={() => setShowNudge(true)}
                        >
                            <UpOutlined className='text-white' />
                        </button>
                    </div>
                )}
            {showNudge && (
                <NudgeCard
                    isExpandWidget={true}
                    startToComplete={() => handleNudgeButtonClick()}
                    onNudgeClose={() => setShowNudge(false)}
                    isKyc={isKyc}
                />
            )}
        </div>
    )
}
