import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getMode, getUserDetails } from '@store/user/selectors'
import CheckoutAdmin from '@pages/admin'
import Settings from '@pages/settings'
import ShopifyAppAnalytics from '@pages/analytics'
import { PermissionValues } from '@library/utilities/constants/constants'
import { validatePermission } from '@gokwik/utilities'
import { NotFound } from '@pages/notFound'

const AppRoutes = () => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const mode = useSelector(getMode)
    if (!Object.keys(userDetails || {}).length) return <></>
    return (
        <Routes key={`${mode}-${JSON.stringify(merchantDetails)}`}>
            <Route
                path='/shopify-app/analytics'
                element={
                    validatePermission(PermissionValues.checkoutAnalytics.view) ? (
                        <ShopifyAppAnalytics />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/shopify-app/settings/*'
                element={
                    validatePermission(PermissionValues.settings.view) ? (
                        <Settings />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/shopify-app/admin/*'
                element={
                    validatePermission(PermissionValues.checkoutAdmin.view) ? (
                        <CheckoutAdmin />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route path='/shopify-app/*' element={<NotFound />} />
        </Routes>
    )
}

export default AppRoutes
