import { updateBreadcrumbs } from '@gokwik/utilities'
import {
    Button,
    EnvironmentOutlined,
    LoginOutlined,
    Tabs,
    Tooltip,
    InfoCircleOutlined,
    LockOutlined,
   
} from 'gokwik-ui-kit'
import { useNavigate } from 'react-router-dom'

import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import LoginPageCustomisation from './loginPage'
import AddressPageCustomisation from './addressPage'
import PaymentPageCustomisation from './paymentPage'
import { defaultShopifyNewCheckoutConfig } from './constants'
import {
    IShopifyNewCheckoutUIConfig,
    IShopifyNewPaymentUIConfig,
    IShopifyPaymentUIConfig,
} from '@library/utilities/interface'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { kycAndPaymentStatus } from '@library/utilities/helpers/helper'
import AddressPagePreview from './addressPagePreview'
import LoginPagePreview from './loginPagePreview'
import { useState, useEffect } from 'react'

import {
    defaultPaymentMethods,
    defaultShopifyNewPaymentUIConfig,
    defaultShopifyPaymentUIConfig,
   
} from './constants'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'
import PaymentMethodsList from './paymentPageList'
import { NoDataIcon } from '@library/images/noDataIcon'
import BannerFunction from './banner'
import SaveBar from '@library/components/save-bar'
import PaymentPagePreview from './paymentPagePreview'

const CheckoutUiCustomisation = (props: { events: ICommonEvents; paymentUICustomisationEvents: ICommonEvents }) => {
    const navigate = useNavigate()
    const { configsUpsert } = props.events
    const { config, saveConfig } = useShopifyMerchantConfigApi({
        useCache: true,
        fireFailureEvent: configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: configsUpsert.edit.fireSuccessEvent,
    })
    const [showCopyConfirm, setShowCopyConfirm] = useState(false)
    const [activeTab, setActiveTab] = useState('login')
    const [updatedCheckoutUIConfig, setUpdatedCheckoutUIConfig] = useState<IShopifyNewCheckoutUIConfig>(
        defaultShopifyNewCheckoutConfig,
    )
    const [updatedPaymentUIConfig, setupdatedPaymentUIConfig] = useState<
        IShopifyPaymentUIConfig | IShopifyNewPaymentUIConfig
    >()

    const { config: configForPaymentPage, saveConfig: saveConfigForPaymentPage } = useShopifyMerchantConfigApi({
        useCache: true,
        fireFailureEvent: props.paymentUICustomisationEvents.configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: props.paymentUICustomisationEvents.configsUpsert.edit.fireSuccessEvent,
    })

    const merchantDetails = useSelector(getMerchantDetails)
    const isPaymentSetupComplete = kycAndPaymentStatus(merchantDetails, 'isPaymentSetupComplete')

    const [isUpdated, setIsUpdated] = useState<boolean>(false)
    const [listItems, setListItems] = useState([])
    const [inputErrors, setInputErrors] = useState({})
    function setStates(firstRender = false) {
        if (firstRender) {
            let configToBeUpdated = {}
            if (!config?.paymentMethods?.length) {
                configToBeUpdated['paymentMethods'] = defaultPaymentMethods.map((paymentMethod) => {
                    return {
                        paymentMethodId: paymentMethod,
                    }
                })
            }

            if (config?.newPaymentsUIEnabled) {
                if (config?.newPaymentsUIConfig?.methodsCustomization) {
                    for (const [paymentMethodName, paymentMethodObj] of Object.entries(
                        defaultShopifyNewPaymentUIConfig.methodsCustomization,
                    )) {
                        if (
                            !Object.keys(config?.newPaymentsUIConfig?.methodsCustomization).includes(paymentMethodName)
                        ) {
                            if (
                                !configToBeUpdated['newPaymentsUIConfig'] ||
                                !configToBeUpdated['newPaymentsUIConfig']['methodsCustomization']
                            ) {
                                configToBeUpdated['newPaymentsUIConfig'] = JSON.parse(
                                    JSON.stringify(config?.newPaymentsUIConfig || defaultShopifyNewPaymentUIConfig),
                                )
                            }
                            configToBeUpdated['newPaymentsUIConfig']['methodsCustomization'][paymentMethodName] =
                                paymentMethodObj
                        } else if (
                            !config?.newPaymentsUIConfig?.methodsCustomization?.[paymentMethodName]?.buttonText
                        ) {
                            if (
                                !configToBeUpdated['newPaymentsUIConfig'] ||
                                !configToBeUpdated['newPaymentsUIConfig']['methodsCustomization']
                            ) {
                                configToBeUpdated['newPaymentsUIConfig'] = JSON.parse(
                                    JSON.stringify(config?.newPaymentsUIConfig || defaultShopifyNewPaymentUIConfig),
                                )
                            }
                            configToBeUpdated['newPaymentsUIConfig']['methodsCustomization'][
                                paymentMethodName
                            ].buttonText =
                                defaultShopifyNewPaymentUIConfig.methodsCustomization[paymentMethodName].buttonText
                        }
                    }
                } else {
                    configToBeUpdated['newPaymentsUIConfig'] = defaultShopifyNewPaymentUIConfig
                }
            } else {
                if (config?.paymentUiConfig?.payment_page_text) {
                    for (const [paymentMethodName, paymentMethodObj] of Object.entries(
                        defaultShopifyPaymentUIConfig.payment_page_text,
                    )) {
                        if (!Object.keys(config?.paymentUiConfig.payment_page_text).includes(paymentMethodName)) {
                            if (
                                !configToBeUpdated['paymentUiConfig'] ||
                                !configToBeUpdated['paymentUiConfig']['payment_page_text']
                            ) {
                                configToBeUpdated['paymentUiConfig'] = JSON.parse(
                                    JSON.stringify(config?.paymentUiConfig || defaultShopifyPaymentUIConfig),
                                )
                            }
                            configToBeUpdated['paymentUiConfig']['payment_page_text'][paymentMethodName] =
                                paymentMethodObj
                        } else if (!config?.paymentUiConfig.payment_page_text?.[paymentMethodName]?.btn_text) {
                            if (
                                !configToBeUpdated['paymentUiConfig'] ||
                                !configToBeUpdated['paymentUiConfig']['payment_page_text']
                            ) {
                                configToBeUpdated['paymentUiConfig'] = JSON.parse(
                                    JSON.stringify(config?.paymentUiConfig || defaultShopifyPaymentUIConfig),
                                )
                            }
                            configToBeUpdated['paymentUiConfig']['payment_page_text'][paymentMethodName].btn_text =
                                defaultShopifyPaymentUIConfig.payment_page_text[paymentMethodName].btn_text
                        }
                    }
                } else {
                    configToBeUpdated['paymentUiConfig'] = defaultShopifyPaymentUIConfig
                }
            }

            if (Object.keys(configToBeUpdated).length) {
                saveConfig(configToBeUpdated, () => {
                    setIsUpdated(false)
                })
            }
        }

        if (config?.newPaymentsUIEnabled) {
            if (config?.newPaymentsUIConfig) {
                setupdatedPaymentUIConfig(config?.newPaymentsUIConfig)
            } else {
                setupdatedPaymentUIConfig(defaultShopifyNewPaymentUIConfig)
            }
        } else if (config?.paymentUiConfig) {
            setupdatedPaymentUIConfig(config?.paymentUiConfig)
        } else {
            setupdatedPaymentUIConfig(defaultShopifyPaymentUIConfig)
        }

        if (config?.paymentMethods?.length) {
            const paymentMethods = commonPaymentMethods(config?.paymentMethods)
            setListItems(paymentMethods)
        }
    }

    useEffect(() => {
        setStates(true)
    }, [])

    useEffect(() => {
        setStates()
    }, [config?.paymentUiConfig, config?.newPaymentsUIConfig, config?.paymentMethods, config?.newPaymentsUIEnabled])

    function commonPaymentMethods(paymentMethodsFromConfig: any[]) {
        let paymentMethods = defaultPaymentMethods.map((paymentMethod) => {
            return {
                paymentMethodId: paymentMethod,
            }
        })

        if (paymentMethodsFromConfig?.length) {
            paymentMethods = paymentMethodsFromConfig
        }

        const newPaymentMethods = paymentMethods.filter((paymentMethod) => {
            return !['cod', 'ppcod-upi'].includes(paymentMethod.paymentMethodId)
        })

        const result: string[] = []

        for (const item of newPaymentMethods) {
            if (item.paymentMethodId.includes('wallet')) {
                if (!result.includes('wallet')) {
                    result.push('wallet')
                }
            } else if (item.paymentMethodId === 'cc' || item.paymentMethodId === 'dc') {
                if (!result.includes('card')) {
                    result.push('card')
                }
            } else if (item.paymentMethodId.includes('emi')) {
                if (!result.includes('emi')) {
                    result.push('emi')
                }
            } else if (item.paymentMethodId.includes('bnpl')) {
                if (!result.includes('bnpl')) {
                    result.push(!config?.newPaymentsUIEnabled ? 'bnpl' : 'paylater')
                }
            } else {
                if (!result.includes(item.paymentMethodId)) {
                    result.push(item.paymentMethodId)
                }
            }
        }

        return result.map((paymentMethod) => {
            return {
                paymentMethodId: paymentMethod,
            }
        })
    }


    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/shopify-app/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'ui-customisation',
                href: '/shopify-app/settings/ui-customisation',
                text: 'UI Customisation',
            },
            {
                key: 'checkout-ui',
                href: '/shopify-app/settings/ui-customisation/checkout-ui',
                text: 'Gokwik Checkout UI',
            },
        ])
    }, [])

    const handleCopyBanners = () => {
        const prevCheckoutBanners = config.newCheckoutUIConfig?.textConfig?.otherSections?.brandBanner || []
        const checkoutBanners = updatedCheckoutUIConfig?.textConfig?.otherSections?.brandBanner || []
        const existingBanners =
            ((updatedPaymentUIConfig as IShopifyNewPaymentUIConfig)?.textConfig?.bannerText?.length
                ? (updatedPaymentUIConfig as IShopifyNewPaymentUIConfig)?.textConfig?.bannerText
                : configForPaymentPage?.newPaymentsUIConfig?.textConfig?.bannerText) || []

        const newlyAddedBanners = checkoutBanners.filter((banner) => !prevCheckoutBanners.includes(banner))
        const mergedBanners = [...existingBanners]

        newlyAddedBanners?.forEach((newBanner) => {
            const emptyIndex = mergedBanners.findIndex((banner) => !banner)
            if (emptyIndex !== -1) {
                mergedBanners[emptyIndex] = newBanner
            }
        })
        const trimmedBanners = mergedBanners?.slice(0, 3)
        const trimmedConfig = {
            ...configForPaymentPage?.newPaymentsUIConfig,
            textConfig: {
                ...configForPaymentPage?.newPaymentsUIConfig?.textConfig,
                bannerText: trimmedBanners,
            },
        }

        saveConfig({
            newCheckoutUIConfig: updatedCheckoutUIConfig,
            newPaymentsUIConfig: trimmedConfig,
        })
        setupdatedPaymentUIConfig(trimmedConfig)
        setShowCopyConfirm(false)
    }

    const handleNotToCopy = () => {
        setActiveTab('payment')
        saveConfig({
            newCheckoutUIConfig: updatedCheckoutUIConfig,
        })
        setShowCopyConfirm(false)
    }

    const items = [
        {
            label: 'Login',
            key: 'login',
            icon: <LoginOutlined />,
            children: (
                <LoginPageCustomisation
                    updatedCheckoutUIConfig={updatedCheckoutUIConfig}
                    setUpdatedCheckoutUIConfig={setUpdatedCheckoutUIConfig}
                    setShowCopyConfirm={setShowCopyConfirm}
                    config={config}
                    saveConfig={saveConfig}
                    fireDiscardSaveEvent={configsUpsert.discardSave}
                />
            ),
        },
        {
            label: 'Address',
            key: 'address',
            icon: <EnvironmentOutlined />,
            children: (
                <AddressPageCustomisation
                    config={config}
                    saveConfig={saveConfig}
                    fireDiscardSaveEvent={configsUpsert.discardSave}
                />
            ),
        },
        {
            label: (
                <span>
                    Payment
                    {!isPaymentSetupComplete && (
                        <Tooltip title='Please complete the payment setup / contact Gokwik Support team to unlock'>
                            <LockOutlined className='ml-2' />
                        </Tooltip>
                    )}
                </span>
            ),
            key: 'payment',
            icon: <EnvironmentOutlined />,
            disabled: !isPaymentSetupComplete,
            children: (
                <PaymentPageCustomisation
                    config={configForPaymentPage}
                    saveConfig={saveConfigForPaymentPage}
                    events={props.paymentUICustomisationEvents}
                    updatedPaymentUIConfig={updatedPaymentUIConfig}
                    setupdatedPaymentUIConfig={setupdatedPaymentUIConfig}
                />
            ),
        },
    ]

    return (
        <div className='rounded-sm'>
            <div className='flex border border-gray-500'>
                <div className='w-4/6 p-4'>
                    <div className='mb-4'>
                        <h2 className='text-lg font-semibold'>Page</h2>
                        <p className='text-gray-400'>
                            Configure UI/UX for payment, address, and login pages to enhance the checkout experience
                        </p>
                    </div>
                    {showCopyConfirm && (
                        <div className='absolute z-50 globalSave p-4 rounded-lg'>
                            <div className='flex items-center text-white'>
                                <Tooltip>
                                    <InfoCircleOutlined className='ml-2 mr-2' />
                                </Tooltip>
                                <p className='text-white text-base font-normal'>
                                    Do you want to copy the same banner text to the payments page?
                                </p>
                            </div>
                            <div className='flex gap-3'>
                                <Button type='default' onClick={() => handleNotToCopy()}>
                                    No
                                </Button>
                                <Button
                                    type='primary'
                                    onClick={() => handleCopyBanners()}
                                    className='bg-green-500 text-white'
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className='flex justify-between w-full'>
                        <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)} items={items} />
                    </div>
                </div>
                <div style={{ borderLeft: '1px solid #D3D3D3' }}></div>
                <div className='w-2/6 p-4 border-l border-gray-500'>
                    {activeTab === 'login' && (
                        <LoginPagePreview newCheckoutUIConfig={updatedCheckoutUIConfig} config={config} />
                    )}
                    {activeTab === 'address' && (
                        <AddressPagePreview newCheckoutUIConfig={updatedCheckoutUIConfig} config={config} />
                    )}
                    {activeTab === 'payment' && (
                        <PaymentPagePreview
                            merchantLogo={config?.logo}
                            newPaymentUIEnabled={config?.newPaymentsUIEnabled}
                            paymentUIConfig={updatedPaymentUIConfig}
                            paymentMethods={listItems}
                            colorConfig={config?.newPaymentsUIEnabled ? config?.newPaymentsUIConfig?.colorConfig : {}}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default CheckoutUiCustomisation
