import { useState, useEffect } from 'react'
import { Col, Row, Button, Input, QuestionCircleOutlined, Tooltip } from 'gokwik-ui-kit'
import {
    IShopifyMerchantConfig,
    IShopifyNewPaymentUIConfig,
    IShopifyPaymentUIConfig,
} from '@library/utilities/interface'
import {
    defaultPaymentMethods,
    defaultShopifyNewPaymentUIConfig,
    defaultShopifyPaymentUIConfig,
    paymentPageTooltips,
} from './constants'
import PaymentPagePreview from './paymentPagePreview'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'
import PaymentMethodsList from './paymentPageList'
import { NoDataIcon } from '@library/images/noDataIcon'
import BannerFunction from './banner'
import SaveBar from '@library/components/save-bar'

export const PaymentPageCustomisation = ({
    events,
    config,
    saveConfig,
    updatedPaymentUIConfig,
    setupdatedPaymentUIConfig
}: {
    config: IShopifyMerchantConfig
    saveConfig: Function
    events: ICommonEvents
    updatedPaymentUIConfig: IShopifyPaymentUIConfig | IShopifyNewPaymentUIConfig
    setupdatedPaymentUIConfig: Function
}) => {
    const [isUpdated, setIsUpdated] = useState<boolean>(false)
    const [listItems, setListItems] = useState([])
    const [inputErrors, setInputErrors] = useState({})


    function setStates(firstRender = false) {
        if (firstRender) {
            let configToBeUpdated = {}
            if (!config?.paymentMethods?.length) {
                configToBeUpdated['paymentMethods'] = defaultPaymentMethods.map((paymentMethod) => {
                    return {
                        paymentMethodId: paymentMethod,
                    }
                })
            }

            if (config?.newPaymentsUIEnabled) {
                if (config?.newPaymentsUIConfig?.methodsCustomization) {
                    for (const [paymentMethodName, paymentMethodObj] of Object.entries(
                        defaultShopifyNewPaymentUIConfig.methodsCustomization,
                    )) {
                        if (
                            !Object.keys(config?.newPaymentsUIConfig?.methodsCustomization).includes(paymentMethodName)
                        ) {
                            if (
                                !configToBeUpdated['newPaymentsUIConfig'] ||
                                !configToBeUpdated['newPaymentsUIConfig']['methodsCustomization']
                            ) {
                                configToBeUpdated['newPaymentsUIConfig'] = JSON.parse(
                                    JSON.stringify(config?.newPaymentsUIConfig || defaultShopifyNewPaymentUIConfig),
                                )
                            }
                            configToBeUpdated['newPaymentsUIConfig']['methodsCustomization'][paymentMethodName] =
                                paymentMethodObj
                        } else if (
                            !config?.newPaymentsUIConfig?.methodsCustomization?.[paymentMethodName]?.buttonText
                        ) {
                            if (
                                !configToBeUpdated['newPaymentsUIConfig'] ||
                                !configToBeUpdated['newPaymentsUIConfig']['methodsCustomization']
                            ) {
                                configToBeUpdated['newPaymentsUIConfig'] = JSON.parse(
                                    JSON.stringify(config?.newPaymentsUIConfig || defaultShopifyNewPaymentUIConfig),
                                )
                            }
                            configToBeUpdated['newPaymentsUIConfig']['methodsCustomization'][
                                paymentMethodName
                            ].buttonText =
                                defaultShopifyNewPaymentUIConfig.methodsCustomization[paymentMethodName].buttonText
                        }
                    }
                } else {
                    configToBeUpdated['newPaymentsUIConfig'] = defaultShopifyNewPaymentUIConfig
                }
            } else {
                if (config?.paymentUiConfig?.payment_page_text) {
                    for (const [paymentMethodName, paymentMethodObj] of Object.entries(
                        defaultShopifyPaymentUIConfig.payment_page_text,
                    )) {
                        if (!Object.keys(config?.paymentUiConfig.payment_page_text).includes(paymentMethodName)) {
                            if (
                                !configToBeUpdated['paymentUiConfig'] ||
                                !configToBeUpdated['paymentUiConfig']['payment_page_text']
                            ) {
                                configToBeUpdated['paymentUiConfig'] = JSON.parse(
                                    JSON.stringify(config?.paymentUiConfig || defaultShopifyPaymentUIConfig),
                                )
                            }
                            configToBeUpdated['paymentUiConfig']['payment_page_text'][paymentMethodName] =
                                paymentMethodObj
                        } else if (!config?.paymentUiConfig.payment_page_text?.[paymentMethodName]?.btn_text) {
                            if (
                                !configToBeUpdated['paymentUiConfig'] ||
                                !configToBeUpdated['paymentUiConfig']['payment_page_text']
                            ) {
                                configToBeUpdated['paymentUiConfig'] = JSON.parse(
                                    JSON.stringify(config?.paymentUiConfig || defaultShopifyPaymentUIConfig),
                                )
                            }
                            configToBeUpdated['paymentUiConfig']['payment_page_text'][paymentMethodName].btn_text =
                                defaultShopifyPaymentUIConfig.payment_page_text[paymentMethodName].btn_text
                        }
                    }
                } else {
                    configToBeUpdated['paymentUiConfig'] = defaultShopifyPaymentUIConfig
                }
            }

            if (Object.keys(configToBeUpdated).length) {
                saveConfig(configToBeUpdated, () => {
                    setIsUpdated(false)
                })
            }
        }

        if (config?.newPaymentsUIEnabled) {
            if (config?.newPaymentsUIConfig) {
                setupdatedPaymentUIConfig(config?.newPaymentsUIConfig)
            } else {
                setupdatedPaymentUIConfig(defaultShopifyNewPaymentUIConfig)
            }
        } else if (config?.paymentUiConfig) {
            setupdatedPaymentUIConfig(config?.paymentUiConfig)
        } else {
            setupdatedPaymentUIConfig(defaultShopifyPaymentUIConfig)
        }

        if (config?.paymentMethods?.length) {
            const paymentMethods = commonPaymentMethods(config?.paymentMethods)
            setListItems(paymentMethods)
        }
    }

    useEffect(() => {
        setStates(true)
    }, [])

    useEffect(() => {
        setStates()
    }, [config?.paymentUiConfig, config?.newPaymentsUIConfig, config?.paymentMethods, config?.newPaymentsUIEnabled])

    function commonPaymentMethods(paymentMethodsFromConfig: any[]) {
        let paymentMethods = defaultPaymentMethods.map((paymentMethod) => {
            return {
                paymentMethodId: paymentMethod,
            }
        })

        if (paymentMethodsFromConfig?.length) {
            paymentMethods = paymentMethodsFromConfig
        }

        const newPaymentMethods = paymentMethods.filter((paymentMethod) => {
            return !['cod', 'ppcod-upi'].includes(paymentMethod.paymentMethodId)
        })

        const result: string[] = []

        for (const item of newPaymentMethods) {
            if (item.paymentMethodId.includes('wallet')) {
                if (!result.includes('wallet')) {
                    result.push('wallet')
                }
            } else if (item.paymentMethodId === 'cc' || item.paymentMethodId === 'dc') {
                if (!result.includes('card')) {
                    result.push('card')
                }
            } else if (item.paymentMethodId.includes('emi')) {
                if (!result.includes('emi')) {
                    result.push('emi')
                }
            } else if (item.paymentMethodId.includes('bnpl')) {
                if (!result.includes('bnpl')) {
                    result.push(!config?.newPaymentsUIEnabled ? 'bnpl' : 'paylater')
                }
            } else {
                if (!result.includes(item.paymentMethodId)) {
                    result.push(item.paymentMethodId)
                }
            }
        }

        return result.map((paymentMethod) => {
            return {
                paymentMethodId: paymentMethod,
            }
        })
    }

    function reParsePaymentMethodsFromList(
        paymentMethodsFromConfig: { paymentMethodId: string }[],
        reOrderedPaymentMethods: { paymentMethodId: string }[],
    ) {
        const expandedArray: { paymentMethodId: string }[] = []

        const walletMethods = paymentMethodsFromConfig
            .filter((item) => item.paymentMethodId.startsWith('wallet'))
            .map((item) => item.paymentMethodId)

        const emiMethods = paymentMethodsFromConfig
            .filter((item) => item.paymentMethodId.startsWith('emi'))
            .map((item) => item.paymentMethodId)

        const bnplMethods = paymentMethodsFromConfig
            .filter((item) => item.paymentMethodId.startsWith('bnpl'))
            .map((item) => item.paymentMethodId)

        const cardMethods = ['cc', 'dc']

        function insertPaymentMethod(expandedMethod: string) {
            const matchingMethod = paymentMethodsFromConfig.find((item) => item.paymentMethodId === expandedMethod)
            if (matchingMethod) {
                expandedArray.push(matchingMethod)
            }
        }

        for (const method of reOrderedPaymentMethods) {
            const methodId = method.paymentMethodId

            if (methodId === 'wallet') {
                walletMethods.forEach((expandedMethod) => {
                    insertPaymentMethod(expandedMethod)
                })
            } else if (methodId === 'card') {
                cardMethods.forEach((expandedMethod) => {
                    insertPaymentMethod(expandedMethod)
                })
            } else if (methodId === 'emi') {
                emiMethods.forEach((expandedMethod) => {
                    insertPaymentMethod(expandedMethod)
                })
            } else if (methodId === 'bnpl' || methodId === 'paylater') {
                bnplMethods.forEach((expandedMethod) => {
                    insertPaymentMethod(expandedMethod)
                })
            } else {
                insertPaymentMethod(methodId)
            }
        }

        return expandedArray
    }

    function onSave() {
        const paymentMethods = reParsePaymentMethodsFromList(config?.paymentMethods, listItems)

        let dataToBeSaved: any = {
            paymentUiConfig: {
                ...config?.paymentUiConfig,
                ...updatedPaymentUIConfig,
            },
            paymentMethods,
        }

        if (config.newPaymentsUIEnabled) {
            dataToBeSaved = {
                newPaymentsUIConfig: {
                    ...config?.newPaymentsUIConfig,
                    ...updatedPaymentUIConfig,
                },
                paymentMethods,
            }
        }

        saveConfig(dataToBeSaved, () => {
            setIsUpdated(false)
        })
    }

    function onDiscard() {
        setStates()
        setIsUpdated(false)
        events.configsUpsert.discardSave()
    }

    return (
        <div className="w-full">
            <Row gutter={16}>
                <Col span={24}> 
                    {config?.newPaymentsUIEnabled && (
                        <BannerFunction
                            updatedConfig={updatedPaymentUIConfig}
                            setUpdatedConfig={setupdatedPaymentUIConfig}
                            setIsUpdated={setIsUpdated}
                            setIsBannerUpdated={() => {}}
                            type="paymentUI"
                        />
                    )}
    
                    {listItems?.length > 0 ? (
                        <PaymentMethodsList
                            listItems={listItems}
                            setListItems={setListItems}
                            config={config}
                            isUpdated={isUpdated}
                            setIsUpdated={setIsUpdated}
                            setupdatedPaymentUIConfig={setupdatedPaymentUIConfig}
                            inputErrors={inputErrors}
                            setInputErrors={setInputErrors}
                        />
                    ) : (
                        <Row>
                            <div className="p-20 text-center flex flex-col items-center justify-center">
                                <NoDataIcon />
                                <h3>Prepaid payments Methods not active.</h3>
                            </div>
                        </Row>
                    )}
    
                    {isUpdated && !Object.values(inputErrors).filter((value) => value).length && (
                        <SaveBar handleDiscard={onDiscard} handleSave={onSave} />
                    )}
                </Col>
            </Row>
        </div>
    );
}    
export default PaymentPageCustomisation
