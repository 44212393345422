import {
    Table,
    Switch,
    ColumnProps,
    Popconfirm,
    Dropdown,
    Menu,
    MoreOutlined,
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
} from 'gokwik-ui-kit';

const RulesTable = ({ title, rules, type, onView, onEdit, onDelete, onStatusChange }) => {
    const columns: ColumnProps<any>[] = [
        {
            title: "Name",
            dataIndex: 'name',
            key: 'name',
            width: '30%',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '45%',
        },
        {
            title: 'Status',
            key: 'enabled',
            fixed: 'right',
            render: (_, record) => <Switch checked={record.enabled} onChange={() => onStatusChange(record)} />,
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            render: (_, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="view" onClick={() => onView(record)}>
                            <EyeOutlined /> View
                        </Menu.Item>
                        <Menu.Item key="edit" onClick={() => onEdit(record)}>
                            <EditOutlined /> Edit
                        </Menu.Item>
                        <Menu.Item key="delete">
                            <Popconfirm
                                title="Are you sure you want to delete this rule?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => onDelete(record)}
                            >
                                <DeleteOutlined className="text-error-500" /> Remove
                            </Popconfirm>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                        <MoreOutlined className="text-lg cursor-pointer" />
                    </Dropdown>
                );
            },
        },
    ]
    return (
        <Table
            scroll={{
                x: 'max-content',
            }}
            dataSource={rules}
            columns={columns}
            pagination={false}
        />
    )
}
export default RulesTable
