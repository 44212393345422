import {
    Table,
    ColumnProps,
    Popconfirm,
    Tooltip,
    Divider,
    Dropdown,
    Menu,
    MoreOutlined,
    EditOutlined,
    DeleteOutlined,
    Select,
} from 'gokwik-ui-kit'
import filter from '@library/images/Content.svg'
import { useEffect, useState } from 'react'
import { DiscountTypeMapping, PaymentMethodsLabelNameMapping } from './constants'

const PrepaidDiscountRulesTable = ({ discounts, enabledPaymentMethods, onEdit, onDelete }) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('all')
    const [filteredDiscounts, setFilteredDiscounts] = useState(discounts)

    useEffect(() => {
        if (selectedPaymentMethod) {
            if (selectedPaymentMethod === 'all') {
                setFilteredDiscounts(
                    discounts.map((discount) => {
                        return {
                            ...discount,
                            methodType: unParsePaymentMethod(discount.methodType),
                            type: DiscountTypeMapping[discount.type],
                            upperLimit: discount.upperLimit == Number.MAX_SAFE_INTEGER ? null : discount.upperLimit,
                            cappingLimit:
                                discount.cappingLimit == Number.MAX_SAFE_INTEGER ? null : discount.cappingLimit,
                        }
                    }),
                )
            } else {
                setFilteredDiscounts(
                    discounts
                        .filter((discount) => {
                            return discount.methodType == selectedPaymentMethod
                        })
                        .map((discount) => {
                            return {
                                ...discount,
                                methodType: unParsePaymentMethod(discount.methodType),
                                type: DiscountTypeMapping[discount.type],
                                upperLimit: discount.upperLimit == Number.MAX_SAFE_INTEGER ? null : discount.upperLimit,
                                cappingLimit:
                                    discount.cappingLimit == Number.MAX_SAFE_INTEGER ? null : discount.cappingLimit,
                            }
                        }),
                )
            }
        }
    }, [selectedPaymentMethod, discounts])

    function parseDiscountType(input) {
        for (const [key, value] of Object.entries(DiscountTypeMapping)) {
            if (input === value) {
                return key
            }
        }
        return input
    }

    function parsePaymentMethod(input) {
        if (input.includes('Wallet')) {
            return input.split(' ').reverse().join('-').toLowerCase()
        }

        for (const [key, value] of Object.entries(PaymentMethodsLabelNameMapping)) {
            if (input === value) {
                return key
            }
        }

        return input
    }

    function unParsePaymentMethod(input) {
        if (input.includes('wallet')) {
            return input
                .split('-')
                .reverse()
                .map((string) => {
                    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
                })
                .join(' ')
        } else {
            return PaymentMethodsLabelNameMapping[input] || input
        }
    }

    const columns: ColumnProps<any>[] = [
        {
            title: `Type`,
            dataIndex: 'type',
            key: 'type',
            width: '12%',
        },
        {
            title: `Value`,
            dataIndex: 'value',
            key: 'value',
            width: '12%',
        },
        {
            title: `Lower Limit`,
            dataIndex: 'lowerLimit',
            key: 'lowerLimit',
            width: '12%',
        },
        {
            title: `Upper Limit`,
            dataIndex: 'upperLimit',
            key: 'upperLimit',
            width: '12%',
        },
        {
            title: `Capping Limit`,
            dataIndex: 'cappingLimit',
            key: 'cappingLimit',
            width: '12%',
        },
        {
            title: `Method Type`,
            dataIndex: 'methodType',
            key: 'methodType',
            width: '12%',
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            width: '12%',
            render: (_, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item
                            key='edit'
                            onClick={() => {
                                const editState = { ...record }
                                editState.type = parseDiscountType(editState.type)
                                editState.methodType = parsePaymentMethod(editState.methodType)
                                onEdit(editState)
                            }}
                        >
                            <EditOutlined /> Edit
                        </Menu.Item>
                        <Menu.Item key='delete'>
                            <Popconfirm
                                title='Are you sure you want to delete this rule?'
                                okText='Yes'
                                cancelText='No'
                                onConfirm={() => {
                                    onDelete(record)
                                }}
                            >
                                <DeleteOutlined className='text-error-500' /> Remove
                            </Popconfirm>
                        </Menu.Item>
                    </Menu>
                )

                return (
                    <Dropdown overlay={menu} trigger={['click']} placement='bottomRight'>
                        <MoreOutlined className='text-lg cursor-pointer' />
                    </Dropdown>
                )
            },
        },
    ]

    function getPaymentMethods() {
        return [
            ...enabledPaymentMethods.map((paymentMethod) => {
                if (paymentMethod.includes('wallet')) {
                    return {
                        label: paymentMethod
                            .split('-')
                            .reverse()
                            .map((string) => {
                                return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
                            })
                            .join(' '),
                        value: paymentMethod,
                    }
                } else {
                    return {
                        label: PaymentMethodsLabelNameMapping[paymentMethod] || paymentMethod,
                        value: paymentMethod,
                    }
                }
            }),
            {
                label: 'All Payment Methods',
                value: 'all',
            },
        ]
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={filter} // Yahan apni image ka path daalo
                    alt='Filter Icon'
                    style={{
                        width: '24px', // Slightly increased width
                        height: '24px', // Slightly increased height
                        marginRight: '8px',
                        border: '1px solid lightgray', // Light gray border
                        borderRadius: '4px', // Optional for rounded corners
                    }}
                />

                {/* Vertical line between image and select */}
                <div
                    style={{
                        width: '1px',
                        height: '24px',
                        backgroundColor: 'lightgray',
                        marginRight: '8px',
                    }}
                ></div>

                <Select
                    placeholder={'Select Payment Method Filter'}
                    options={getPaymentMethods().sort((a, b) =>
                        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                    )}
                    onChange={(value) => {
                        if (value) {
                            setSelectedPaymentMethod(value)
                        }
                    }}
                    style={{ width: '100%' }}
                    className='filter-prepaid-discount-tour'
                    disabled={discounts?.length === 0}
                    defaultValue={'all'}
                />
            </div>

            <br />
            <Table
                scroll={{
                    x: 'max-content',
                    y: 300,
                }}
                dataSource={filteredDiscounts}
                columns={columns}
                pagination={false}
                className='view-existing-prepaid-discounts'
            />
        </div>
    )
}

export default PrepaidDiscountRulesTable
