import { useState, ReactNode, useMemo, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Layout, Menu, Select } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import ShopifyAppManualCod from '@pages/admin/manual-cod'
import ShopifyAppfailSafe from '@pages/admin/fail-safe'
import { getMerchantDetails, getUserConfig, getUserDetails } from '@store/user/selectors'
import { stepConfiguration } from '@library/utilities/constants/constants'
import { titleCase } from '@gokwik/utilities'
import { fetchShopifyAppMerchantConfig } from '@store/shopify-app'
import { isShopifyAppCheckoutEnabled } from '@store/shopify-app/selectors'
import { FireEvents } from '@library/utilities/helpers/eventsHelper'
import { fireClickedEventOnCheckoutAdminPage } from '@library/utilities/helpers/helper'
import { useAppDispatch } from '@library/utilities/hooks/index'

const { Sider } = Layout

interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
    className?: string
}

function createMenuItem(
    label: ReactNode,
    key: string,
    className?: string,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        children,
        label,
        type,
        className,
    }
}

export default function () {
    const merchant_details = useSelector(getMerchantDetails)
    const config = useSelector(getUserConfig)
    const route = useLocation()
    const navigate = useNavigate()
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })

    const user_details = useSelector(getUserDetails)

    const eventsClass = new FireEvents(merchant_details, user_details).features
    const CheckoutAdminEvents = eventsClass.checkoutAdminEvents

    const adminList = useMemo(
        () => [
            {
                title: 'Manual COD',
                path: '/manual-cod',
                component: () => <ShopifyAppManualCod events={eventsClass.manualCodEvents} />,
                className: 'manual-cod',
                hideInMenu: false,
            },
            {
                title: 'Fail Safe',
                path: '/fail-safe',
                component: () => <ShopifyAppfailSafe />,
                className: 'fail-safe',
                hideInMenu: false,
            },
        ],
        [config],
    )
    const menuItems = () => {
        const items = adminList?.reduce(
            (result, item) => [
                ...result,
                ...(item?.hideInMenu
                    ? []
                    : [createMenuItem(item?.title, `/shopify-app/admin${item?.path}`, item?.className)]),
            ],
            [],
        )
        return items
    }

    useEffect(() => {
        if (CheckoutAdminEvents) {
            CheckoutAdminEvents.fireClickedEvent()
        }
    }, [CheckoutAdminEvents])

    useEffect(() => {
        if (route.pathname === '/shopify-app/admin') navigate('/shopify-app/admin' + adminList[0].path)
    }, [route.pathname])

    const openItems = useMemo(() => {
        const menu = menuItems()
        const openedKey = menu.find((item) => {
            if (item.key === route.pathname) {
                return true
            }
            if (item.children) {
                return item.children.some((child) => child.key === route.pathname)
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
        } else if (previousOpenedItems.current.openedKey) {
            return {
                ...previousOpenedItems.current,
            }
        } else {
            let similarSelectedKey: MenuItem | undefined
            const similarKey = menu.find(
                (item) =>
                    !!item.children?.find((child) => {
                        if (route.pathname.includes(child.key)) {
                            similarSelectedKey = child
                            return true
                        } else return false
                    }),
            )

            previousOpenedItems.current = {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
            return {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
        }
    }, [route.pathname, adminList])

    const eventMappings: Record<string, string> = {
        '/shopify-app/manual-cod': 'manual-cod',
    }

    const handleMenuSelection = ({ key }: any) => {
        const eventName = eventMappings[key]
        fireClickedEventOnCheckoutAdminPage(eventName, eventsClass)
        navigate(key)
    }

    return (
        <div className='w-full bg-white'>
            <Layout className='h-full'>
                {(stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >=
                    stepConfiguration['imp_checks_passed']?.id ||
                    !merchant_details?.onboarding_status?.kwik_checkout_app) && (
                    <Sider
                        className='bg-white h-full mr-4 border-0 border-gray-100 border-r-2 fixed overflow-hidden'
                        style={{ background: 'white' }}
                    >
                        <div className='setting-menu-custom-css'>
                            <Menu
                                onClick={handleMenuSelection}
                                defaultOpenKeys={[openItems.openedKey]}
                                defaultSelectedKeys={[openItems.selectedKey]}
                                key={`${route.pathname}-${openItems.openedKey}-${openItems.selectedKey}`}
                                items={menuItems()}
                                inlineCollapsed={true}
                                mode='inline'
                            />
                        </div>
                    </Sider>
                )}
                <Layout className='overflow-auto'>
                    <Routes>
                        {adminList.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component />} />
                        ))}
                    </Routes>
                </Layout>
            </Layout>
        </div>
    )
}
