import React, { useEffect } from 'react'
import { CreateDiscountLayout } from './createDiscountLayout'
import { useLocation } from 'react-router-dom'
import { Button } from 'gokwik-ui-kit'
import { useCreateDiscount } from '../helpers/useCreateDiscount'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

interface CreateDiscountProps {
    events: ICommonEvents
}

const CreateDiscount: React.FC<CreateDiscountProps> = ({ events }) => {
    const location = useLocation()
    const {
        getTitles,
        formState,
        currentStep,
        discountAction,
        discountType,
        dynamicStepItems,
        saveStepAndProceed,
        goBack,
    } = useCreateDiscount(location?.state?.type || '')

    const { configsUpsert } = events

    const isAutomaticDiscount = formState?.method === 'automatic'

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text
                ? prev
                : [
                      prev[0],

                      {
                          key: 'discount',
                          href: '/shopify-app/settings/discount',
                          text: 'Discount',
                      },
                      {
                          key: 'create-discount',
                          href: `/shopify-app/settings/create-discount`,
                          text: 'Create Discount',
                      },
                  ],
        )
    }, [])

    return (
        <CreateDiscountLayout
            headerTitle={getTitles.headerTitle}
            subtitle={getTitles.subTitle}
            currentStep={currentStep}
            stepsItems={dynamicStepItems()}
            formState={formState}
            discountType={discountType}
            events={events}
        >
            <>
                {discountAction === 'view' && (
                    <div className='w-full flex gap-1 justify-end p-3 rounded mt-2'>
                        {(currentStep > 0 || discountAction === 'view') && (
                            <Button variant='default' onClick={goBack}>
                                {discountAction === 'view' ? 'Back' : 'Go Back'}
                            </Button>
                        )}
                        {(currentStep < 2 || (!isAutomaticDiscount && currentStep < 3)) && (
                            <Button variant='primary' onClick={saveStepAndProceed}>
                                {discountAction === 'view' ? 'View Next' : 'Save & Proceed'}
                            </Button>
                        )}
                    </div>
                )}
            </>
        </CreateDiscountLayout>
    )
}

export const CreateDiscountContainer = React.memo(CreateDiscount)
