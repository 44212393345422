import { Card, Col, Input, message, Row, QuestionCircleOutlined, Tooltip } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import {
    defaultShopifyNewPaymentUIConfig,
    defaultShopifyPaymentUIConfig,
    PaymentMethodsReorderingLabelNameMapping,
    paymentPageTooltips,
} from './constants'

const PaymentMethodsList = ({
    listItems,
    setListItems,
    config,
    isUpdated,
    setIsUpdated,
    setupdatedPaymentUIConfig,
    inputErrors,
    setInputErrors,
}) => {
    const [draggedListItem, setDraggedListItem] = useState(null)
    const [paymentMethodDisplayNames, setPaymentMethodDisplayNames] = useState({})

    useEffect(() => {
        let paymentButtonTextFromConfig: Record<string, any> = JSON.parse(
            JSON.stringify(config?.paymentUiConfig?.payment_page_text || {}),
        )
        if (config?.newPaymentsUIEnabled) {
            paymentButtonTextFromConfig = JSON.parse(
                JSON.stringify(config?.newPaymentsUIConfig?.methodsCustomization || {}),
            )
        }

        let displayNames = {}
        Object.entries(
            config?.newPaymentsUIEnabled
                ? defaultShopifyNewPaymentUIConfig.methodsCustomization
                : defaultShopifyPaymentUIConfig.payment_page_text,
        ).forEach(([key, value]) => {
            displayNames[key] = config?.newPaymentsUIEnabled ? value.buttonText : value.btn_text
        })
        if (config?.newPaymentsUIEnabled) {
            displayNames['paylater'] = 'Pay via Pay Later'
        } else {
            displayNames['bnpl'] = 'Pay via BNPL'
        }

        let valueChanged = false

        for (const [paymentMethodId, buttonText] of Object.entries(paymentButtonTextFromConfig)) {
            if (config?.newPaymentsUIEnabled) {
                if (!isUpdated || !paymentMethodDisplayNames?.[paymentMethodId]?.length) {
                    displayNames[paymentMethodId] = buttonText.buttonText
                    valueChanged = true
                }
            } else {
                if (!isUpdated || !paymentMethodDisplayNames?.[paymentMethodId]?.length) {
                    displayNames[paymentMethodId] = buttonText.btn_text
                    valueChanged = true
                }
            }
        }

        if (valueChanged) {
            setPaymentMethodDisplayNames(displayNames)
        }
    }, [listItems])

    useEffect(() => {
        let tempConfig = JSON.parse(JSON.stringify(config?.paymentUiConfig || {}))
        let paymentUiTextConfig = tempConfig?.payment_page_text || {}
        if (config?.newPaymentsUIEnabled) {
            tempConfig = JSON.parse(JSON.stringify(config?.newPaymentsUIConfig || {}))
            paymentUiTextConfig = tempConfig?.methodsCustomization || {}
        }

        let errorMethods = ''

        const paymentMethodsFromConfig = listItems.map((listItem) => listItem.paymentMethodId)

        for (const [paymentMethodId, paymentMethodName] of Object.entries(paymentMethodDisplayNames)) {
            for (const [paymentMethodIdFromConfig, buttonTextObj] of Object.entries(paymentUiTextConfig)) {
                if (paymentMethodId === paymentMethodIdFromConfig) {
                    if (!paymentMethodName) {
                        if (paymentMethodsFromConfig.includes(paymentMethodIdFromConfig)) {
                            errorMethods += paymentMethodId + ', '
                            setInputErrors((prev) => {
                                return {
                                    ...prev,
                                    [paymentMethodId]: true,
                                }
                            })
                        }
                    } else {
                        if (config?.newPaymentsUIEnabled) {
                            tempConfig.methodsCustomization[paymentMethodId].buttonText = paymentMethodName
                        } else {
                            tempConfig.payment_page_text[paymentMethodId].btn_text = paymentMethodName
                        }
                        setInputErrors((prev) => {
                            return {
                                ...prev,
                                [paymentMethodId]: false,
                            }
                        })
                    }
                }
            }
        }

        if (errorMethods.length) {
            message.error(`Payment Method Text cannot be empty for methods: ${errorMethods.slice(0, -2)}`)
        }
        if (Object.keys(tempConfig || {}).length) {
            setupdatedPaymentUIConfig(tempConfig)
        }
    }, [paymentMethodDisplayNames])

    const handleDragStart = (e, index) => {
        setTimeout(() => {
            setDraggedListItem(index)
        })
        e.dataTransfer.setData('index', index)
    }

    const handleDragOver = (e, index) => {
        e.preventDefault()
        if (draggedListItem !== index) {
            const newData = [...listItems]
            const [removedItem] = newData.splice(draggedListItem, 1)
            newData.splice(index, 0, removedItem)
            setListItems(newData)
            setDraggedListItem(index)
            setIsUpdated(true)
        }
    }

    const handleDrop = () => {
        setDraggedListItem(null)
    }

    const handleUpdate = (e, paymentMethodId: string) => {
        if (paymentMethodId in paymentMethodDisplayNames) {
            const updatedMethodNames = JSON.parse(JSON.stringify(paymentMethodDisplayNames))
            updatedMethodNames[paymentMethodId] = e.target.value
            setPaymentMethodDisplayNames(updatedMethodNames)
            setIsUpdated(true)
        }
    }

    const handleInput = (e) => {
        if (e.key === '-' || e.key === '+') {
            e.preventDefault()
        }
    }

    function unParsePaymentMethod(input: string) {
        return PaymentMethodsReorderingLabelNameMapping[input] || input
    }

    function fetchDefaultValue(paymentMethodId: string) {
        if (!fetchValue(paymentMethodId).length) {
            if (config?.newPaymentsUIEnabled) {
                return defaultShopifyNewPaymentUIConfig.methodsCustomization[paymentMethodId]?.buttonText || ''
            }
            return defaultShopifyPaymentUIConfig.payment_page_text[paymentMethodId]?.btn_text || ''
        }
    }

    function fetchValue(paymentMethodId: string) {
        if (config?.newPaymentsUIEnabled) {
            return paymentMethodDisplayNames[paymentMethodId] || ''
        }
        return paymentMethodDisplayNames[paymentMethodId] || ''
    }

    return (
        <div className="w-full mb-6 p-4 flex bg-white rounded-lg shadow-md flex-col gap-4">
            <Row gutter={24} justify={'space-between'}>
                <Col className="rounded-lg" span={24}>
                
                    <div className="flex justify-between items-start">
                     
                        <div className='flex flex-col space-y-1'>
                            <p className='text-base font-semibold'>Payment Methods</p>
                            <p>Choose and customize your payment preferences</p>
                        </div>
    
                    
                        <div className="flex flex-col gap-4">
                            {listItems?.map((item, index) => (
                                <Card
                                    key={index}
                                    hoverable
                                    draggable={true}
                                    onDragStart={(e) => handleDragStart(e, index)}
                                    onDragOver={(e) => handleDragOver(e, index)}
                                    onDrop={(e) => handleDrop()}
                                    className="mb-4 border"
                                >
                                    <div className="flex items-center">
                                        <div className="flex flex-col justify-between h-4 mr-4">
                                            <span className="block w-5 h-0.5 bg-gray-300"></span>
                                            <span className="block w-5 h-0.5 bg-gray-300"></span>
                                            <span className="block w-5 h-0.5 bg-gray-300"></span>
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <Row className="flex" justify={'space-between'}>
                                                <div className="flex items-center">
                                                    {unParsePaymentMethod(item.paymentMethodId)}
                                                </div>
                                                {inputErrors[item.paymentMethodId] ? (
                                                    <div
                                                        className="border rounded-[20px] px-2 py-1"
                                                        style={{ borderColor: 'red' }}
                                                    >
                                                        <Input
                                                            style={{ borderColor: 'red' }}
                                                            type="text"
                                                            onKeyDown={(event) => handleInput(event)}
                                                            onChange={(e) => handleUpdate(e, item.paymentMethodId)}
                                                            defaultValue={fetchDefaultValue(item.paymentMethodId)}
                                                            value={fetchValue(item.paymentMethodId)}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="border rounded-[20px] px-2 py-1">
                                                        <Input
                                                            type="text"
                                                            onKeyDown={(event) => handleInput(event)}
                                                            onChange={(e) => handleUpdate(e, item.paymentMethodId)}
                                                            defaultValue={fetchDefaultValue(item.paymentMethodId)}
                                                            value={fetchValue(item.paymentMethodId)}
                                                        />
                                                    </div>
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
    
}

export default PaymentMethodsList
