import {
    Button,
    Col,
    Divider,
    Modal,
    Row,
    Tooltip,
    message,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    Tag,
    PlayCircleFilled,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import AddShippingRateForm from './addOrEditShippingRateForm'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { NoDataIcon } from '@library/images/noDataIcon'
import BookDownload from '@library/images/book-download.svg'
import { getCookie, setCookie } from '@library/utilities/helpers/cookieHelper'
import { FloatingTourIcon, RenderTour } from '../tour/tour'
import { shopifyShippingServiceabilityTourSteps } from '../tour/tourSteps'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import { IShippingServiceabilityEvents } from '@library/utilities/helpers/eventsHelper'

const index = (props: { events: IShippingServiceabilityEvents }) => {
    const shippingServiceabilityEvents = props.events;
    const navigate = useNavigate()
    const merchantDetails = useSelector(getMerchantDetails)
    const [configJSON, setConfigJSON] = useState(null)
    const [listItems, setListItems] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
    const [action, setAction] = useState({ name: null, id: null })
    const [modalTitle, setModalTitle] = useState('Add shipping method')
    const [showTour, setShowTour] = useState(false)
    const [onBoardingStates, setOnBoardingStates] = useState({
        addCodShippingRates: false,
    })
    const [playTutorial, setPlayTutorial] = useState(false)

    const nextOnboardingStepRedirectionPath = '/onboarding/cod-setup?cod-shipping-rate=true'

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        setOnBoardingStates({
            addCodShippingRates: params.get('cod-shipping-rate') === 'true',
        })
        if (params.get('cod-shipping-rate') === 'true') {
            setShowModal(true)
        }

        const checkCookies = () => {
            const isTourVisited = getCookie('shopifyShippingServiceabilityTour')
            if (!isTourVisited && params.get('cod-shipping-rate') !== 'true') {
                setShowTour(true)
            }
        }
        setTimeout(checkCookies, 100)
    }, [])

    const getShippingConfig = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
        })

        if (response?.data?.status_code === 200) {
            setConfigJSON({ shippingConfig: response?.data?.data?.shippingConfig || [] })
            setListItems(response?.data?.data?.shippingConfig)
        }

        if (onBoardingStates.addCodShippingRates) {
            if (response?.data?.status_code === 200 && response?.data?.data?.shippingConfig?.length) {
                shippingServiceabilityEvents.onboardingPrefill.fireSuccessEvent({
                    onboarding_flow: onBoardingStates.addCodShippingRates,
                })
            } else {
                shippingServiceabilityEvents.onboardingPrefill.fireFailureEvent({
                    onboarding_flow: onBoardingStates.addCodShippingRates,
                    apiStatus: response?.data?.status_code,
                    errorMessage: "Failed to Prefill Shipping"
                })
            }
        }
    }

    useEffect(() => {
        getShippingConfig()
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/shopify-app/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'shipping-serviceability',
                href: '/shopify-app/settings/shipping',
                text: 'Shipping Serviceability',
            },
        ])
    }, [])

    const updateOnBoardingStatus = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchant(merchantDetails?.m_id) + '?mode=live',
                payload: {
                    current_onboarding_status: 'gokwik_shipping_setup_done',
                },
            })

            if (response.data.status_code === 200) {
                //redirect to next onboarding step
                shippingServiceabilityEvents.onboardingStatusUpdate.fireSuccessEvent({
                    onboarding_flow: onBoardingStates.addCodShippingRates,
                    current_onboarding_status: 'gokwik_shipping_setup_done',
                });
                navigate(nextOnboardingStepRedirectionPath)
            } else {
                shippingServiceabilityEvents.onboardingStatusUpdate.fireFailureEvent({
                    onboarding_flow: onBoardingStates.addCodShippingRates,
                    current_onboarding_status: 'gokwik_shipping_setup_done',
                    apiStatus: response.data.status_code,
                    errorMessage: "Failed to Update Onboarding Status"
                })
            }
        } catch (error) {
            shippingServiceabilityEvents.onboardingStatusUpdate.fireFailureEvent({
                onboarding_flow: onBoardingStates.addCodShippingRates,
                current_onboarding_status: 'gokwik_shipping_setup_done',
                errorMessage: "Failed to Update Onboarding Status"
            })
            console.error('Error:', error)
            message.error('Failed to redirect to next step')
        }
    }

    const deleteShippingMethod = async (shippingMethodId) => {
        const temp = { ...configJSON }
        const indexToRemove = temp.shippingConfig.findIndex((item) => item.id === shippingMethodId)
        const shippingToBeRemoved = temp?.shippingConfig[indexToRemove];
        if (indexToRemove !== -1) {
            temp?.shippingConfig?.splice(indexToRemove, 1)
        }

        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
                payload: temp,
            })

            if (response.data.status_code === 200) {
                setShowDeleteConfirmationModal(false)
                message.success('Shipping method deleted successfully')
                shippingServiceabilityEvents.configsUpsert.delete.fireSuccessEvent({
                    onboarding_flow: onBoardingStates.addCodShippingRates,
                    removedShipping: shippingToBeRemoved
                });
                getShippingConfig()
            } else {
                shippingServiceabilityEvents.configsUpsert.delete.fireFailureEvent({
                    onboarding_flow: onBoardingStates.addCodShippingRates,
                    removedShipping: shippingToBeRemoved,
                    apiStatus: response.data.status_code,
                    errorMessage: "Failed to Delete Shipping"
                });
            }
        } catch (error) {
            shippingServiceabilityEvents.configsUpsert.delete.fireFailureEvent({
                onboarding_flow: onBoardingStates.addCodShippingRates,
                removedShipping: shippingToBeRemoved,
                errorMessage: error?.message || "Failed to Delete Shipping"
            });
            setShowDeleteConfirmationModal(false)
            console.error('Error:', error)
            message.error('Failed to delete shipping method')
        }
    }

    useEffect(() => {
        if (action?.name) {
            if (action?.name && action?.name !== 'delete') {
                if (action?.name === 'edit') {
                    setModalTitle(
                        `Edit ${configJSON?.shippingConfig
                            .find((item) => item.id === action?.id)
                            .shipping_name?.trim()} shipping method`,
                    )
                    shippingServiceabilityEvents.configsUpsert.edit.fireClickedEvent({
                        onboarding_flow: onBoardingStates.addCodShippingRates,
                    })
                } else if (action?.name === 'view') {
                    setModalTitle(
                        `${configJSON?.shippingConfig
                            .find((item) => item.id === action?.id)
                            .shipping_name?.trim()} shipping method`,
                    )
                } else {
                    setModalTitle('Add new shipping method')
                    shippingServiceabilityEvents.configsUpsert.add.fireClickedEvent({
                        onboarding_flow: onBoardingStates.addCodShippingRates,
                    })
                }
                setShowModal(true)
            } else {
                shippingServiceabilityEvents.configsUpsert.delete.fireClickedEvent({
                    onboarding_flow: onBoardingStates.addCodShippingRates,
                })
                setShowDeleteConfirmationModal(true)
            }
        }
    }, [action])

    const handleActionButtonClick = (actionName, shippingMethodId) => {
        setAction({ name: actionName, id: shippingMethodId })
    }


    function startTour() {
        setShowTour(true);
        props.events.tourStepsEvents.fireStartedEvent();
    }

    const openGuide = () => {
        shippingServiceabilityEvents.tourStepsEvents.fireGuideOpenedEvent({
            onboarding_flow: onBoardingStates.addCodShippingRates,
        })
    };

    const nextStep = (step: number) => {
        const stepTitle = shopifyShippingServiceabilityTourSteps[step].title
        shippingServiceabilityEvents.tourStepsEvents.fireNextStepEvent({
            onboarding_flow: onBoardingStates.addCodShippingRates,
            stepTitle: stepTitle
        })
    }

    const closeTour = () => {
        setShowTour(false)
        setCookie('shopifyShippingServiceabilityTour', true, 365);
        shippingServiceabilityEvents.tourStepsEvents.fireSkippedEvent({
            onboarding_flow: onBoardingStates.addCodShippingRates
        });
    }

    const finishTour = () => {
        setShowTour(false)
        setCookie('shopifyShippingServiceabilityTour', true, 365);
        shippingServiceabilityEvents.tourStepsEvents.fireCompletedEvent({
            onboarding_flow: onBoardingStates.addCodShippingRates
        });
    }

    return (
        <div className='w-full  p-2 flex flex-col gap-4'>
            <RenderTour showTour={showTour} steps={shopifyShippingServiceabilityTourSteps} onClose={closeTour} onChange={nextStep} onFinish={finishTour} />
            {showDeleteConfirmationModal && (
                <Modal
                    title={`Delete ${configJSON?.shippingConfig
                        ?.find((item) => item?.id === action?.id)
                        ?.shipping_name?.trim()} shipping method ?`}
                    onCancel={() => setShowDeleteConfirmationModal(false)}
                    open={showDeleteConfirmationModal}
                    onOk={() => deleteShippingMethod(action?.id)}
                    centered
                    width={'450px'}
                >
                    Do you really want to delete{' '}
                    {configJSON?.shippingConfig?.find((item) => item?.id === action?.id)?.shipping_name?.trim()}{' '}
                    shipping method ?
                </Modal>
            )}
            {showModal && (
                <Modal
                    title={
                        <div className='flex items-center justify-between'>
                            <span>{modalTitle}</span>
                            {onBoardingStates?.addCodShippingRates && (
                                <Button onClick={() => setPlayTutorial((prev) => !prev)} type='link' variant='default'>
                                    <PlayCircleFilled className='text-black' />
                                    <span className='text-black'>{playTutorial ? 'Close' : 'Play'} Tutorial</span>
                                </Button>
                            )}
                        </div>
                    }
                    footer={null}
                    onCancel={() => setShowModal(false)}
                    open={showModal}
                    centered
                    width={'450px'}
                    closable={!onBoardingStates?.addCodShippingRates}
                    maskClosable={false}
                >
                    {playTutorial && (
                        <iframe
                            width='100%'
                            height='300px'
                            src='https://www.youtube.com/embed/BxJ1F4Obwtc?autoplay=1&mute=1'
                            allow='autoplay; encrypted-media'
                            className='rounded-xl'
                            allowFullScreen
                            title='video'
                        ></iframe>
                    )}
                    <AddShippingRateForm
                        setShowModal={setShowModal}
                        configJSON={configJSON}
                        action={action}
                        getShippingConfig={getShippingConfig}
                        events={shippingServiceabilityEvents}
                        isOnBoardingFlow={onBoardingStates.addCodShippingRates}
                    />
                </Modal>
            )}
            <Row className='bg-white rounded-md p-2' justify={'space-between'} align={'middle'} wrap={true}>
                <Col>
                    <div className='flex flex-col justify-between'>
                        <div className='flex flex-row'>
                            <span className=' text-[#000000E0] font-semibold text-lg pb-1'>Shipping Methods</span>
                            <a
                                className='ml-3 mb-1 mt-1 text-sm font-normal'
                                id='shipping-serviceability-guide'
                                href='https://scribehow.com/page/Untitled__ryXskpYUTriDwz_tF1eKwg'
                                target='_blank'
                                rel='noopener noreferrer'
                                onClick={openGuide}
                            >
                                <img className='mr-1 mb-1' src={BookDownload} height={'18'} width={'18'} />
                                Open Guide
                            </a>
                        </div>
                        <span className='text-[#00000080] font-normal text-sm pt-1'>
                            Map your existing Shopify shipping methods to Prepaid, CoD, or both
                        </span>
                    </div>
                </Col>
                <Col>
                    <Button
                        id='add-shipping-method'
                        variant='primary'
                        onClick={() => handleActionButtonClick('add', null)}
                    >
                        + &nbsp; Add Shipping Method
                    </Button>
                </Col>
            </Row>
            {listItems && listItems?.length > 0 ? (
                <Row gutter={24} justify={'space-between'}>
                    <Col className=' rounded-lg' span={18}>
                        <>
                            {listItems?.map((item, index) => {
                                return (
                                    <div
                                        key={1}
                                        className={`my-2 border border-solid border-[#00000026] bg-[#FFF] rounded-lg pt-3 pb-4 px-4 transition-all duration-200 ease-in-out list-item-class`}
                                    >
                                        <Row align={'middle'}>
                                            <Col span={14}>
                                                <div className='flex flex-wrap flex-col'>
                                                    <div className=' text-lg font-semibold'>
                                                        {item?.shipping_name?.trim()}
                                                    </div>
                                                    <div className='text-[#000] flex gap-1 flex-wrap items-center text-sm font-normal opacity-60'>
                                                        <span>Price: {item?.shipping_charge}</span>
                                                        <Divider type='vertical' />
                                                        {item?.minOrderPrice !== null && item?.minOrderPrice >= 0 && (
                                                            <>
                                                                <span>Min order price: ₹{item?.minOrderPrice}</span>
                                                                <Divider type='vertical' />
                                                            </>
                                                        )}

                                                        {item?.maxOrderPrice !== null && item?.maxOrderPrice >= 0 && (
                                                            <span>Max order price: ₹{item?.maxOrderPrice}</span>
                                                        )}

                                                        {item?.minWeight !== null && item?.minWeight >= 0 && (
                                                            <>
                                                                <span>Minimum weight: {item?.minWeight}kg</span>
                                                                <Divider type='vertical' />
                                                            </>
                                                        )}

                                                        {item?.maxWeight !== null && item?.maxWeight >= 0 && (
                                                            <span>Maximum weight: {item?.maxWeight}kg</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={10}>
                                                <div className='w-full flex flex-wrap justify-end gap-2 items-center'>
                                                    <div className='flex flex-grow items-center justify-end gap-2'>
                                                        {item?.paymentOption === 'all' ? (
                                                            <>
                                                                <Tag
                                                                    variant={'default'}
                                                                    className='border border-solid border-[#1677FF] rounded flex items-center'
                                                                >
                                                                    <span className='text-[#1677FF]'>COD</span>
                                                                </Tag>
                                                                <Tag
                                                                    variant={'default'}
                                                                    className='border border-solid border-[#1677FF] rounded flex items-center'
                                                                >
                                                                    <span className='text-[#1677FF]'>Prepaid</span>
                                                                </Tag>
                                                            </>
                                                        ) : (
                                                            item?.paymentOption && (
                                                                <Tag
                                                                    variant={'default'}
                                                                    className='border border-solid border-[#1677FF] rounded flex items-center'
                                                                >
                                                                    <span className='text-[#1677FF]'>
                                                                        {item?.paymentOption?.toUpperCase()}
                                                                    </span>
                                                                </Tag>
                                                            )
                                                        )}
                                                    </div>

                                                    <div className=' border opacity-60 border-solid border-gray-300 rounded-md p-1 cursor-pointer'>
                                                        <Tooltip title={'View'}>
                                                            <EyeOutlined
                                                                onClick={() => handleActionButtonClick('view', item.id)}
                                                            />
                                                        </Tooltip>
                                                        <Divider type='vertical' />

                                                        <Tooltip title={'Edit'}>
                                                            <EditOutlined
                                                                onClick={() => handleActionButtonClick('edit', item.id)}
                                                            />
                                                        </Tooltip>
                                                        <Divider type='vertical' />
                                                        <Tooltip title={'Delete'}>
                                                            <DeleteOutlined
                                                                onClick={() =>
                                                                    handleActionButtonClick('delete', item.id)
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}

                            {onBoardingStates?.addCodShippingRates && (
                                <Row justify={'end'}>
                                    <Button variant='primary' onClick={updateOnBoardingStatus}>
                                        I Did It, What Next?
                                    </Button>
                                </Row>
                            )}
                        </>
                    </Col>
                    <Col span={6}>
                        <div>
                            <div className='flex flex-col gap-3'>
                                <div>Address</div>
                                <div
                                    style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                                    className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                                ></div>
                                <div
                                    style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                                    className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                                ></div>
                            </div>
                            <Divider />
                            <div className='flex flex-col gap-3'>
                                <div>Shipping Methods</div>
                                <div className='flex flex-col gap-2 max-h-60 overflow-y-auto'>
                                    {listItems?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    'flex justify-between border border-solid border-[#00000026] bg-white rounded-md p-3'
                                                }
                                            >
                                                <span className=' text-sm font-normal'>
                                                    {item.shipping_name?.trim()}&nbsp;({item.paymentOption})
                                                </span>{' '}
                                                <span className=' text-sm font-semibold'>₹ {item.shipping_charge}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <Divider />
                            <div className='flex flex-col gap-3'>
                                <div>Payment</div>
                                <div
                                    style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                                    className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                                ></div>
                                <div
                                    style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                                    className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                                ></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col span={24}>
                        <div className='p-20 text-center flex flex-col items-center align-middle justify-center'>
                            <NoDataIcon />
                            <h3>No Shipping method added.</h3>
                        </div>
                    </Col>
                </Row>
            )}
            <FloatingTourIcon onClick={startTour} />
        </div>
    )
}

export default index
