export const PermissionValues = {
    orders: {
        view: 'view_order',
        initiateRefund: 'refund',
    },
    abandonedCarts: {
        view: 'abandoned_cart',
    },
    settings: {
        view: 'setting',
    },
    checkoutAnalytics: {
        view: 'view_checkout_analytics',
    },
    reports: {
        view: 'view_reports',
    },
}

export const DiscountConfigKeys = {
    tieredPrepaidDiscount: 'show_tiered_prepaid_discount',
    discountCode: 'show_discount_code',
    gwpAndExtraDiscount: 'show_gwp_and_extra_discount',
    codChargeConfig: 'show_cod_charge_config',
    discountApplicability: 'show_discount_applicability',
    dynamicDiscount: 'show_dynamic_discounts',
    buyXAtYDiscounts: 'show_buy_x_at_y_discounts',
    gwpWithAnyDiscount: 'show_gwp_with_any_discount',
    discountCapping: 'show_discount_capping',
}

export const cohortDiscountTooltips = {
    csvDownload: [
        'Upload a CSV file containing Phone Numbers eligible for the discount.',
        'The file must be in attached CSV format.',
    ],
}

export const DiscountApplicabilityKeys = {
    shopify: { label: 'Shopify', value: 'shopify' },
    uploaded_CSV: { label: 'Uploaded CSV', value: 'uploaded_csv' },
    include: 'Include',
    exclude: 'Exclude',
    included: 'Included',
    excluded: 'Excluded',
}

export const OffersDiscountKeys = {
    only_when_eligible: { label: 'Only When Eligible', value: 'ONLY_WHEN_ELIGIBLE' },
    always: { label: 'Always', value: 'ALWAYS' },
    shopify_based_cohort: { label: 'Shopify Based Cohort', value: 'shopify_based_cohort' },
    csv_based_cohort: { label: 'CSV Based Cohort', value: 'csv_based_cohort' },
    all: { label: 'All', value: 'all' },
    new_users_only: { label: 'New Users Only', value: 'new' },
    repeat_users_only: { label: 'Repeat Users Only', value: 'existing' },
}

export const stepConfiguration = {
    password_setup: { id: 0, stepNumber: 0 },
    feature_tour_done: { id: 1, stepNumber: 1 },
    smart_cod_app_installed: { id: 2, stepNumber: 2 },
    smart_cod_app_activated: { id: 3, stepNumber: 3 },
    gokwik_shipping_setup_done: { id: 4, stepNumber: 4 },
    smart_cod_app_disabled: { id: 5, stepNumber: 5 },
    imp_checks_passed: { id: 6, stepNumber: 6 },
    kyc_viewed: { id: 7, stepNumber: 7 },
    kyc_basic_info_added: { id: 8, stepNumber: 8 },
    kyc_address_details: { id: 9, stepNumber: 9 },
    kyc_bank_details: { id: 10, stepNumber: 10 },
    kyc_business_details: { id: 11, stepNumber: 11 },
    kyc_document_upload: { id: 12, stepNumber: 12 },
    kyc_vpc: { id: 13, stepNumber: 13 },
    kyc_verification_pending: { id: 14, stepNumber: 14 },
    kyc_verification_failed: { id: 15, stepNumber: 15 },
    kyc_completed: { id: 16, stepNumber: 16 },
    kwik_checkout_button_activated: { id: 17, stepNumber: 17 },
    setup_completed: { id: 18, stepNumber: 18 },
}

export const RTO_FAKE_ORDER_REASONS = [
    'Multiple COD orders within short time frame',
    'Gibberish address',
    'Gibberish name',
]

export const Freebies = 'Freebie'
