import { useState } from 'react';
import { RateComponent } from './RateComponent';
import { ReviewModal } from './ReviewModal';
import { InfoCircleOutlined, Tooltip } from 'gokwik-ui-kit';
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig';
import { useLocation } from 'react-router-dom';

const ReviewComponent = (props: { events: any }) => {
    const [visible, setVisible] = useState(false);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState("");
    const [error, setError] = useState('');
    const location = useLocation();
    const currentPagePath = location.pathname;
    const pageName = currentPagePath.split('/').pop(); 
    const { sendRatingFeedback } = useShopifyMerchantConfigApi()
    const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);

    const handleRateChange = (value) => {
        props.events.fireClickedEvent({
            event: 'rating-change-clicked',
            Rating: value, 
            ratingSource: "fix-modal",
        });
        if (value <= 4) {
            setRating(value);
            setVisible(true);
        } else if (value === 5) {
            setVisible(false);
            window.open('https://apps.shopify.com/kwikcheckout#modal-show=WriteReviewModal', '_self');
        }
    };

    const handleCancel = async () => {
        setVisible(false);
        setRating(0);
        props.events.fireClickedEvent({
            event: 'rating-cancelled-clicked',
            Rating: rating, 
            ratingSource: "fix-modal",
        });
        await sendRatingFeedback(review, rating, false, setReview, setIsReviewSubmitted, props.events);
    };

    const submitReview = async () => {
        if (review?.length && review?.length < 30) {
            setError('Minimum character limit is 30.');
        } else {
            setError('');
            await sendRatingFeedback(review, rating, false, setReview, setIsReviewSubmitted, props.events);
            setVisible(false);
            setRating(0);
        }
    }

    return (
        <div>
            <div className='flex flex-col items-center gap-1 mr-2 ml-2 mb-2 bg-white p-3 rounded-md' >
                <div className='flex justify-start' style={{ lineHeight: '20px',  width: '14rem' }} >
                    <p className='text-[16px] m-0'>Review</p>
                    <Tooltip title={"Please leave a 5 star review"}>
                        <InfoCircleOutlined className='ml-2 mr-2' />
                    </Tooltip>
                    <RateComponent rating={rating} onRateChange={handleRateChange} />
                </div>
            </div>
            <ReviewModal
                visible={visible}
                review={review}
                onReviewChange={(trimmedReview) => setReview(trimmedReview)}
                onCancel={handleCancel}
                onSubmit={submitReview}
                error={error}
            />
        </div>
    );
};

export default ReviewComponent;