import APIEndPoints from "@library/utilities/constants/apiEndpoints"
import useShopifyMerchantConfigApi from "@library/utilities/hooks/useShopifyMerchantConfig"
import { ArrowLeftOutlined, Button, Col, Modal, PlusOutlined, QuestionCircleOutlined, Row, Switch, Tooltip } from "gokwik-ui-kit"
import { useState, useEffect } from "react"
import PrepaidDiscountRulesTable from "./discountTable"
import PrepaidDiscountRuleForm from "./discountRuleForm"
import { DefaultPaymentMethods } from "./constants"
import { getCookie, updateBreadcrumbs } from "@gokwik/utilities"
import { useNavigate } from "react-router-dom"
import { setCookie } from "@library/utilities/helpers/cookieHelper"
import { prepaidDiscountFeatureTourSteps } from "@pages/settings/tour/tourSteps"
import { FloatingTourIcon, RenderTour } from "@pages/settings/tour/tour"
import { ICommonEvents } from "@library/utilities/helpers/eventsHelper"

const PrepaidDiscounts = (props:{ events: ICommonEvents }) => {
    const navigate = useNavigate();
    const [showTour, setShowTour] = useState(false);

    const { tourStepsEvents, configsUpsert, fireClickedEvent } = props.events;

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);

    const [prepaidDiscountConfig, setPrepaidDiscountConfig] = useState({
        postOrderDiscount: false,
        prepaidDiscountEnabled: true,
        prepaidDiscounts: [],
        enabledPaymentMethods: []
    })

    const { config, saveConfig } = useShopifyMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.shopifyAppPrepaidDiscountSaveConfig,
        fireFailureEvent: configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: configsUpsert.edit.fireSuccessEvent,
    })

    const { saveConfig: saveMerchantConfig, config: tempConfig } = useShopifyMerchantConfigApi({
        useCache: true,
        fireFailureEvent: configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: configsUpsert.edit.fireSuccessEvent,
    })

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/shopify-app/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'payment-features',
                href: '/shopify-app/settings/payment-features',
                text: 'Payments Features',
            },
            {
                key: 'prepaid-discounts',
                href: '/shopify-app/settings/payment-features/prepaid-discounts',
                text: 'Prepaid Discounts Rules',
            },
        ])
        const checkCookies = () => {
            const isTourVisited = getCookie('prepaidDiscountsTour') || null;
            if (!isTourVisited) {
                setShowTour(true)
            }
        }
        setTimeout(checkCookies, 100)
        fireClickedEvent();
    }, [])

    const parseSupportedPrepaidDiscounts = (paymentMethods) => {
        const unSupportedPaymentMethods = ['ppcod-upi', 'cod', 'snapmint'];

        const res = paymentMethods.filter((method) => {
            if (method.includes('emi-') || method.includes('bnpl-')) return false;
            return !unSupportedPaymentMethods.includes(method);
        })

        return res;
    }

    useEffect(() => {
        if (config) {
            if (!config.paymentMethods?.length) {
                saveMerchantConfig({
                    paymentMethods: DefaultPaymentMethods.map((method) => {
                        return {
                            paymentMethodId: method
                        }
                    })
                })
                setPrepaidDiscountConfig({
                    postOrderDiscount: config.postOrderDiscountEnabled ?? false,
                    prepaidDiscountEnabled: config.prepaidDiscountEnabled ?? false,
                    prepaidDiscounts: config.gkPrepaidDiscountConfig || [],
                    enabledPaymentMethods: config.paymentMethods.map((method) => {
                        return method.paymentMethodId;
                    })
                })
            } else {
                setPrepaidDiscountConfig({
                    postOrderDiscount: config.postOrderDiscountEnabled ?? false,
                    prepaidDiscountEnabled: config.prepaidDiscountEnabled ?? false,
                    prepaidDiscounts: config.gkPrepaidDiscountConfig || [],
                    enabledPaymentMethods: parseSupportedPrepaidDiscounts(config.paymentMethods.map((method) => {
                        return method.paymentMethodId;
                    }))
                })
            }
        }
    }, [config])

    useEffect(() => {
        if (tempConfig) {
            if (!tempConfig.paymentMethods?.length) {
                saveMerchantConfig({
                    paymentMethods: DefaultPaymentMethods.map((method) => {
                        return {
                            paymentMethodId: method
                        }
                    })
                })
                setPrepaidDiscountConfig({
                    postOrderDiscount: tempConfig.postOrderDiscountEnabled ?? false,
                    prepaidDiscountEnabled: tempConfig.prepaidDiscountEnabled ?? false,
                    prepaidDiscounts: tempConfig.gkPrepaidDiscountConfig || [],
                    enabledPaymentMethods: tempConfig.paymentMethods.map((method) => {
                        return method.paymentMethodId;
                    })
                })
            } else {
                setPrepaidDiscountConfig({
                    postOrderDiscount: tempConfig.postOrderDiscountEnabled ?? false,
                    prepaidDiscountEnabled: tempConfig.prepaidDiscountEnabled ?? false,
                    prepaidDiscounts: tempConfig.gkPrepaidDiscountConfig || [],
                    enabledPaymentMethods: parseSupportedPrepaidDiscounts(tempConfig.paymentMethods.map((method) => {
                        return method.paymentMethodId;
                    }))
                })
            }
        }
    }, [tempConfig])

    function handleEdit (discountObject) {
        setModalTitle('Edit Prepaid Discount');
        setCurrentDiscountInfo(discountObject);
        setShowModal(true);
        configsUpsert.edit.fireClickedEvent({
            prevDiscountConfig: prepaidDiscountConfig.prepaidDiscounts?.find(discount => discount?.id === discountObject?.id),
            currentDiscountConfig: discountObject
        });
    }

    function handleDelete(id: string) {
        configsUpsert.delete.fireClickedEvent({
            discount: prepaidDiscountConfig.prepaidDiscounts.find(discount => discount.id === id)
        });
        saveConfig({
            gkPrepaidDiscountConfig: [{
                id,
                isDeleted: true
            }]
        })
    }

    function handleAdd() {
        setShowModal(true);
        setCurrentDiscountInfo(null);
        setModalTitle('Add Prepaid Discount');
        configsUpsert.add.fireClickedEvent();
    }

    function toggleFeatureFlags(postOrderDiscountFeatureFlag) {
        configsUpsert.edit.fireClickedEvent({
            prevDiscountConfig: {
                prepaidDiscountEnabled: prepaidDiscountConfig.prepaidDiscountEnabled,
                postOrderDiscountFeatureFlag: prepaidDiscountConfig.postOrderDiscount
            },
            currentDiscountConfig: {
                prepaidDiscountEnabled: postOrderDiscountFeatureFlag,
                postOrderDiscountFeatureFlag: postOrderDiscountFeatureFlag
            }
        });
        saveConfig({
            prepaidDiscountEnabled: postOrderDiscountFeatureFlag,
            postOrderDiscountEnabled: postOrderDiscountFeatureFlag
        })
    }

    function startTour() {
        setShowTour(true);
        tourStepsEvents.fireStartedEvent();
    }

    const nextStep = (step) => {
        handleAdd()
        if (step >= 2 && step <= 4) setShowModal(true);
        else setShowModal(false);
        tourStepsEvents.fireNextStepEvent({
            stepTitle: prepaidDiscountFeatureTourSteps[step].title
        });
    }

    const closeTour = () => {
        setShowTour(false)
        setShowModal(false);
        setCookie('prepaidDiscountsTour', true, 365)
        tourStepsEvents.fireSkippedEvent();
    }

    const finishTour = () => {
        setShowTour(false)
        setShowModal(false);
        setCookie('prepaidDiscountsTour', true, 365)
        tourStepsEvents.fireCompletedEvent();
    }
    
    return(
        <div>
            <RenderTour showTour={showTour} steps={prepaidDiscountFeatureTourSteps} onClose={closeTour} onChange={nextStep} followCurrentStep={true} onFinish={finishTour} />
            <Row className='rounded-sm p-2' gutter={[0, 24]} justify={'space-between'}>
                <Col span={16}>
                    <div className='flex items-top'>
                        <Button className='bg-gray-200 border-none' onClick={() => navigate(-1)}>
                            <ArrowLeftOutlined />
                        </Button>
                        <div className='ml-2'>
                            <p className='text-base font-semibold'>
                                Prepaid Discount Rules
                                {/* <a
                                    className='ml-3 text-sm font-normal'
                                    href='https://scribehow.com/page/Untitled__q1KTFWmjSHKvv2RBV3_atA'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    onClick={tourStepsEvents.fireGuideOpenedEvent()}
                                >
                                    <img className='mr-1 mb-1' src={BookDownload} height={'18'} width={'18'} />
                                    Open Guide
                                </a> */}
                            </p>
                            <p className="text-warning-500 ">
                                Warning: The prepaid discount will not be applicable if any flat product discount is already applied on an order.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='flex items-center gap-x-4 add-prepaid-discount-button'>
                        <Button variant='primary' onClick={() => handleAdd()} disabled={!(prepaidDiscountConfig.postOrderDiscount && prepaidDiscountConfig.prepaidDiscountEnabled)}>
                            <PlusOutlined /> Add New Discount Rule
                        </Button>
                    </div>
                </Col>
            </Row>
            <div>
                {showModal && (
                    <Modal
                        title={modalTitle}
                        footer={null}
                        onCancel={() => setShowModal(false)}
                        open={showModal}
                        centered
                        width={'700px'}
                    >
                        <PrepaidDiscountRuleForm
                            setShowModal={setShowModal}
                            discountInfo={currentDiscountInfo}
                            enabledPaymentMethods={prepaidDiscountConfig.enabledPaymentMethods}
                            saveConfig={saveConfig}
                            tourInProgress={showTour}
                            discounts={prepaidDiscountConfig.prepaidDiscounts}
                        />
                    </Modal>
                )}
                <br />
                <Row className='flex flex-col rounded-sm p-2' gutter={[0, 24]}>
                    <Col className="flex flex-row justify-end toggle-prepaid-discount-button">
                        <div className="flex flex-row">
                            <p><b>Enable Prepaid Discounts</b></p>
                            <Tooltip title={<>Toggle to enable/disable prepaid discounts, applicable on Shopify orders.</>}>
                                <QuestionCircleOutlined className='ml-2' />{' '}
                            </Tooltip>
                        </div>
                        <Switch className='ml-5' checked={prepaidDiscountConfig.postOrderDiscount && prepaidDiscountConfig.prepaidDiscountEnabled} onChange={(value) => toggleFeatureFlags(value)}></Switch>
                    </Col>
                </Row>
                <Col span={24}>
                    <PrepaidDiscountRulesTable
                        discounts={prepaidDiscountConfig.prepaidDiscountEnabled === true && prepaidDiscountConfig.postOrderDiscount === true ? prepaidDiscountConfig.prepaidDiscounts : []}
                        enabledPaymentMethods={prepaidDiscountConfig.enabledPaymentMethods}
                        onEdit={(discount) => handleEdit(discount)}
                        onDelete={(discount) => handleDelete(discount.id)}
                    />
                </Col>
            </div>
            <FloatingTourIcon onClick={startTour}/>
        </div>
    )
}

export default PrepaidDiscounts;