import { Button } from 'gokwik-ui-kit'
import sparkles from '@library/images/sparkles_lightBlue.svg'
import sparklesBlue from '@library/images/sparkles.svg'
import codVideo from '@library/images/addressPrefill.gif'
import minus from '@library/images/MinusOutlined.svg'
import check from '@library/images/check.svg'

const messages = [
    {
        text: 'Partial Payment on COD to Collect Upfront Amount',
        bgGradiant: 'spotlight-gradiant',
    },
    {
        text: 'Prepaid Payments via UPI, Net-Banking, Wallets, Cards, BNPL and More',
    },
    {
        text: 'Discounts on Prepaid Orders',
    },
]

const NudgeCard = ({ isExpandWidget, startToComplete, onNudgeClose, isKyc }) => {
    return (
        <div className={`upgrade-plan-expanded fixed w-[380px] ${isExpandWidget ? 'open' : 'closed'}`}>
            {isExpandWidget && (
                <div>
                    <div
                        className='tab-heading flex items-center text-[#004B8D] p-2 gap-2 rounded'
                        style={{ background: 'linear-gradient(to right, #91CAFF, #E6F4FF' }}
                    >
                        <div>
                            <img src={sparklesBlue} className='m-[auto]' />
                        </div>
                        <div>{isKyc ? 'Complete KYC' : 'Install payments App'}</div>
                        <div className='ml-[auto] bg-[#c8def1] rounded'>
                            <img src={minus} onClick={() => onNudgeClose()} className='p-[4px]' />
                        </div>
                    </div>
                    <div className='plan-video'>
                        <img src={codVideo} style={{ height: 'auto', width: '100%', background: '#004B8D' }} />
                    </div>
                    <div className='plan-description bg-[#004B8D] text-[#fff] p-4'>
                        <div className='heading text-[20px] font-bold mb-5'>Add Additional Features</div>
                        <div className='plan-features font-medium font-medium text-[14px] flex flex-col gap-2'>
                            {messages?.map((message, index) => (
                                <div
                                    key={index}
                                    style={{ lineHeight: '1.4rem' }}
                                    className={`flex items-start gap-2 p-1 rounded-lg ${
                                        message.bgGradiant && message.bgGradiant
                                    }`}
                                >
                                    <img src={check} style={{ marginTop: '4px' }}/>
                                    {message.bgGradiant && <img src={sparkles} className='m-[auto]' />}
                                    <p>{message.text}</p>
                                </div>
                            ))}
                        </div>
                        <Button
                            onClick={startToComplete}
                            style={{ border: 'none', outline: 'none' }}
                            className='mt-[20px] flex items-center justify-center text-[16px] p-[20px] w-[100%]'
                        >
                            {isKyc ? 'Start KYC' : 'Start Installation'}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default NudgeCard
