import { Button, Col, Modal, Row, message, Dropdown, Alert, Tabs } from 'gokwik-ui-kit'
import RulesTable from './rulesTable'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import 'gokwik-ui-kit/dist/esm/bundle.css'
import PaymentRuleForm from './paymentRuleForm'
import { RuleTypes } from './ruleConstant'
import ShippingRuleForm from './shippingRuleForm'
import { CloseReviewModal } from '../helpers/CloseReviewModal'
import { IFeaturesEvents } from '@library/utilities/helpers/eventsHelper'
import SaveBar from '@library/components/save-bar'

const ShippingAndPaymentCustomizations = (props: { events: IFeaturesEvents }) => {
    const {
        shippingCustomisationsEvents: shippingCustomisationEvents,
        paymentCustomisationsEvents: paymentCustomisationEvents,
        reviewFlowEvents,
    } = props.events
    const navigate = useNavigate()
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [paymentRules, setPaymentRules] = useState([])
    const [shippingRules, setShippingRules] = useState([])
    const [shippingConfig, setShippingConfig] = useState([])
    const [currentType, setCurrentType] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [action, setAction] = useState({ name: null, id: null })
    const [modalTitle, setModalTitle] = useState('')
    const [currentRule, setCurrentRule] = useState({})
    const [showAlert, setShowAlert] = useState(false)
    const [isConfigSaved, setIsConfigSaved] = useState(false)
    const [isNonPlusMerchant, setIsNonPlusMerchant] = useState(true)
    const pendingChangesRef = useRef({ payment: [], shipping: [] })
    const maxAllowedRules = 5
    const [activeTab, setActiveTab] = useState('shipping') // New state to track active tab

    const handleTabClick = (tab: string) => {
        setActiveTab(tab)
    }
    const fetchPaymentAndShippingRules = async () => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
            })

            if (response?.data?.status_code === 200) {
                setShippingRules(response?.data?.data?.customShippingConditions)
                setShippingConfig(response?.data?.data?.shippingConfig)
                setPaymentRules(response?.data?.data?.paymentCustomizationConfig)
                setIsNonPlusMerchant(response?.data?.data?.isNonPlusMerchant ?? true)
            }
        } catch (error) {
            console.error('Error:', error)
            message.error('failed to fetch rules data')
        }
    }

    useEffect(() => {
        fetchPaymentAndShippingRules()
        shippingCustomisationEvents.fireClickedEvent()
        paymentCustomisationEvents.fireClickedEvent()
    }, [])

    const handleDeleteRule = async (type, rule) => {
        let data
        if (type == RuleTypes.PAYMENT) {
            data = {
                paymentCustomizationConfig: [
                    {
                        id: rule?.id,
                        isDeleted: true,
                    },
                ],
            }
            paymentCustomisationEvents.configsUpsert.delete.fireClickedEvent()
        } else if (type == RuleTypes.SHIPPING) {
            data = {
                customShippingConditions: [
                    {
                        id: rule?.id,
                        isDeleted: true,
                    },
                ],
            }
            shippingCustomisationEvents.configsUpsert.delete.fireClickedEvent()
        }
        const errMsg = `failed to delete ${type} rule `
        const successMsg = `${type} rule deleted successfully`
        let successEventFunction = () => {
            shippingCustomisationEvents.configsUpsert.delete.fireSuccessEvent({
                deletedConfig: shippingRules.find(
                    (shippingRuleData) => shippingRuleData.id == data.customShippingConditions[0].id,
                ),
            })
        }
        let failureEventFunction = (errMessage: string) => {
            shippingCustomisationEvents.configsUpsert.delete.fireFailureEvent({
                deletedConfig: shippingRules.find(
                    (shippingRuleData) => shippingRuleData.id == data.customShippingConditions[0].id,
                ),
                errorMessage: errMessage,
            })
        }

        if (type === RuleTypes.PAYMENT) {
            successEventFunction = () => {
                paymentCustomisationEvents.configsUpsert.delete.fireSuccessEvent({
                    deletedConfig: paymentRules.find(
                        (paymentRuleData) => paymentRuleData.id == data.paymentCustomizationConfig[0].id,
                    ),
                })
            }
            failureEventFunction = (errMessage: string) => {
                paymentCustomisationEvents.configsUpsert.delete.fireFailureEvent({
                    deletedConfig: paymentRules.find(
                        (paymentRuleData) => paymentRuleData.id == data.paymentCustomizationConfig[0].id,
                    ),
                    errorMessage: errMessage,
                })
            }
        }

        updateRuleConfig(data, successMsg, errMsg, [successEventFunction], [failureEventFunction])
    }

    const updatePendingChanges = (type, newChange) => {
        let changes = pendingChangesRef.current[type.toLowerCase()]
        const existingChangeIndex = changes.findIndex((change) => change.id === newChange.id)

        if (existingChangeIndex !== -1) {
            changes[existingChangeIndex].enabled = newChange.enabled
        } else {
            changes.push(newChange)
        }
        pendingChangesRef.current[type.toLowerCase()] = changes
    }

    const handleStatusChange = (type, rule) => {
        const newChange = { id: rule.id, enabled: !rule.enabled }

        if (type === RuleTypes.PAYMENT) {
            updatePendingChanges('payment', newChange)
            setPaymentRules((prev) =>
                prev.map((paymentRule) =>
                    paymentRule.id === rule.id ? { ...paymentRule, enabled: !paymentRule.enabled } : paymentRule,
                ),
            )
        } else if (type === RuleTypes.SHIPPING) {
            updatePendingChanges('shipping', newChange)
            setShippingRules((prev) =>
                prev.map((shippingRule) =>
                    shippingRule.id === rule.id ? { ...shippingRule, enabled: !shippingRule.enabled } : shippingRule,
                ),
            )
        }
        setUnsavedChanges(true)
    }

    const handleActionButtonClick = (ruleType: string, actionName, rule) => {
        setAction({ name: actionName, id: rule?.id })
        setCurrentType(ruleType)
        setCurrentRule(rule)

        if (actionName === 'edit') {
            if (ruleType === RuleTypes.PAYMENT) {
                paymentCustomisationEvents.configsUpsert.edit.fireClickedEvent()
            } else if (ruleType === RuleTypes.SHIPPING) {
                shippingCustomisationEvents.configsUpsert.edit.fireClickedEvent()
            }
            setModalTitle(`Edit ${ruleType} Rule`)
        } else if (actionName === 'view') {
            setModalTitle(`${ruleType} Rule`)
        } else if (actionName === 'add') {
            if (ruleType === RuleTypes.PAYMENT) {
                paymentCustomisationEvents.configsUpsert.add.fireClickedEvent()
            } else if (ruleType === RuleTypes.SHIPPING) {
                shippingCustomisationEvents.configsUpsert.add.fireClickedEvent()
            }
            setModalTitle(`Add ${ruleType} Rule`)
        }
        setShowModal(true)
    }

    const updateRuleConfig = async (data, successMsg, errMsg, successEvents = null, failureEvents = null) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyShippingAndPaymentRule,
                payload: data,
            })
            if (response.data.status_code === 200) {
                message.success(successMsg)
                fetchPaymentAndShippingRules()
                setIsConfigSaved(true)
                if (Array.isArray(successEvents)) {
                    successEvents.forEach((successEvent) => {
                        if (typeof successEvent === 'function') {
                            successEvent()
                        }
                    })
                }
            } else {
                if (Array.isArray(failureEvents)) {
                    failureEvents.forEach((failureEvent) => {
                        if (typeof failureEvent === 'function') {
                            failureEvent(
                                'Customisations API Failed with Status Code ' + response.data.status_code?.toString(),
                            )
                        }
                    })
                }
            }
        } catch (error) {
            console.error('Error:', error)
            message.error(errMsg)
            if (Array.isArray(failureEvents)) {
                failureEvents.forEach((failureEvent) => {
                    if (typeof failureEvent === 'function') {
                        failureEvent(error?.message || 'Failed to update configs')
                    }
                })
            }
        }
    }

    const handleSave = () => {
        const data: {
            paymentCustomizationConfig?: { id: number; enabled: boolean }[]
            customShippingConditions?: { id: number; enabled: boolean }[]
        } = {}
        let successEvent = []
        let failureEvents = []
        if (pendingChangesRef.current.payment.length > 0) {
            data.paymentCustomizationConfig = pendingChangesRef.current.payment
            successEvent.push(() => {
                paymentCustomisationEvents.configsUpsert.edit.fireSuccessEvent({
                    currentCnfig: data.paymentCustomizationConfig,
                })
            })
            failureEvents.push((errMessage: string) => {
                paymentCustomisationEvents.configsUpsert.edit.fireFailureEvent({
                    currentCnfig: data.paymentCustomizationConfig,
                    errorMessage: errMessage,
                })
            })
        }
        if (pendingChangesRef.current.shipping.length > 0) {
            data.customShippingConditions = pendingChangesRef.current.shipping
            successEvent.push(() => {
                shippingCustomisationEvents.configsUpsert.edit.fireSuccessEvent({
                    currentCnfig: data.customShippingConditions,
                })
            })
            failureEvents.push((errMessage: string) => {
                shippingCustomisationEvents.configsUpsert.edit.fireFailureEvent({
                    currentCnfig: data.customShippingConditions,
                    errorMessage: errMessage,
                })
            })
        }
        if (Object.keys(data).length) {
            updateRuleConfig(data, 'Changes saved successfully', 'Failed to save changes', successEvent, failureEvents)
        }
        pendingChangesRef.current = { payment: [], shipping: [] }
        setUnsavedChanges(false)
    }

    const handleDiscard = () => {
        pendingChangesRef.current = { payment: [], shipping: [] }
        shippingCustomisationEvents.configsUpsert.discardSave()
        paymentCustomisationEvents.configsUpsert.discardSave()
        fetchPaymentAndShippingRules()
        setUnsavedChanges(false)
    }

    const menuItems = [
        {
            key: RuleTypes.SHIPPING,
            label: 'Shipping Rule',
            disabled: shippingRules?.length >= maxAllowedRules,
        },
        {
            key: RuleTypes.PAYMENT,
            label: 'Payment Rule',
            disabled: paymentRules?.length >= maxAllowedRules,
        },
    ]

    const onClick = (e) => {
        handleActionButtonClick(e.key, 'add', null)
    }

    const items = [
        {
            label: 'Shipping',
            key: 'shipping',
            children: (
                <RulesTable
                    title='Shipping'
                    rules={shippingRules}
                    type='Shipping'
                    onView={(rule) => handleActionButtonClick('Shipping', 'view', rule)}
                    onEdit={(rule) => handleActionButtonClick('Shipping', 'edit', rule)}
                    onDelete={(rule) => handleDeleteRule('Shipping', rule)}
                    onStatusChange={(rule) => handleStatusChange('Shipping', rule)}
                />
            ),
        },
        {
            label: 'Payment',
            key: 'payment',
            children: (
                <RulesTable
                    title='Payment'
                    rules={paymentRules}
                    type='Payment'
                    onView={(rule) => handleActionButtonClick('Payment', 'view', rule)}
                    onEdit={(rule) => handleActionButtonClick('Payment', 'edit', rule)}
                    onDelete={(rule) => handleDeleteRule('Payment', rule)}
                    onStatusChange={(rule) => handleStatusChange('Payment', rule)}
                />
            ),
        },
    ]

    const checkMaxRulesCondition = () => {
        if (paymentRules?.length >= maxAllowedRules || shippingRules?.length >= maxAllowedRules) {
            setShowAlert(true)

            const timer = setTimeout(() => {
                setShowAlert(false)
            }, 3000)

            return () => clearTimeout(timer)
        }
    }

    return (
        <div className='w-full p-2 flex flex-col gap-4'>
            {unsavedChanges && <SaveBar handleSave={handleSave} handleDiscard={handleDiscard} />}
            {showModal && (
                <Modal
                    title={modalTitle}
                    footer={null}
                    onCancel={() => setShowModal(false)}
                    open={showModal}
                    centered
                    width={'700px'}
                >
                    {currentType === RuleTypes.PAYMENT && (
                        <PaymentRuleForm
                            setShowModal={setShowModal}
                            ruleInfo={currentRule}
                            userAction={action}
                            fetchConfig={fetchPaymentAndShippingRules}
                            setIsConfigSaved={setIsConfigSaved}
                            events={paymentCustomisationEvents}
                            isNonPlusMerchant={isNonPlusMerchant}
                        />
                    )}
                    {currentType === RuleTypes.SHIPPING && (
                        <ShippingRuleForm
                            setShowModal={setShowModal}
                            ruleInfo={currentRule}
                            userAction={action}
                            fetchConfig={fetchPaymentAndShippingRules}
                            setIsConfigSaved={setIsConfigSaved}
                            events={shippingCustomisationEvents}
                        />
                    )}
                </Modal>
            )}
            <Row className='rounded-sm p-2 ' gutter={[0, 24]} justify={'space-between'}>
                <Col span={16}>
                    <div className='flex items-top'>
                        <div>
                            <p className='text-lg font-bold p-2'>
                                Shipping & Payment Rules
                                <p className='text-[#00000080] font-normal text-sm pt-1'>
                                    Set rules to hide, reorder, or rename shipping and payment options based on cart,
                                    product, location, or collection
                                </p>
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            {showAlert && (
                <Alert
                    message={`You're limited to add maximum ${maxAllowedRules} rules of each type`}
                    type='info'
                    showIcon
                    style={{ textAlign: 'center' }}
                />
            )}

            <div className='border border-gray-500 rounded-md p-4'>
                <Tabs
                    activeKey={activeTab}
                    onChange={setActiveTab}
                    items={items}
                    tabBarExtraContent={
                        <Dropdown menu={{ items: menuItems, onClick: onClick }} trigger={['click']}>
                            <Button id='add-new-rule' variant='primary' onClick={checkMaxRulesCondition}>
                                Add New Rule
                            </Button>
                        </Dropdown>
                    }
                />
            </div>
            {isConfigSaved ? <CloseReviewModal setIsConfigSaved={setIsConfigSaved} events={reviewFlowEvents} /> : ''}
        </div>
    )
}

export default ShippingAndPaymentCustomizations
