import { getCookie, updateBreadcrumbs } from '@gokwik/utilities'
import { ArrowLeftOutlined, Button, Col, InfoCircleOutlined, Row, Tooltip, message } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import { IShopifyAppPPCODConfig } from '@library/utilities/interface'
import PpcodConfigForm, { formatPpcodConfig, validatePppcodConfig } from './ppcodConfigForm'
import { FloatingTourIcon, RenderTour } from '@pages/settings/tour/tour'
import { setCookie } from '@library/utilities/helpers/cookieHelper'
import { ppcodFeatureTourSteps } from '@pages/settings/tour/tourSteps'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

const PpcodConfig = (props: { events: ICommonEvents }) => {
    const navigate = useNavigate()
    const [showTour, setShowTour] = useState(false)
    const { tourStepsEvents, configsUpsert } = props.events
    const { config, saveConfig } = useShopifyMerchantConfigApi({
        useCache: true,
        fireSuccessEvent: configsUpsert.edit.fireSuccessEvent,
        fireFailureEvent: configsUpsert.edit.fireFailureEvent,
    })
    const paymentMethods = config?.paymentMethods ?? []
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)
    const [updatedPpcodConfig, setUpdatedPpcodConfig] = useState<IShopifyAppPPCODConfig>({
        baseCaseEnabled: false,
        additionalFeatureFlags: [],
        riskFlags: [],
        tieredAmounts: [],
    })

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/shopify-app/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'shopify-app-payments',
                href: '/shopify-app/settings/shopify-app-payments',
                text: 'Payments',
            },
            {
                key: 'discount-suggestions',
                href: '/shopify-app/settings/shopify-app-payments/ppcod',
                text: 'Partial Payment COD',
            },
        ])

        checkTourCookies()
    }, [])

    const checkTourCookies = () => {
        const isTourVisited = getCookie('ppcodTour')
        if (!isTourVisited) {
            setShowTour(true)
        }
    }

    const handleDiscardChanges = () => {
        setUpdatedPpcodConfig(JSON.parse(JSON.stringify(config?.ppcodConfig || {})))
        setUnsavedChanges(false)
        configsUpsert.discardSave()
    }

    const handleSaveChanges = () => {
        const formattedConfig = formatPpcodConfig(updatedPpcodConfig)
        const validation = validatePppcodConfig(formattedConfig)
        if (validation.status) {
            updateConfiguration(formattedConfig)
        } else {
            message.error(validation.msg)
        }
    }

    const updateConfiguration = (formattedConfig) => {
        const isPpcodPaymentMethodAdded = paymentMethods?.some((method) => method.paymentMethodId === 'ppcod-upi')

        saveConfig(
            {
                ppcodConfig: formattedConfig,
                ...(!isPpcodPaymentMethodAdded && {
                    paymentMethods: [...paymentMethods, { paymentMethodId: 'ppcod-upi' }],
                }),
            },
            () => setUnsavedChanges(false),
        )
    }

    useEffect(() => {
        setUpdatedPpcodConfig(JSON.parse(JSON.stringify(config?.ppcodConfig || {})))
    }, [config?.ppcodConfig])

    function startTour() {
        setShowTour(true)
        tourStepsEvents.fireStartedEvent()
    }

    const closeTour = () => {
        setShowTour(false)
        setCookie('ppcodTour', true, 365)
        tourStepsEvents.fireSkippedEvent()
    }

    const finishTour = () => {
        setShowTour(false)
        setCookie('ppcodTour', true, 365)
        tourStepsEvents.fireCompletedEvent()
    }

    const handleTourStepChange = (current: number) => {
        if (current === ppcodFeatureTourSteps.length - 1) {
            closeTour()
        } else {
            tourStepsEvents.fireNextStepEvent({
                stepTitle: ppcodFeatureTourSteps[current].title,
            })
        }
    }

    return (
        <div className=' items-center'>
            <Row className='rounded-sm p-2' gutter={[0, 24]} justify={'space-between'}>
                <Col span={24}>
                    <div className='flex items-top'>
                        <Button className='bg-gray-200 border-none' onClick={() => navigate(-1)}>
                            <ArrowLeftOutlined />
                        </Button>
                        <div className='ml-2'>
                            <p className='text-base font-semibold'>Partial Payment COD (PPCOD)</p>
                            <p>
                                PPCOD enables merchants to request a partial payment of the total cash on delivery (CoD)
                                order upfront
                            </p>
                        </div>
                    </div>
                </Col>

                <Col>
                    {unsavedChanges && (
                        <span className='flex justify-center gap-3 globalSave p-2 rounded-lg z-50'>
                            <div className='flex items-center gap-1 text-white'>
                                <Tooltip>
                                    <InfoCircleOutlined className='ml-2 mr-2' />{' '}
                                </Tooltip>
                                <p className='text-white text-base font-normal'>Unsaved Changes</p>
                            </div>
                            <div className='flex gap-3'>
                                <Button type='default' onClick={handleDiscardChanges}>
                                    Discard
                                </Button>
                                <Button type='primary' onClick={handleSaveChanges} className='bg-green-500 text-white'>
                                    Save
                                </Button>
                            </div>
                        </span>
                    )}
                </Col>

                <Col span={24}>
                    <PpcodConfigForm
                        config={config}
                        ppcodConfig={updatedPpcodConfig}
                        setPpcodConfig={setUpdatedPpcodConfig}
                        setIsUpdated={setUnsavedChanges}
                        tourInProgress={showTour}
                        ppcodEvents={props.events}
                    />
                </Col>
            </Row>
            <RenderTour
                showTour={showTour}
                steps={ppcodFeatureTourSteps}
                onClose={closeTour}
                onChange={handleTourStepChange}
                onFinish={finishTour}
            />
            <FloatingTourIcon onClick={startTour} />
        </div>
    )
}

export default PpcodConfig
