import { logEvent } from "../userLogEvents/userLogEvents";

enum EventTypesEnum {
    click = 'click',
    toggle = 'toggle',
    add = 'add_config',
    edit = 'edit_config',
    delete = 'delete_config',
    success = 'success',
    failure = 'failure'
}

interface EventsTypeInternal {
    clicked: string;
    success: string;
    failure: string;
}

export interface EventsFunctionInternal {
    fireClickedEvent: (data?: Record<string, any>) => void;
    fireSuccessEvent: (data?: Record<string, any>) => void;
    fireFailureEvent: (data?: Record<string, any>) => void;
}

interface EventsTourInternal {
    fireStartedEvent: (data?: Record<string, any>) => void;
    fireNextStepEvent: (data?: Record<string, any>) => void;
    fireSkippedEvent: (data?: Record<string, any>) => void;
    fireCompletedEvent: (data?: Record<string, any>) => void;
    fireGuideOpenedEvent: (data?: Record<string, any>) => void
}

interface EventsConfigUpsertInternal {
    add: EventsTypeInternal;
    edit: EventsTypeInternal;
    delete: EventsTypeInternal;
    discardSave: string;
}

interface EventsConfigUpsertFunctionInternal {
    add :EventsFunctionInternal;
    edit: EventsFunctionInternal;
    delete: EventsFunctionInternal;
    discardSave: (data?: Record<string, any>) => void;
}

interface ITourEvents {
    tourStepsEvents: EventsTourInternal
}

interface ITourEventNames {
    tourSteps: {
        started: string;
        nextStep: string;
        skipped: string;
        completed: string;
        guideOpened: string
    }
}

interface EventNamesEnumType {
    settingsPage: Partial<EventsTypeInternal> & {
        clicked: string,
        upgradePlanWidgetClicked: string,
        upgradeWidgetExpanded: string,
        upgradeWidgetCollapsed: string,
        upgradePlanClicked: string
    }
    pincodeServiceability: ITourEventNames & {
        clicked: string,
        downloadCSV: EventsTypeInternal & { downloadSample: string };
        uploadCSV: EventsTypeInternal;
        toggleConfig: EventsTypeInternal;
        onboardingStatusUpdate: Partial<EventsTypeInternal>;
    }
    shippingServiceability: ITourEventNames & {
        clicked: string;
        onboardingPrefill: Partial<EventsTypeInternal>;
        configsUpsert: EventsConfigUpsertInternal;
        onboardingStatusUpdate: Partial<EventsTypeInternal>;
    }
    customDiscounts: ITourEventNames & {
        clicked: string;
        configsUpsert: EventsConfigUpsertInternal
    }
    codFeatures: ITourEventNames & {
        clicked: string;
        upgradePlan: string;
    }
    codConfigs: ITourEventNames & {
        clicked: string;
        configsUpsert: EventsConfigUpsertInternal
    }
    paymentCustomisations: ITourEventNames & {
        clicked: string;
        configsUpsert: EventsConfigUpsertInternal
    }
    shippingCustomisations: ITourEventNames & {
        clicked: string;
        configsUpsert: EventsConfigUpsertInternal
    }
    paymentFeatures: ITourEventNames & Partial<EventsTypeInternal>
    ppcod: ITourEventNames & {
        clicked: string;
        configsUpsert: EventsConfigUpsertInternal
    }
    prepaidDiscounts: ITourEventNames & {
        clicked: string;
        configsUpsert: EventsConfigUpsertInternal
    }
    uiCustomisations: ITourEventNames & {
        clicked: string;
        configsUpsert: EventsConfigUpsertInternal
    }
    loginUICustomisations: ITourEventNames & {
        clicked: string;
        configsUpsert: EventsConfigUpsertInternal
    }
    discountSuggestions: ITourEventNames & {
        clicked: string;
        configsUpsert: EventsConfigUpsertInternal
    }
    reviewFlow: EventsTypeInternal
}

export interface IPincodeServiceabilityEvents extends ITourEvents {
    fireClickedEvent: (data?: Record<string, any>) => void;
    downloadCSVEvents: EventsFunctionInternal & { fireDownloadSamplePincodesEvent: Function };
    uploadCSVEvents: EventsFunctionInternal;
    toggleConfigEvents: EventsFunctionInternal;
    onboardingStatusUpdate: Partial<EventsFunctionInternal>;
}

export interface IShippingServiceabilityEvents extends ICommonEvents, ITourEvents {
    onboardingPrefill: Partial<EventsFunctionInternal>
    onboardingStatusUpdate: Partial<EventsFunctionInternal>;
}

export interface IMenuPageEvents extends ITourEvents {
    fireClickedEvent: (data?: Record<string, any>) => void;
}

export interface ICommonEvents extends ITourEvents {
    fireClickedEvent: (data?: Record<string, any>) => void;
    configsUpsert: {
        add: EventsFunctionInternal;
        edit: EventsFunctionInternal;
        delete: EventsFunctionInternal;
        discardSave: (data?: Record<string, any>) => void;
    };
}

enum FeaturesEnum {
    settingsPage = 'settingsPage',
    pincodeServiceability = 'pincodeServiceability',
    shippingServiceability = 'shippingServiceability',
    customDiscounts = 'customDiscounts',
    codFeatures = 'codFeatures',
    codConfigs = 'codConfigs',
    paymentCustomisations = 'paymentCustomisations',
    shippingCustomisations = 'shippingCustomisations',
    paymentFeatures = 'paymentFeatures',
    ppcod = 'ppcod',
    prepaidDiscounts = 'prepaidDiscounts',
    uiCustomisations = 'uiCustomisations',
    loginUICustomisations = 'loginUICustomisations',
    discountSuggestions = 'discountSuggestions',
    reviewFlow = 'reviewFlow',
}

export interface IFeaturesEvents {
    settingsPageEvents: Partial<EventsFunctionInternal> & {
        fireWidgetClicked: (data?: Record<string, any>) => void
        fireUpgradeWidgetExpanded: (data?: Record<string, any>) => void
        fireUpgradeWidgetCollapsed: (data?: Record<string, any>) => void
        fireUpgradePlanEvent: (data?: Record<string, any>) => void
    }
    pincodeServiceabilityEvents: IPincodeServiceabilityEvents
    shippingServiceabilityEvents: IShippingServiceabilityEvents
    customDiscountsEvents: ICommonEvents
    codFeaturesEvents: IMenuPageEvents & { fireUpgradePlanEvent: (data?: Record<string, any>) => void }
    codConfigsEvents: ICommonEvents
    paymentCustomisationsEvents: ICommonEvents
    shippingCustomisationsEvents: ICommonEvents
    paymentFeaturesEvents: IMenuPageEvents
    ppcodEvents: ICommonEvents
    prepaidDiscountsEvents: ICommonEvents
    uiCustomisationsEvents: ICommonEvents
    loginUICustomisationsEvents: ICommonEvents
    discountSuggestionsEvents: ICommonEvents
    reviewFlowEvents: EventsFunctionInternal
}

class EventsUtils {
    private merchantDetails: any;
    private userDetails: any;
    protected eventNamesEnum: EventNamesEnumType;
    constructor(merchantDetails: any, userDetails: any) {
        this.merchantDetails = merchantDetails;
        this.userDetails = userDetails;
        this.eventNamesEnum = {
            settingsPage: {
                clicked: 'kwik_checkout_settings_clicked',
                upgradePlanWidgetClicked: 'upgrade_widget_clicked',
                upgradeWidgetExpanded: 'upgrade_widget_expanded',
                upgradeWidgetCollapsed: 'upgrade_widget_collapsed',
                upgradePlanClicked: 'upgrade_plan_clicked'
            },
            pincodeServiceability: {
                clicked: 'pincode_serviceability_clicked',
                downloadCSV: {
                    clicked: 'download_pincodes_clicked',
                    success: 'download_pincodes_success',
                    failure: 'download_pincodes_failure',
                    downloadSample: 'download_sample_pincodes_clicked'
                },
                uploadCSV: {
                    clicked: 'upload_pincodes_clicked',
                    success: 'upload_pincodes_success',
                    failure: 'upload_pincodes_failure',
                },
                toggleConfig: {
                    clicked: 'toggle_pincode_serviceability_clicked',
                    success: 'toggle_pincode_serviceability_success',
                    failure: 'toggle_pincode_serviceability_failure',
                },
                tourSteps: {
                    started: 'pincode_serviceability_tour_started',
                    nextStep: 'pincode_serviceability_tour_next_step_clicked',
                    skipped: 'pincode_serviceability_tour_skipped',
                    completed: 'pincode_serviceability_tour_completed',
                    guideOpened: 'pincode_serviceability_guide_opened',
                },
                onboardingStatusUpdate: {
                    success: 'pincode_serviceability_onboarding_status_update_successful',
                    failure: 'pincode_serviceability_onboarding_status_update_failure'
                }
            },
            shippingServiceability: {
                clicked: 'shipping_serviceability_clicked',
                onboardingPrefill: {
                    success: 'shipping_serviceability_onboarding_prefill_success',
                    failure: 'shipping_serviceability_onboarding_prefill_failure'
                },
                configsUpsert: {
                    add: {
                        clicked: 'shipping_serviceability_add_clicked',
                        success: 'shipping_serviceability_add_success',
                        failure: 'shipping_serviceability_add_failure',
                    },
                    edit: {
                        clicked: 'shipping_serviceability_edit_clicked',
                        success: 'shipping_serviceability_edit_success',
                        failure: 'shipping_serviceability_edit_failure',
                    },
                    delete: {
                        clicked: 'shipping_serviceability_delete_clicked',
                        success: 'shipping_serviceability_delete_success',
                        failure: 'shipping_serviceability_delete_failure',
                    },
                    discardSave: 'shipping_serviceability_discard_clicked'
                },
                tourSteps: {
                    started: 'shipping_serviceability_tour_started',
                    nextStep: 'shipping_serviceability_tour_next_step_clicked',
                    skipped: 'shipping_serviceability_tour_skipped',
                    completed: 'shipping_serviceability_tour_completed',
                    guideOpened: 'shipping_serviceability_guide_opened',

                },
                onboardingStatusUpdate: {
                    success: 'shipping_serviceability_onboarding_status_update_successful',
                    failure: 'shipping_serviceability_onboarding_status_update_failure'
                }
            },
            customDiscounts: {
                clicked: 'custom_discounts_clicked',
                configsUpsert: {
                    add: {
                        clicked: 'custom_discounts_add_clicked',
                        success: 'custom_discounts_add_success',
                        failure: 'custom_discounts_add_failure',
                    },
                    edit: {
                        clicked: 'custom_discounts_edit_clicked',
                        success: 'custom_discounts_edit_success',
                        failure: 'custom_discounts_edit_failure',
                    },
                    delete: {
                        clicked: 'custom_discounts_delete_clicked',
                        success: 'custom_discounts_delete_success',
                        failure: 'custom_discounts_delete_failure',
                    },
                    discardSave: 'custom_discounts_discard_clicked'

                },
                tourSteps: {
                    started: 'custom_discounts_tour_started',
                    nextStep: 'custom_discounts_tour_next_step_clicked',
                    skipped: 'custom_discounts_tour_skipped',
                    completed: 'custom_discounts_tour_completed',
                    guideOpened: 'custom_discounts_guide_opened',
                }
            },
            codFeatures: {
                clicked: 'cod_features_clicked',
                tourSteps: {
                    started: 'cod_features_tour_started',
                    nextStep: 'cod_features_tour_next_step_clicked',
                    skipped: 'cod_features_tour_skipped',
                    completed: 'cod_features_tour_completed',
                    guideOpened: 'cod_features_guide_opened',
                },
                upgradePlan: 'upgrade_plan_section_clicked',
            },
            codConfigs: {
                clicked: 'cod_configs_clicked',
                configsUpsert: {
                    add: {
                        clicked: 'cod_configs_add_clicked',
                        success: 'cod_configs_add_success',
                        failure: 'cod_configs_add_failure',
                    },
                    edit: {
                        clicked: 'cod_configs_edit_clicked',
                        success: 'cod_configs_edit_success',
                        failure: 'cod_configs_edit_failure',
                    },
                    delete: {
                        clicked: 'cod_configs_delete_clicked',
                        success: 'cod_configs_delete_success',
                        failure: 'cod_configs_delete_failure',
                    },
                    discardSave: 'cod_configs_discard_clicked'

                },
                tourSteps: {
                    started: 'cod_configs_tour_started',
                    nextStep: 'cod_configs_tour_next_step_clicked',
                    skipped: 'cod_configs_tour_skipped',
                    completed: 'cod_configs_tour_completed',
                    guideOpened: 'cod_configs_guide_opened',
                }
            },
            paymentCustomisations: {
                clicked: 'payment_customisations_clicked',
                configsUpsert: {
                    add: {
                        clicked: 'payment_customisations_add_clicked',
                        success: 'payment_customisations_add_success',
                        failure: 'payment_customisations_add_failure',
                    },
                    edit: {
                        clicked: 'payment_customisations_edit_clicked',
                        success: 'payment_customisations_edit_success',
                        failure: 'payment_customisations_edit_failure',
                    },
                    delete: {
                        clicked: 'payment_customisations_delete_clicked',
                        success: 'payment_customisations_delete_success',
                        failure: 'payment_customisations_delete_failure',
                    },
                    discardSave: 'payment_customisations_discard_clicked'
                },
                tourSteps: {
                    started: 'payment_customisations_tour_started',
                    nextStep: 'payment_customisations_tour_next_step_clicked',
                    skipped: 'payment_customisations_tour_skipped',
                    completed: 'payment_customisations_tour_completed',
                    guideOpened: 'payment_customisations_guide_opened',
                }
            },
            shippingCustomisations: {
                clicked: 'shipping_customisations_clicked',
                configsUpsert: {
                    add: {
                        clicked: 'shipping_customisations_add_clicked',
                        success: 'shipping_customisations_add_success',
                        failure: 'shipping_customisations_add_failure',
                    },
                    edit: {
                        clicked: 'shipping_customisations_edit_clicked',
                        success: 'shipping_customisations_edit_success',
                        failure: 'shipping_customisations_edit_failure',
                    },
                    delete: {
                        clicked: 'shipping_customisations_delete_clicked',
                        success: 'shipping_customisations_delete_success',
                        failure: 'shipping_customisations_delete_failure',
                    },
                    discardSave: 'shipping_customisations_discard_clicked'
                },
                tourSteps: {
                    started: 'shipping_customisations_tour_started',
                    nextStep: 'shipping_customisations_tour_next_step_clicked',
                    skipped: 'shipping_customisations_tour_skipped',
                    completed: 'shipping_customisations_tour_completed',
                    guideOpened: 'shipping_customisations_guide_opened',
                }
            },
            paymentFeatures: {
                clicked: 'payment_features_clicked',
                tourSteps: {
                    started: 'payment_features_tour_started',
                    nextStep: 'payment_features_tour_next_step_clicked',
                    skipped: 'payment_features_tour_skipped',
                    completed: 'payment_features_tour_completed',
                    guideOpened: 'payment_features_guide_opened',
                }
            },
            ppcod: {
                clicked: 'ppcod_page_clicked',
                configsUpsert: {
                    add: {
                        clicked: 'ppcod_config_add_clicked',
                        success: 'ppcod_config_add_success',
                        failure: 'ppcod_config_add_failure',
                    },
                    edit: {
                        clicked: 'ppcod_config_edit_clicked',
                        success: 'ppcod_config_edit_success',
                        failure: 'ppcod_config_edit_failure',
                    },
                    delete: {
                        clicked: 'ppcod_config_delete_clicked',
                        success: 'ppcod_config_delete_success',
                        failure: 'ppcod_config_delete_failure',
                    },
                    discardSave: 'ppcod_config_discard_clicked'
                },
                tourSteps: {
                    started: 'ppcod_page_tour_started',
                    nextStep: 'ppcod_page_tour_next_step_clicked',
                    skipped: 'ppcod_page_tour_skipped',
                    completed: 'ppcod_page_tour_completed',
                    guideOpened: 'ppcod_page_guide_opened',
                },
            },
            prepaidDiscounts: {
                clicked: 'prepaid_discounts_config_clicked',
                configsUpsert: {
                    add: {
                        clicked: 'prepaid_discounts_config_add_clicked',
                        success: 'prepaid_discounts_config_add_success',
                        failure: 'prepaid_discounts_config_add_failure',
                    },
                    edit: {
                        clicked: 'prepaid_discounts_config_edit_clicked',
                        success: 'prepaid_discounts_config_edit_success',
                        failure: 'prepaid_discounts_config_edit_failure',
                    },
                    delete: {
                        clicked: 'prepaid_discounts_config_delete_clicked',
                        success: 'prepaid_discounts_config_delete_success',
                        failure: 'prepaid_discounts_config_delete_failure',
                    },
                    discardSave: 'prepaid_discounts_discard_clicked'
                },
                tourSteps: {
                    started: 'prepaid_discounts_tour_started',
                    nextStep: 'prepaid_discounts_tour_next_step_clicked',
                    skipped: 'prepaid_discounts_tour_skipped',
                    completed: 'prepaid_discounts_tour_completed',
                    guideOpened: 'prepaid_discounts_guide_opened',
                }
            },
            uiCustomisations: {
                clicked: 'ui_customisations_clicked',
                configsUpsert: {
                    add: {
                        clicked: 'ui_customisations_add_clicked',
                        success: 'ui_customisations_add_success',
                        failure: 'ui_customisations_add_failure',
                    },
                    edit: {
                        clicked: 'ui_customisations_edit_clicked',
                        success: 'ui_customisations_edit_success',
                        failure: 'ui_customisations_edit_failure',
                    },
                    delete: {
                        clicked: 'ui_customisations_delete_clicked',
                        success: 'ui_customisations_delete_success',
                        failure: 'ui_customisations_delete_failure',
                    },
                    discardSave: 'ui_customisations_discard_clicked'
                },
                tourSteps: {
                    started: 'ui_customisations_tour_started',
                    nextStep: 'ui_customisations_tour_next_step_clicked',
                    skipped: 'ui_customisations_tour_skipped',
                    completed: 'ui_customisations_tour_completed',
                    guideOpened: 'ui_customisations_guide_opened',
                }
            },
            loginUICustomisations: {
                clicked: 'login_ui_customisations_clicked',
                configsUpsert: {
                    add: {
                        clicked: 'login_ui_customisations_add_clicked',
                        success: 'login_ui_customisations_add_success',
                        failure: 'login_ui_customisations_add_failure',
                    },
                    edit: {
                        clicked: 'login_ui_customisations_edit_clicked',
                        success: 'login_ui_customisations_edit_success',
                        failure: 'login_ui_customisations_edit_failure',
                    },
                    delete: {
                        clicked: 'login_ui_customisations_delete_clicked',
                        success: 'login_ui_customisations_delete_success',
                        failure: 'login_ui_customisations_delete_failure',
                    },
                    discardSave: 'login_ui_customisations_discard_clicked'
                },
                tourSteps: {
                    started: 'login_ui_customisations_tour_started',
                    nextStep: 'login_ui_customisations_tour_next_step_clicked',
                    skipped: 'login_ui_customisations_tour_skipped',
                    completed: 'login_ui_customisations_tour_completed',
                    guideOpened: 'login_ui_customisations_guide_opened',
                }
            },
            discountSuggestions: {
                clicked: 'discount_suggestions_clicked',
                configsUpsert: {
                    add: {
                        clicked: 'discount_suggestions_add_clicked',
                        success: 'discount_suggestions_add_success',
                        failure: 'discount_suggestions_add_failure',
                    },
                    edit: {
                        clicked: 'discount_suggestions_edit_clicked',
                        success: 'discount_suggestions_edit_success',
                        failure: 'discount_suggestions_edit_failure',
                    },
                    delete: {
                        clicked: 'discount_suggestions_delete_clicked',
                        success: 'discount_suggestions_delete_success',
                        failure: 'discount_suggestions_delete_failure',
                    },
                    discardSave: 'discount_suggestions_discard_clicked'
                },
                tourSteps: {
                    started: 'discount_suggestions_tour_started',
                    nextStep: 'discount_suggestions_tour_next_step_clicked',
                    skipped: 'discount_suggestions_tour_skipped',
                    completed: 'discount_suggestions_tour_completed',
                    guideOpened: 'discount_suggestions_guide_opened',
                }
            },
            reviewFlow: {
                clicked: 'ReviewStarrClicked',
                success: 'ReviewSubmissionSuccess',
                failure: 'ReviewSubmissionFailure'
            }
        }
    }

    protected fireEvent(eventName: string, eventType: EventTypesEnum, screen: string, data: Record<any, any>) {
        if (
            eventName &&
            eventType && 
            screen && 
            Object.keys(this.userDetails || {}).length && 
            Object.keys(this.merchantDetails || {}).length
        ) {
            logEvent(
                eventName,
                eventType,
                screen,
                this.userDetails.email,
                this.merchantDetails.m_id,
                this.merchantDetails.short_name,
                this.userDetails.name,
                data
            )
        } else {
            console.error('Invalid parameters provided to logEvent');
        }
    }
}

export class FireEvents extends EventsUtils {
    public features: IFeaturesEvents;
    protected tourEvents: Function;
    protected configUpsertEvents: Function;

    constructor(merchantDetails: any, userDetails: any) {
        super(merchantDetails, userDetails)
        this.tourEvents = (keyName: FeaturesEnum): EventsTourInternal | null => {
            if (!Object.values(FeaturesEnum).includes(keyName)) throw new Error('Invalid keyName in tourEvents');
            const eventObj = this.eventNamesEnum[keyName];
            if (!this.hasTourSteps(eventObj)) return null;
            return {
                fireStartedEvent: (data: Record<any, any>) => {
                    this.fireEvent(eventObj.tourSteps.started, EventTypesEnum.click, 'tourStarted', data);
                },
                fireNextStepEvent: (data: Record<any, any>) => {
                    this.fireEvent(eventObj.tourSteps.nextStep, EventTypesEnum.click, 'tourNextStep', data);
                },
                fireSkippedEvent: (data: Record<any, any>) => {
                    this.fireEvent(eventObj.tourSteps.skipped, EventTypesEnum.click, 'tourSkipped', data);
                },
                fireCompletedEvent: (data: Record<any, any>) => {
                    this.fireEvent(eventObj.tourSteps.completed, EventTypesEnum.click, 'tourCompleted', data);
                },
                fireGuideOpenedEvent: (data: Record<any, any>) => {
                    this.fireEvent(eventObj.tourSteps.guideOpened, EventTypesEnum.click, 'tourGuideOpened', data);
                },
            }
        }
        this.configUpsertEvents = (keyName: FeaturesEnum): EventsConfigUpsertFunctionInternal | null => {
            if (!Object.values(FeaturesEnum).includes(keyName)) throw new Error('Invalid keyName in configUpsertEvents');
            const eventObj = this.eventNamesEnum[keyName];
            if (!this.hasConfigsUpsertObj(eventObj)) return null;
            return {
                add: {
                    fireClickedEvent: (data: Record<any, any>) => {
                        this.fireEvent(eventObj.configsUpsert.add.clicked, EventTypesEnum.click, `${keyName} Config Add Page`, data);
                    },
                    fireSuccessEvent: (data: Record<any, any>) => {
                        this.fireEvent(eventObj.configsUpsert.add.success, EventTypesEnum.success, `${keyName} Config Add Page`, data);
                    },
                    fireFailureEvent: (data: Record<any, any>) => {
                        this.fireEvent(eventObj.configsUpsert.add.failure, EventTypesEnum.failure, `${keyName} Config Add Page`, data);
                    },
                },
                edit: {
                    fireClickedEvent: (data: Record<any, any>) => {
                        this.fireEvent(eventObj.configsUpsert.edit.clicked, EventTypesEnum.click, `${keyName} Config Edit Page`, data);
                    },
                    fireSuccessEvent: (data: Record<any, any>) => {
                        this.fireEvent(eventObj.configsUpsert.edit.success, EventTypesEnum.success, `${keyName} Config Edit Page`, data);
                    },
                    fireFailureEvent: (data: Record<any, any>) => {
                        this.fireEvent(eventObj.configsUpsert.edit.failure, EventTypesEnum.failure, `${keyName} Config Edit Page`, data);
                    },
                },
                delete: {
                    fireClickedEvent: (data: Record<any, any>) => {
                        this.fireEvent(eventObj.configsUpsert.delete.clicked, EventTypesEnum.click, `${keyName} Home Page`, data);
                    },
                    fireSuccessEvent: (data: Record<any, any>) => {
                        this.fireEvent(eventObj.configsUpsert.delete.success, EventTypesEnum.success, `${keyName} Home Page`, data);
                    },
                    fireFailureEvent: (data: Record<any, any>) => {
                        this.fireEvent(eventObj.configsUpsert.delete.failure, EventTypesEnum.failure, `${keyName} Home Page`, data);
                    },
                },
                discardSave: (data: Record<any, any>) => {
                    this.fireEvent(eventObj.configsUpsert.discardSave, EventTypesEnum.click, `${keyName} Home Page`, data);
                },
            }
        }
        this.features = {
            settingsPageEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.settingsPage.clicked, EventTypesEnum.click, 'Settings Home Page', data);
                },
                fireWidgetClicked: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.settingsPage.upgradePlanWidgetClicked , EventTypesEnum.click, 'Settings Home Page', data);
                },
                fireUpgradeWidgetExpanded: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.settingsPage.upgradeWidgetExpanded , EventTypesEnum.click, 'Settings Home Page', data);
                },
                fireUpgradeWidgetCollapsed: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.settingsPage.upgradeWidgetCollapsed , EventTypesEnum.click, 'Settings Home Page', data);
                },
                fireUpgradePlanEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.settingsPage.upgradePlanClicked , EventTypesEnum.click, 'Settings Home Page', data);
                },
            },
            pincodeServiceabilityEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.pincodeServiceability.clicked, EventTypesEnum.click, 'Pincode Serviceability Home Page', data);
                },
                downloadCSVEvents: {
                    fireClickedEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.downloadCSV.clicked, EventTypesEnum.click, 'Pincode Serviceability Home Page', data);
                    },
                    fireSuccessEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.downloadCSV.success, EventTypesEnum.success, 'Pincode Serviceability Home Page', data);
                    },
                    fireFailureEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.downloadCSV.failure, EventTypesEnum.failure, 'Pincode Serviceability Home Page', data);
                    },
                    fireDownloadSamplePincodesEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.downloadCSV.downloadSample, EventTypesEnum.click, 'Pincode Serviceability Home Page', data);
                    }
                },
                uploadCSVEvents: {
                    fireClickedEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.uploadCSV.clicked, EventTypesEnum.click, 'Pincode Serviceability Upload Modal Page', data);
                    },
                    fireSuccessEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.uploadCSV.success, EventTypesEnum.success, 'Pincode Serviceability Upload Modal Page', data);
                    },
                    fireFailureEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.uploadCSV.failure, EventTypesEnum.failure, 'Pincode Serviceability Upload Modal Page', data);
                    },
                },
                toggleConfigEvents: {
                    fireClickedEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.toggleConfig.clicked, EventTypesEnum.click, 'Pincode Serviceability Home Page', data);
                    },
                    fireSuccessEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.toggleConfig.success, EventTypesEnum.success, 'Pincode Serviceability Home Page', data);
                    },
                    fireFailureEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.toggleConfig.failure, EventTypesEnum.failure, 'Pincode Serviceability Home Page', data);
                    },
                },
                onboardingStatusUpdate: {
                    fireSuccessEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.onboardingStatusUpdate.success, EventTypesEnum.success, 'Pincode Serviceability Home Page - OnBoarding Flow', data);
                    },
                    fireFailureEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.pincodeServiceability.onboardingStatusUpdate.failure, EventTypesEnum.failure, 'Pincode Serviceability Home Page - OnBoarding Flow', data);
                    },
                },
                tourStepsEvents: this.tourEvents(FeaturesEnum.pincodeServiceability),
            },
            shippingServiceabilityEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.shippingServiceability.clicked, EventTypesEnum.click, 'Shipping Serviceability Home Page', data);
                },
                onboardingPrefill: {
                    fireSuccessEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.shippingServiceability.onboardingPrefill.success, EventTypesEnum.success, 'Shipping Serviceability Home Page', data);
                    },
                    fireFailureEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.shippingServiceability.onboardingPrefill.failure, EventTypesEnum.failure, 'Shipping Serviceability Home Page', data);
                    },
                },
                onboardingStatusUpdate: {
                    fireSuccessEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.shippingServiceability.onboardingStatusUpdate.success, EventTypesEnum.success, 'Shipping Serviceability Home Page - OnBoarding Flow', data);
                    },
                    fireFailureEvent: (data: Record<any, any>) => {
                        this.fireEvent(this.eventNamesEnum.shippingServiceability.onboardingStatusUpdate.failure, EventTypesEnum.failure, 'Shipping Serviceability Home Page - OnBoarding Flow', data);
                    },
                },
                configsUpsert: this.configUpsertEvents(FeaturesEnum.shippingServiceability),
                tourStepsEvents: this.tourEvents(FeaturesEnum.shippingServiceability)
            },
            customDiscountsEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.customDiscounts.clicked, EventTypesEnum.click, 'Custom Discounts Home Page', data);
                },
                configsUpsert: this.configUpsertEvents(FeaturesEnum.customDiscounts),
                tourStepsEvents: this.tourEvents(FeaturesEnum.customDiscounts)
            },
            codFeaturesEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.codFeatures.clicked, EventTypesEnum.click, 'COD Features Home Page', data);
                }, 
                fireUpgradePlanEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.codFeatures.upgradePlan, EventTypesEnum.click, 'COD Features Home Page', data);
                },                 
                tourStepsEvents: this.tourEvents(FeaturesEnum.codFeatures)
            },
            codConfigsEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.codConfigs.clicked, EventTypesEnum.click, 'COD Features Home Page', data);
                },
                configsUpsert: this.configUpsertEvents(FeaturesEnum.codConfigs),
                tourStepsEvents: this.tourEvents(FeaturesEnum.codConfigs)
            },
            paymentCustomisationsEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.paymentCustomisations.clicked, EventTypesEnum.click, 'COD Features Home Page', data);
                },
                configsUpsert: this.configUpsertEvents(FeaturesEnum.paymentCustomisations),
                tourStepsEvents: this.tourEvents(FeaturesEnum.paymentCustomisations)
            },
            shippingCustomisationsEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.shippingCustomisations.clicked, EventTypesEnum.click, 'COD Features Home Page', data);
                },
                configsUpsert: this.configUpsertEvents(FeaturesEnum.shippingCustomisations),
                tourStepsEvents: this.tourEvents(FeaturesEnum.shippingCustomisations)
            },
            paymentFeaturesEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.paymentFeatures.clicked, EventTypesEnum.click, 'Payment Features Home Page', data);
                }, 
                tourStepsEvents: this.tourEvents(FeaturesEnum.paymentFeatures)
            },
            ppcodEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.ppcod.clicked, EventTypesEnum.click, 'Payment Features Home Page', data);
                },
                configsUpsert: this.configUpsertEvents(FeaturesEnum.ppcod),
                tourStepsEvents: this.tourEvents(FeaturesEnum.ppcod)
            },
            prepaidDiscountsEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.prepaidDiscounts.clicked, EventTypesEnum.click, 'Payment Features Home Page', data);
                },
                configsUpsert: this.configUpsertEvents(FeaturesEnum.prepaidDiscounts),
                tourStepsEvents: this.tourEvents(FeaturesEnum.prepaidDiscounts)
            },
            uiCustomisationsEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.uiCustomisations.clicked, EventTypesEnum.click, 'UI Customisations Home Page', data);
                }, 
                configsUpsert: this.configUpsertEvents(FeaturesEnum.uiCustomisations),
                tourStepsEvents: this.tourEvents(FeaturesEnum.uiCustomisations)
            },
            loginUICustomisationsEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.loginUICustomisations.clicked, EventTypesEnum.click, 'Login UI Customisations Home Page', data);
                },
                configsUpsert: this.configUpsertEvents(FeaturesEnum.loginUICustomisations),
                tourStepsEvents: this.tourEvents(FeaturesEnum.loginUICustomisations)
            },
            discountSuggestionsEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.discountSuggestions.clicked, EventTypesEnum.click, 'UI Customisations Home Page', data);
                },
                configsUpsert: this.configUpsertEvents(FeaturesEnum.discountSuggestions),
                tourStepsEvents: this.tourEvents(FeaturesEnum.discountSuggestions)
            },
            reviewFlowEvents: {
                fireClickedEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.reviewFlow.clicked, EventTypesEnum.click, 'Review Panel', data);
                },
                fireSuccessEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.reviewFlow.success, EventTypesEnum.success, 'Review Panel', data);
                },
                fireFailureEvent: (data: Record<any, any>) => {
                    this.fireEvent(this.eventNamesEnum.reviewFlow.failure, EventTypesEnum.failure, 'Review Panel', data);
                },
            }
        }
    }

    private hasTourSteps(obj: any): obj is ITourEventNames {
        return obj && typeof obj.tourSteps !== 'undefined';
    }

    private hasConfigsUpsertObj(obj: any): obj is { configsUpsert: EventsConfigUpsertInternal } {
        return obj && typeof obj.configsUpsert !== 'undefined';
    }
}