import { Button } from 'gokwik-ui-kit'
import isCod from '@library/images/isCod.mp4'

const CodPopUp = ({ onYesClick, onNoClick }) => {
    return (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' onClick={() => {}}>
            <div className='flex flex-col p-5 bg-white shadow-lg rounded-lg max-w-md w-full'>
                <div>
                    <video
                        className='mb-4'
                        src={isCod}
                        autoPlay
                        loop
                        muted
                        style={{ width: '100%', borderRadius: '8px' }}
                    />
                </div>
                <div className='flex flex-col'>
                    <h4 className='text-lg font-semibold'>
                        Is the Cash on Delivery (COD) payment option visible at checkout?
                    </h4>
                    <div className='flex gap-3'>
                        <Button onClick={onYesClick} variant='default' className='w-full items-center h-[48px]'>
                            Yes
                        </Button>
                        <Button onClick={onNoClick} variant='primary' className='w-full items-center h-[48px]'>
                            No
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CodPopUp
