import { useEffect, useState } from 'react'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'

import {
    Button,
    Table,
    Tag,
    Tooltip,
    ColumnProps,
    Switch,
    DownloadOutlined,
    SearchOutlined,
    InfoCircleOutlined,
    Input,
    message,
    Row,
    RedoOutlined,
    Col,
} from 'gokwik-ui-kit'
import BookDownload from '@library/images/book-download.svg'

import { useSelector } from 'react-redux'
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { getCookie, setCookie } from '@library/utilities/helpers/cookieHelper'
import { FloatingTourIcon, RenderTour } from '../tour/tour'
import { pincodeServiceabilityTourSteps } from '../tour/tourSteps'
import { PincodesUploadModal } from './pincodesUploadModal'
import { useNavigate } from 'react-router-dom'
import { IPincodeServiceabilityEvents, EventsFunctionInternal } from '@library/utilities/helpers/eventsHelper'
import { RatingModal } from '../cod-features/helpers/RatingModal'
import { shopifyAppMerchantConfig } from '@store/shopify-app/selectors'
import { kycAndPaymentStatus } from '@library/utilities/helpers/helper'

export default function index(props: {
    events: IPincodeServiceabilityEvents
    reviewFlowEvents: EventsFunctionInternal
    showTourPop: boolean
}) {
    const navigate = useNavigate()
    const isMerchantUser = useSelector(isMerchantSelector)
    const [parameters, setParameters] = useState({
        merchant: null,
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
    })
    const { showTourPop } = props
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [showRatingModal, setShowRatingModal] = useState(false)
    const [pincodeList, setPincodeList] = useState([])
    const [pincodeDetails, setDetails] = useState<any>(null)
    const [total, setTotalPincode] = useState(0)
    const [searchPincode, setSearchPincode] = useState('')
    const merchantDetails = useSelector(getMerchantDetails)
    const shopifyConfig = useSelector(shopifyAppMerchantConfig)
    const [showTour, setShowTour] = useState(false)
    const [onBoardingStates, setOnBoardingStates] = useState({
        addCodPincodes: false,
        addPrepaidPincodes: false,
        addCodShippingRates: false,
    })
    const [filesLists, setFilesLists] = useState({
        codFilesList: [],
        upiFilesList: [],
    })
    const [currentCodPincode, setCurrentCodPincode] = useState(null)
    const [currentUpiPincode, setCurrentUpiPincode] = useState(null)
    const shippingPageRedirectionPath = '/shopify-app/settings/shipping?cod-shipping-rate=true'
    const nextOnboardingStepRedirectionPath = '/onboarding/kc/onboarding-steps'
    const isKycCompleted = kycAndPaymentStatus(merchantDetails, 'isKycCompleted')
    const { downloadCSVEvents, uploadCSVEvents, toggleConfigEvents } = props.events
    const isNonPlusMerchant = shopifyConfig?.isNonPlusMerchant ?? false

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        setOnBoardingStates({
            addCodPincodes: params.get('limit-cod-availability') === 'true',
            addPrepaidPincodes: params.get('limit-prepaid-availability') === 'true',
            addCodShippingRates: params.get('cod-shipping-rate') === 'true',
        })
        if (params.get('limit-cod-availability') === 'true' || params.get('limit-prepaid-availability') === 'true') {
            setShowUploadModal(true)
        }
        //only show modal if the user has selected cod suite and redirection is from onboarding
        if (
            localStorage.getItem('showKycPromptFlag') === 'false' &&
            localStorage.getItem('isFromOnboarding') === 'true'
        ) {
            setShowRatingModal(true)
        }

        const checkCookies = () => {
            const isTourVisited = getCookie('pincodeServiceabilityTour')
            if (
                !isTourVisited &&
                params.get('limit-cod-availability') !== 'true' &&
                params.get('limit-prepaid-availability') !== 'true' &&
                localStorage.getItem('isFromOnboarding') === null &&
                !isAnyModalOpen()
            ) {
                setShowTour(true)
            }
        }
        getPincodeServiceabilityStatus()
        getFilesUploadedList()

        const interval = setInterval(() => {
            checkCookies()
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const isAnyModalOpen = () => {
        const modals = document.querySelectorAll('.ant-modal-root .ant-modal');
        return Array.from(modals).some(modal => {
          const style = window.getComputedStyle(modal);
          return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0';
        });
    };
    
    const columns: ColumnProps<any>[] = [
        {
            title: <span>Pincode</span>,
            dataIndex: 'pincode',
            width: 150,
            ellipsis: true,
        },
        {
            title: <span>Cash on Delivery</span>,
            dataIndex: 'cod',
            width: 150,
            render: (itm) => <Tag variant={itm ? 'default' : 'error'}>{itm ? 'Enabled' : 'Disabled'}</Tag>,
        },

        ...(isKycCompleted && !isNonPlusMerchant
            ? [
                  {
                      title: <span>Prepaid</span>,
                      dataIndex: 'upi',
                      width: 150,
                      render: (itm) => <Tag variant={itm ? 'default' : 'error'}>{itm ? 'Enabled' : 'Disabled'}</Tag>,
                  },
              ]
            : []),
    ]

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }

    const downloadPincodesCSV = async (file, pincodeType) => {
        try {
            downloadCSVEvents.fireClickedEvent({
                onboarding_flow: onBoardingStates.addCodPincodes || onBoardingStates.addPrepaidPincodes,
                pincodeType
            });
            const response = await makeAPICall({
                url:
                    process.env.REACT_APP_BASE_URL +
                    '/api/dashboard/pincode/download/file' +
                    '?file_name=' +
                    'latest' +
                    '&response_mode=download' +
                    '&checkout_platform=' +
                    'kwik_checkout' +
                    '&pincode_type=' +
                    pincodeType,
                method: 'get',
            })
            if (response.status == 200) {
                let filename = 'Pincode.csv'
                if (pincodeType == 'cod') filename = 'CodPincodes.csv'
                else if (pincodeType == 'upi') filename = 'UpiPincodes.csv'

                downloadCSVEvents.fireSuccessEvent({
                    onboarding_flow: onBoardingStates.addCodPincodes || onBoardingStates.addPrepaidPincodes,
                    pincodeType
                });

                const link = document.createElement('a')
                var blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' })
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                message.success('Downloaded successfully!')
            } else {
                downloadCSVEvents.fireFailureEvent({
                    onboarding_flow: onBoardingStates.addCodPincodes || onBoardingStates.addPrepaidPincodes,
                    pincodeType,
                    apiStatusCode: response.status,
                    errorMessage: 'Failed to Download Current Pincodes',
                })
                console.error('Failed to fetch Pincode file :', response.status, response.statusText)
                message.error('Failed to fetch Pincode file !')
            }
        } catch (error) {
            downloadCSVEvents.fireFailureEvent({
                onboarding_flow: onBoardingStates.addCodPincodes || onBoardingStates.addPrepaidPincodes,
                pincodeType,
                errorMessage: error.message,
            })
            console.error('Download Failed:', error)
            message.error('Download failed!')
        }
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/shopify-app/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'pincode',
                href: '/shopify-app/settings/pincode',
                text: 'Pincode Serviceability',
            },
        ])

        getPincodesList()
    }, [parameters.pageSize, parameters.page])

    useEffect(() => {
        if (filesLists?.codFilesList?.length > 0) {
            setCurrentCodPincode(filesLists.codFilesList[0])
        }
        if (filesLists?.upiFilesList?.length > 0) {
            setCurrentUpiPincode(filesLists.upiFilesList[0])
        }
    }, [filesLists])

    const updateOnBoardingStatus = async () => {
        if (onBoardingStates.addCodShippingRates) {
            //redirect to shipping page
            navigate(shippingPageRedirectionPath)
        } else {
            try {
                const response = await makeAPICall({
                    method: 'post',
                    url:
                        process.env.REACT_APP_BASE_URL +
                        APIEndPoints.updateMerchant(merchantDetails?.m_id) +
                        '?mode=live',
                    payload: {
                        current_onboarding_status: 'gokwik_shipping_setup_done',
                    },
                })
                if (response.data.status_code === 200) {
                    //redirect to next onboarding step
                    navigate(nextOnboardingStepRedirectionPath)
                }
            } catch (error) {
                console.error('Error:', error)
                message.error('Failed to redirect to next step')
            }
        }
    }

    const getFilesUploadedList = () => {
        makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.merchantPincodeFiles +
                '?checkout_platform=' +
                'kwik_checkout',
        }).then((res) => {
            setFilesLists({
                codFilesList: res?.data?.data?.codPincodeFiles || [],
                upiFilesList: res?.data?.data?.upiPincodeFiles || [],
            })
        })
    }

    const handleSearchPincode = () => {
        if (searchPincode.length) {
            if (parameters.page === 1) {
                getPincodesList()
            } else setParameters((prev) => ({ ...prev, page: 1 }))
        }
    }

    const handleResetPincode = () => {
        setSearchPincode('')
        if (searchPincode && parameters.page === 1) {
            getPincodesList('')
        } else setParameters((prev) => ({ ...prev, page: 1 }))
    }

    const togglePincodeServiceablity = () => {
        toggleConfigEvents.fireClickedEvent({
            value: !pincodeDetails?.enable_pincode,
            onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes
        });
        makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.enablePin,
            payload: {
                enable: !pincodeDetails?.enable_pincode,
                checkout_platform: 'kwik_checkout',
            },
        }).then((res) => {
            if (res.data.status_code == 200) {
                toggleConfigEvents.fireSuccessEvent({
                    value: !pincodeDetails?.enable_pincode,
                    onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes
                });
                getPincodeServiceabilityStatus()
            } else {
                toggleConfigEvents.fireFailureEvent({
                    value: !pincodeDetails?.enable_pincode,
                    onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes,
                    apiStatusCode: res.data.status_code,
                    errorMessage: "Failed to Update Pincode Serviceability Config"
                });
            }
        })
    }

    const getPincodesList = (pincode = undefined) => {
        makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.pincodeList +
                `?page=${parameters.page}&pageSize=${parameters.pageSize}&pincode=${
                    pincode === '' ? pincode : searchPincode
                }`,
        }).then((res) => {
            if (res.data.status_code == 200) {
                setPincodeList(res.data.data?.pincodes)
                setTotalPincode(res.data.data?.totalCount)
            }
        })
    }

    const getPincodeServiceabilityStatus = () => {
        makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getPin + '?checkout_platform=' + 'kwik_checkout',
        }).then((res) => {
            if (res.data.status_code == 200) {
                setDetails(res.data.data)
            }
        })
    }

    const getSignedUrl = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.fileS3PreSignedUrl,
            params: { file_name: 'Pincode.csv', ...(!isMerchantUser && { merchant_id: merchantDetails?.id }) },
        })
        if (response.success) {
            return response.data.data.url
        }
    }

    async function uploadPincodesData(csvFile, fileName, pincodeType) {
        try {
            uploadCSVEvents.fireClickedEvent({
                onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes,
                step: 'uploadPincodes'
            });
            const url = await getSignedUrl()
            const awsResponse = await fetch(url, {
                method: 'PUT',
                body: csvFile,
            })
            if (awsResponse.status == 200) {
                const parsedUrl = new URL(url)
                const parts = parsedUrl.pathname.split('/')
                const nonEmptyParts = parts.filter((part) => part !== '')
                const filename = nonEmptyParts.pop()

                const postData = {
                    file_name: filename,
                    ...(!isMerchantUser && { merchant_id: merchantDetails?.id + '' }),
                }
                const response = await makeAPICall({
                    method: 'post',
                    url:
                        process.env.REACT_APP_BASE_URL +
                        APIEndPoints.fileS3UploadNotifyUrl +
                        '?file=' +
                        fileName +
                        '&checkout_platform=' +
                        'kwik_checkout' +
                        '&pincode_type=' +
                        pincodeType,
                    payload: {
                        ...postData,
                    },
                })

                if (response.data.status_code == 200) {
                    uploadCSVEvents.fireSuccessEvent({
                        onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes,
                        step: 'uploadPincodes'
                    });
                    getPincodesList()
                    getFilesUploadedList()
                    // enabled pincode serviceability
                    if (!pincodeDetails?.enable_pincode) {
                        togglePincodeServiceablity()
                    }
                    message.success('Changes saved successfully')
                } else {
                    uploadCSVEvents.fireFailureEvent({
                        onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes,
                        apiStatusCode: response.data.status_code,
                        step: 'uploadPincodes',
                        errorMessage: "Failed to Upload New Pincodes"
                    });
                    console.error('Failed to make POST request:', response.status, response.data, response.statusText)
                }
            } else {
                uploadCSVEvents.fireFailureEvent({
                    onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes,
                    apiStatusCode: awsResponse.status,
                    step: 'uploadPincodes',
                    errorMessage: "Failed to Upload New Pincodes"
                });
                console.error('Failed to make POST request:', awsResponse.status, awsResponse.statusText)
            }
        } catch {
            uploadCSVEvents.fireFailureEvent({
                onboarding_flow: onBoardingStates.addCodPincodes || onBoardingStates.addPrepaidPincodes,
                step: 'uploadPincodes',
                errorMessage: "Failed to Upload New Pincodes"
            });
            message.error('Upload Failed!')
        }
    }

    async function replacePincodesData(fileSelected, pincodeType) {
        try {
            uploadCSVEvents.fireClickedEvent({
                onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes,
                step: 'replacePincodes'
            });
            const postData = {
                file_name: fileSelected.file_name,
                ...(!isMerchantUser && { merchant_id: merchantDetails?.id + '' }),
            }

            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.fileS3UploadNotifyUrl +
                    '?file=' +
                    fileSelected.file +
                    '&checkout_platform=' +
                    'kwik_checkout' +
                    '&pincode_type=' +
                    pincodeType +
                    '&replace=true',
                payload: {
                    ...postData,
                },
            })
            if (response.data.status_code == 200) {
                message.success('Uploaded successfully!')
                getPincodesList()
                getFilesUploadedList()
                uploadCSVEvents.fireSuccessEvent({
                    onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes,
                    step: 'replacePincodes'
                });
            } else {
                uploadCSVEvents.fireFailureEvent({
                    onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes,
                    apiStatusCode: response.data.status_code,
                    step: 'replacePincodes',
                    errorMessage: "Failed to Replace Pincodes with Existing File"
                });
            }
        } catch {
            message.error('Upload Failed!')
            uploadCSVEvents.fireFailureEvent({
                onboarding_flow: onBoardingStates.addCodPincodes || onBoardingStates.addPrepaidPincodes,
                step: 'replacePincodes',
                errorMessage: "Failed to Replace Pincodes with Existing File"
            });
        }
    }

    const handleToggleChange = (checked: boolean) => {
        // Do not enable pincode serviceability if no pincode files are uploaded
        if (checked && !filesLists?.codFilesList?.length && !filesLists?.upiFilesList?.length) {
            message.info('Please upload a pincode file to enable pincode serviceability')
        } else {
            togglePincodeServiceablity()
        }
    }

    function startTour() {
        setShowTour(true);
        props.events.tourStepsEvents.fireStartedEvent();
    }

    const nextStep = (step) => {
        const currentStepTitle = pincodeServiceabilityTourSteps[step].title

        props.events.tourStepsEvents.fireNextStepEvent({
            onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes,
            stepTitle: currentStepTitle
        })
    }

    const openGuide = () => {
        props.events.tourStepsEvents.fireGuideOpenedEvent({
            onboarding_flow: onBoardingStates.addCodPincodes ||onBoardingStates.addPrepaidPincodes
        })
    }

    const closeTour = () => {
        setShowTour(false)
        setCookie('pincodeServiceabilityTour', true, 365)
        props.events.tourStepsEvents.fireSkippedEvent({
            onboarding_flow: onBoardingStates.addCodPincodes || onBoardingStates.addPrepaidPincodes,
        })
    }

    const finishTour = () => {
        setShowTour(false)
        setCookie('pincodeServiceabilityTour', true, 365)
        props.events.tourStepsEvents.fireCompletedEvent({
            onboarding_flow: onBoardingStates.addCodPincodes || onBoardingStates.addPrepaidPincodes,
        })
    }

    return (
        <>
            <div className='w-full px-2 flex flex-col gap-2 inter'>
                <div className='flex justify-between items-center bg-white p-3 gap-1  rounded-md '>
                    <div className='w-1/2'>
                        <div className='flex gap-2  items-center'>
                            <p className='text-base font-semibold  mb-2'>Pincode Serviceability</p>

                            <a
                                className='ml-2 mb-1 text-sm font-normal'
                                id='pincode-serviceability-guide'
                                href='https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg'
                                target='_blank'
                                rel='noopener noreferrer'
                                onClick={openGuide}
                            >
                                <img
                                    className='mr-1 mb-1'
                                    src={BookDownload}
                                    height={'18'}
                                    width={'18'}
                                    alt='Book Download Icon'
                                />
                                Open Guide
                            </a>
                        </div>
                        <p className='text-sm  text-gray-400'>Enable this feature to ship only to uploaded pincodes.</p>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <p>{pincodeDetails?.enable_pincode ? 'Disable' : 'Enable'}</p>
                        <Switch
                            id='enable-pincode'
                            onChange={handleToggleChange}
                            checked={
                                pincodeDetails &&
                                Object.keys(pincodeDetails)?.includes('enable_pincode') &&
                                pincodeDetails?.enable_pincode
                            }
                            className='mx-3 '
                        />
                    </div>
                </div>
                <div className='bg-white p-3 rounded-md'>
                    <div className='gap-2 rounded-md flex items-center justify-between  '>
                        <p className='text-base font-semibold'>
                            Existing Uploaded Files
                            <Tooltip
                                className='ml-2'
                                title={
                                    <>
                                        Pincodes not included in the uploaded files will be marked as non-serviceable
                                        for both prepaid and COD.
                                    </>
                                }
                            >
                                <InfoCircleOutlined className='opacity-40' />
                            </Tooltip>
                        </p>
                    </div>
                    <Row className='bg-white rounded-md' justify={'space-between'} align={'middle'} wrap={true}>
                        <Col span={18}>
                            {currentCodPincode?.file_name && (
                                <Col span={24} className='flex gap-2 mt-1'>
                                    <DownloadOutlined
                                        onClick={() => downloadPincodesCSV(currentCodPincode?.file_name, 'cod')}
                                        className='text-blue-500'
                                    />
                                    <p>
                                        {currentCodPincode?.file}
                                        <span className='opacity-40 ml-2'>
                                            • {currentCodPincode?.records_count} pincodes{' '}
                                        </span>
                                        <Tag> Cash on Delivery </Tag>
                                    </p>
                                </Col>
                            )}
                            {!!(isKycCompleted && currentUpiPincode?.file_name && !isNonPlusMerchant) && (
                                <Col span={24} className='flex gap-2 mt-1'>
                                    <DownloadOutlined
                                        onClick={() => downloadPincodesCSV(currentUpiPincode?.file_name, 'upi')}
                                        className='text-blue-500'
                                    />
                                    <p>
                                        {currentUpiPincode?.file}
                                        <span className='opacity-40 ml-2'>
                                            • {currentUpiPincode?.records_count} pincodes{' '}
                                        </span>
                                        <Tag>Prepaid</Tag>
                                    </p>
                                </Col>
                            )}
                        </Col>
                        <Col>
                            <Button
                                className='ml-auto justify-end'
                                id='upload-pincode'
                                onClick={() => {
                                    setShowUploadModal(true)
                                }}
                                variant='primary'
                            >
                                Upload File
                            </Button>
                        </Col>
                    </Row>
                </div>

                <div className='mt-1 bg-white rounded-md pt-1  overflow-auto'>
                    <div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
                        <div className='mt-2 px-2'>
                            <Input
                                onChange={(e) => {
                                    setSearchPincode(e.target.value)
                                }}
                                type='text'
                                placeholder='Search Pincode'
                                maxLength={6}
                                value={searchPincode}
                                suffix={<SearchOutlined />}
                            />
                        </div>
                        <div className='mt-2 px-2 sm:col-span-2 sm:flex sm:gap-2 justify-end'>
                            <div className='flex sm:justify-start gap-2'>
                                <Button onClick={handleSearchPincode} variant='primary'>
                                    <SearchOutlined />
                                    Search
                                </Button>
                                <Button variant='default' onClick={handleResetPincode}>
                                    <RedoOutlined />
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Table
                            columns={columns}
                            dataSource={pincodeList || []}
                            pagination={{
                                current: parameters.page, // Current page number
                                pageSize: parameters.pageSize, // Number of items to display per page
                                total: total, // Total number of items in the data array
                                showSizeChanger: false,
                                onChange: handlePaginationChange,

                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                            }}
                            scroll={{ x: 300 }}
                        />
                    </div>
                </div>

                <FloatingTourIcon onClick={startTour} />
            </div>
            {showUploadModal && (
                <PincodesUploadModal
                    showModal={showUploadModal}
                    setShowModal={setShowUploadModal}
                    filesLists={filesLists}
                    uploadPincodesData={uploadPincodesData}
                    replacePincodesData={replacePincodesData}
                    downloadPincodesCsv={downloadPincodesCSV}
                    onBoardingStates={onBoardingStates}
                    GoToNextOnboardingStep={updateOnBoardingStatus}
                    isKycCompleted={isKycCompleted}
                    isNonPlusMerchant={isNonPlusMerchant}
                    fireDownloadSampleEvent={downloadCSVEvents.fireDownloadSamplePincodesEvent}
                />
            )}

            <RenderTour
                showTour={showTour && showTourPop}
                steps={pincodeServiceabilityTourSteps}
                onClose={closeTour}
                onChange={nextStep}
                onFinish={finishTour}
            />
            <RatingModal
                isModalVisible={showRatingModal}
                setIsModalVisible={setShowRatingModal}
                events={props.reviewFlowEvents}
            />
        </>
    )
}
