import { useState, useEffect } from 'react'
import { Col, Input, Row, Button, InputNumber, Tooltip, QuestionCircleOutlined, message } from 'gokwik-ui-kit'
import { IShopifyNewCheckoutUIConfig } from '@library/utilities/interface'
import AddressPagePreview from './addressPagePreview'
import { addressPageTooltips, defaultShopifyNewCheckoutConfig } from './constants'
import SaveBar from '@library/components/save-bar'

const AddressPageCustomisation = ({ config, saveConfig, fireDiscardSaveEvent }) => {
    const [isUpdated, setIsUpdated] = useState<boolean>(false)
    const [updatedCheckoutUIConfig, setUpdatedCheckoutUIConfig] = useState<IShopifyNewCheckoutUIConfig>(
        defaultShopifyNewCheckoutConfig,
    )

    useEffect(() => {
        if (config?.newCheckoutUIConfig) {
            setUpdatedCheckoutUIConfig(config.newCheckoutUIConfig)
        }
    }, [config?.newCheckoutUIConfig])

    const validateAddressPageConfig = () => {
        if (updatedCheckoutUIConfig?.textConfig?.address?.cta?.trim()?.length < 1) {
            return {
                msg: 'Please add CTA button text',
                status: false,
            }
        }
        if (updatedCheckoutUIConfig?.textConfig?.address?.addressCount < 1) {
            return {
                msg: 'Address count must be greater than 0',
                status: false,
            }
        }
        return {
            msg: 'newUiAddressPageConfig validation success',
            status: true,
        }
    }

    const onSave = () => {
        const validation = validateAddressPageConfig()
        if (validation.status) {
            // Trim values of updated config
            const trimmedConfig = { ...updatedCheckoutUIConfig }
            if (trimmedConfig.textConfig?.address?.cta) {
                trimmedConfig.textConfig.address.cta = trimmedConfig.textConfig.address.cta.trim()
            }
            //update config
            saveConfig(
                {
                    newCheckoutUIConfig: updatedCheckoutUIConfig,
                },
                () => setIsUpdated(false),
            )
        } else {
            message.error(validation.msg)
        }
    }

    const onDiscard = () => {
        //reset
        setUpdatedCheckoutUIConfig(config?.newCheckoutUIConfig ?? defaultShopifyNewCheckoutConfig)
        setIsUpdated(false)
        fireDiscardSaveEvent()
    }

    return (
        <div>
            <Row gutter={16}>
                <Col span={16}>
                    <div className='bg-gray-100 min-h-screen'>
                        {/* CTA Button Section */}
                        <div className='mb-6 bg-white p-4 rounded-lg shadow-md max-w-4xl mx-auto'>
                            <p className='mb-2 text-base font-semibold'>
                                CTA Button{' '}
                                <Tooltip title={<>{addressPageTooltips.CtaButton}</>}>
                                    <QuestionCircleOutlined className='opacity-40' />
                                </Tooltip>
                            </p>
                            <div>
                                <label className='block mb-1 text-sm font-medium text-gray-700'>Button Text</label>
                                <Input
                                    value={updatedCheckoutUIConfig?.textConfig?.address?.cta}
                                    placeholder='Eg: Proceed To Payment'
                                    maxLength={18}
                                    onChange={(e) => {
                                        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
                                        setUpdatedCheckoutUIConfig((prev) => ({
                                            ...prev,
                                            textConfig: {
                                                ...prev.textConfig,
                                                address: {
                                                    ...prev.textConfig?.address,
                                                    cta: sanitizedValue,
                                                },
                                            },
                                        }))
                                        setIsUpdated(true)
                                    }}
                                    showCount
                                />
                            </div>
                        </div>

                        {/* Address Count */}
                        <div className='bg-white p-4 rounded-lg shadow-md max-w-4xl mx-auto'>
                            <p className='mb-2 text-base font-semibold'>
                                Address Visibility Count{' '}
                                <Tooltip title={<>{addressPageTooltips.AddressCount}</>}>
                                    <QuestionCircleOutlined className='opacity-40' />
                                </Tooltip>
                            </p>{' '}
                            <div>
                                <label className='block mb-1 text-sm font-medium text-gray-700'>Count</label>
                                <InputNumber
                                    type='number'
                                    min={1}
                                    max={10}
                                    value={updatedCheckoutUIConfig?.textConfig?.address?.addressCount}
                                    onChange={(value: number) => {
                                        setUpdatedCheckoutUIConfig((prev) => ({
                                            ...prev,
                                            textConfig: {
                                                ...prev.textConfig,
                                                address: {
                                                    ...prev.textConfig?.address,
                                                    addressCount: +value,
                                                },
                                            },
                                        }))
                                        setIsUpdated(true)
                                    }}
                                />
                            </div>
                        </div>
                        {isUpdated && <SaveBar handleDiscard={onDiscard} handleSave={onSave}/>}
                    </div>
                </Col>
                <Col span={8}>
                    <AddressPagePreview newCheckoutUIConfig={updatedCheckoutUIConfig} config={config} />{' '}
                </Col>
            </Row>
        </div>
    )
}

export default AddressPageCustomisation
