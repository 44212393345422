import { useState, useEffect } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Switch } from 'gokwik-ui-kit'

const ShopifyAppfailSafe = () => {
    const [isToggled, setIsToggled] = useState(false)

    useEffect(() => {
        const fetchInitialState = async () => {
            try {
                const res = await makeAPICall({
                    method: 'get',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.getFailSafeState,
                })
                const value = res?.data?.data?.isFailSafe
                setIsToggled(value ?? false)
            } catch (err) {
                console.error('GET Error:', err)
            }
        }

        fetchInitialState()
    }, [])

    const toggleFailSafeFn = async (value: boolean) => {
        try {
            const res = await makeAPICall({
                method: 'patch',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateFailSafe,
                payload: { value },
            })
            if (res?.data?.data?.isFailSafe !== undefined) {
                setIsToggled(res.data.data.isFailSafe)
            }
        } catch (err) {
            console.error('PATCH Error:', err)
        }
    }

    const handleToggle = async () => {
        const newValue = !isToggled
        try {
            setIsToggled(newValue)
            await toggleFailSafeFn(newValue)
        } catch (err) {
            console.error('Toggle failed:', err)
        }
    }

    return (
        <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>Fail Safe : </span>
            <Switch checked={isToggled} onChange={handleToggle} />
        </div>
    )
}

export default ShopifyAppfailSafe
