import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import {
    ArrowLeftOutlined,
    Button,
    Col,
    Modal,
    PlusOutlined,
    QuestionCircleOutlined,
    Row,
    Switch,
    Tooltip,
} from 'gokwik-ui-kit'
import { useState, useEffect } from 'react'
import PrepaidDiscountRulesTable from './discountTable'
import PrepaidDiscountRuleForm from './discountRuleForm'
import { DefaultPaymentMethods } from './constants'
import { getCookie, updateBreadcrumbs } from '@gokwik/utilities'
import { useNavigate } from 'react-router-dom'
import { setCookie } from '@library/utilities/helpers/cookieHelper'
import { prepaidDiscountFeatureTourSteps } from '@pages/settings/tour/tourSteps'
import { FloatingTourIcon, RenderTour } from '@pages/settings/tour/tour'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'
const PrepaidDiscountsWithstoreCredits = (props: { events: ICommonEvents }) => {
    const navigate = useNavigate()
    const [showTour, setShowTour] = useState(false)

    const { tourStepsEvents, configsUpsert, fireClickedEvent } = props.events

    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null)
    const [storeCreditDiscountConfig, setStoreCreditDiscountConfig] = useState({
        storeCreditDiscountEnabled: true,
        prepaidDiscounts: [],
        enabledPaymentMethods: [],
    })

    const { config, saveConfig } = useShopifyMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.shopifyAppPrepaidDiscountSaveConfig,
        fireFailureEvent: configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: configsUpsert.edit.fireSuccessEvent,
    })

    const { saveConfig: saveMerchantConfig, config: tempConfig } = useShopifyMerchantConfigApi({
        fireFailureEvent: configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: configsUpsert.edit.fireSuccessEvent,
    })
    const prepaidDiscountEnabled = tempConfig?.prepaidDiscountEnabled

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/shopify-app/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'payment-features',
                href: '/shopify-app/settings/payment-features',
                text: 'Payments Features',
            },
            {
                key: 'prepaid-discounts-with-store-credits',
                href: '/shopify-app/settings/payment-features/prepaid-discounts-with-store-credits',
                text: 'Prepaid Discounts as store Credits',
            },
        ])
        const checkCookies = () => {
            const isTourVisited = getCookie('prepaidDiscountsTour') || null
            if (!isTourVisited) {
                setShowTour(true)
            }
        }
        setTimeout(checkCookies, 100)
        fireClickedEvent()
    }, [])

    const parseSupportedPrepaidDiscounts = (paymentMethods) => {
        const unSupportedPaymentMethods = ['ppcod-upi', 'cod', 'snapmint']

        const res = paymentMethods.filter((method) => {
            if (method.includes('emi-') || method.includes('bnpl-')) return false
            return !unSupportedPaymentMethods.includes(method)
        })

        return res
    }

    useEffect(() => {
        if (config) {
            if (!config.paymentMethods?.length) {
                saveMerchantConfig({
                    paymentMethods: DefaultPaymentMethods.map((method) => {
                        return {
                            paymentMethodId: method,
                        }
                    }),
                })
                setStoreCreditDiscountConfig({
                    storeCreditDiscountEnabled: config.storeCreditDiscountEnabled ?? false,
                    prepaidDiscounts: config.storeCreditDiscountConfig || [],
                    enabledPaymentMethods: config.paymentMethods.map((method) => {
                        return method.paymentMethodId
                    }),
                })
            } else {
                setStoreCreditDiscountConfig({
                    storeCreditDiscountEnabled: config.storeCreditDiscountEnabled ?? false,
                    prepaidDiscounts: config.storeCreditDiscountConfig || [],
                    enabledPaymentMethods: parseSupportedPrepaidDiscounts(
                        config.paymentMethods.map((method) => {
                            return method.paymentMethodId
                        }),
                    ),
                })
            }
        }
    }, [config])

    useEffect(() => {
        if (tempConfig) {
            if (!tempConfig.paymentMethods?.length) {
                saveMerchantConfig({
                    paymentMethods: DefaultPaymentMethods.map((method) => {
                        return {
                            paymentMethodId: method,
                        }
                    }),
                })
                setStoreCreditDiscountConfig({
                    
                    storeCreditDiscountEnabled: tempConfig.storeCreditDiscountEnabled ?? false,
                    prepaidDiscounts: tempConfig.storeCreditDiscountConfig || [],
                    enabledPaymentMethods: tempConfig.paymentMethods.map((method) => {
                        return method.paymentMethodId
                    }),
                })
            } else {
                setStoreCreditDiscountConfig({
                    
                    storeCreditDiscountEnabled: tempConfig.storeCreditDiscountEnabled ?? false,
                    prepaidDiscounts: tempConfig.storeCreditDiscountConfig || [],
                    enabledPaymentMethods: parseSupportedPrepaidDiscounts(
                        tempConfig.paymentMethods.map((method) => {
                            return method.paymentMethodId
                        }),
                    ),
                })
            }
        }
    }, [tempConfig])

    function handleEdit(discountObject) {
        setModalTitle('Edit Prepaid Discount')
        setCurrentDiscountInfo(discountObject)
        setShowModal(true)
        configsUpsert.edit.fireClickedEvent({
            prevDiscountConfig: storeCreditDiscountConfig.prepaidDiscounts?.find(
                (discount) => discount?.id === discountObject?.id,
            ),
            currentDiscountConfig: discountObject,
        })
    }

    function handleDelete(id: string) {
        configsUpsert.delete.fireClickedEvent({
            discount: storeCreditDiscountConfig.prepaidDiscounts.find((discount) => discount.id === id),
        })
        saveConfig({
            storeCreditDiscountConfig: [
                {
                    id,
                    isDeleted: true,
                },
            ],
        })
    }

    function handleAdd() {
        setShowModal(true)
        setCurrentDiscountInfo(null)
        setModalTitle('Add New Discount Rule')
        configsUpsert.add.fireClickedEvent()
    }

    function toggleFeatureFlags(postOrderDiscountFeatureFlag) {
        configsUpsert.edit.fireClickedEvent({
            prevDiscountConfig: {
                storeCreditDiscountEnabled: storeCreditDiscountConfig.storeCreditDiscountEnabled,
            },
            currentDiscountConfig: {
                storeCreditDiscountEnabled: postOrderDiscountFeatureFlag,
                postOrderDiscountFeatureFlag: postOrderDiscountFeatureFlag,
            },
        })
        saveConfig({
            storeCreditDiscountEnabled: postOrderDiscountFeatureFlag
            // postOrderDiscountEnabled: postOrderDiscountFeatureFlag,
        })
    }
    

    function startTour() {
        setShowTour(true)
        tourStepsEvents.fireStartedEvent()
    }

    const nextStep = (step) => {
        handleAdd()
        if (step >= 2 && step <= 4) setShowModal(true)
        else setShowModal(false)
        tourStepsEvents.fireNextStepEvent({
            stepTitle: prepaidDiscountFeatureTourSteps[step].title,
        })
    }

    const closeTour = () => {
        setShowTour(false)
        setShowModal(false)
        setCookie('prepaidDiscountsTour', true, 365)
        tourStepsEvents.fireSkippedEvent()
    }

    const finishTour = () => {
        setShowTour(false)
        setShowModal(false)
        setCookie('prepaidDiscountsTour', true, 365)
        tourStepsEvents.fireCompletedEvent()
    }

    return (
        <div>
            <RenderTour
                showTour={showTour}
                steps={prepaidDiscountFeatureTourSteps}
                onClose={closeTour}
                onChange={nextStep}
                followCurrentStep={true}
                onFinish={finishTour}
            />
            <Row className='rounded-sm p-2 pl-0' gutter={[0, 24]} justify={'space-between'}>
                <Col span={16}>
                    <div className='flex items-top'>
                        <div>
                            <p className='text-xl font-semibold'>Prepaid Discount as Store Credits</p>
                            <p className='text-sm text-gray-400 font-normal'>
                                This prepaid discount will be added to user’s Shopify account as store credit. Please
                                ensure Store Credits are enabled.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <div>
                {showModal && (
                    <Modal
                        title={modalTitle}
                        footer={null}
                        onCancel={() => setShowModal(false)}
                        open={showModal}
                        centered
                        width={'700px'}
                    >
                        <PrepaidDiscountRuleForm
                            setShowModal={setShowModal}
                            discountInfo={currentDiscountInfo}
                            enabledPaymentMethods={storeCreditDiscountConfig.enabledPaymentMethods}
                            saveConfig={saveConfig}
                            tourInProgress={showTour}
                            discounts={storeCreditDiscountConfig.prepaidDiscounts}
                        />
                    </Modal>
                )}
                <br />
                <Row
                    className='flex flex-row justify-between items-center rounded-sm p-2 pl-0 mt-[-10px]
'
                    gutter={[0, 24]}
                >
                    <Col>
                        <div className='flex flex-col '>
                            <p className='text-base font-semibold '>
                                <b>Enable Prepaid Discounts</b>
                            </p>
                            <Tooltip
                                title={<>Toggle to enable/disable prepaid discounts, applicable on Shopify orders.</>}
                            ></Tooltip>
                        </div>
                    </Col>
                    <Col className='toggle-prepaid-discount-button'>
                        <Tooltip
                            title={
                                prepaidDiscountEnabled
                                    ? 'Only one discount type can be applied at a time. To enable Store Credit Discount, please disable the Prepaid Discount first.'
                                    : ''
                            }
                        >
                            <span>
                                {' '}
                                <Switch
                                    className='ml-5'
                                    checked={
                                        storeCreditDiscountConfig.storeCreditDiscountEnabled
                                    }
                                    onChange={(value) => toggleFeatureFlags(value)}
                                    disabled={prepaidDiscountEnabled} 
                                />
                            </span>
                        </Tooltip>
                    </Col>
                </Row>
                <Row className='rounded-sm p-2 pl-0' gutter={[0, 24]} justify={'space-between'}>
                    <Col span={16}>
                        <div className='flex items-top'>
                            <div>
                                <p className='text-base font-semibold '>Filter Basis Payment methods</p>
                                <p className='text-sm text-gray-400 font-normal'>
                                    Choose payment method from the below section.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='flex items-center gap-x-4 add-prepaid-discount-button'>
                            <Button
                                onClick={() => handleAdd()}
                                variant='primary'
                                disabled={
                                    !(
                                        storeCreditDiscountConfig.storeCreditDiscountEnabled
                                    )
                                }
                            >
                                Add New Discount Rule
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Col span={24}>
                    <PrepaidDiscountRulesTable
                        discounts={
                            storeCreditDiscountConfig.storeCreditDiscountEnabled === true 
                                ? storeCreditDiscountConfig.prepaidDiscounts
                                : []
                        }
                        enabledPaymentMethods={storeCreditDiscountConfig.enabledPaymentMethods}
                        onEdit={(discount) => handleEdit(discount)}
                        onDelete={(discount) => handleDelete(discount.id)}
                    />
                </Col>
            </div>
            <FloatingTourIcon onClick={startTour} />
        </div>
    )
}

export default PrepaidDiscountsWithstoreCredits
